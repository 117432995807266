<div *ngIf="isFormLoading">
    <div class="loader">
        <p-progressSpinner></p-progressSpinner>
    </div>
</div>
<div *ngIf="!isFormLoading" class="form-container">
    <div class="mb-5">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/app/matters"><i class="bi bi-briefcase-fill"></i>
                        Matters</a>
                </li>
                <li *ngIf="currentMatterId > 0; else newActive" class="breadcrumb-item active" aria-current="page">Edit
                </li>
                <ng-template #newActive>
                    <li class="breadcrumb-item active" aria-current="page">New</li>
                </ng-template>
            </ol>
        </nav>
    </div>
    <div class="mb-2 sticky-top form-header">
        <div class="row">
            <div class="col d-flex justify-content-start">
                <div *ngIf="currentMatterId > 0" class="header-btn-spacing">
                    <button class="btn btn-primary" (click)="cloneMatter()">Clone Matter</button>
                </div>
            </div>
            <div class="col d-flex justify-content-end">
                <div *ngIf="currentMatterId > 0 && !isArchived" class="header-btn-spacing">
                    <app-time-entry-popup buttonText="Add Time To Matter" [currentMatterId]="currentMatterId"
                        [canChangeMatter]="false" (savedTimeEntry)="refreshMatterTimes()"></app-time-entry-popup>
                </div>
                <div *ngIf="currentMatterId === 0" class="header-btn-spacing">
                    <button class="btn btn-primary" (click)="submitForm()" [disabled]="matterForm.invalid">Save
                        Matter & Continue</button>
                </div>
                <div *ngIf="currentMatterId > 0" class="header-btn-spacing">
                    <button class="btn btn-primary" (click)="submitForm()" [disabled]="matterForm.invalid">Update
                        Matter</button>
                </div>
                <div>
                    <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    <div class="form-scroll">
        <p-tabMenu *ngIf="isTabMenuVisible" [model]="tabMenuItems" [activeItem]="activeMenuItem"
            (activeItemChange)="onActiveItemChange($event)"></p-tabMenu>
        <form [formGroup]="matterForm" (ngSubmit)="submitForm()">
            <div *ngIf="activeMenuItem.label !== 'Memos' && activeMenuItem.label !== 'Time'" class="row">
                <div class="col-sm">
                    <div class="card mt-2" [ngClass]="{'extra-padding' : (currentMatterId === 0)}">
                        <div class="card-body">
                            <div class="row">
                                <div class="col d-flex flex-column gap-1">
                                    <label for="matter-ref">Matter #</label>
                                    <input pInputText id="matter-ref" formControlName="matterRef">
                                </div>
                            </div>
                            <div class="row pb-2">
                                <div class="col d-flex flex-column gap-1">
                                    <label for="matter-title">Matter Title</label>
                                    <input pInputText id="matter-title" formControlName="matterTitle">
                                </div>
                            </div>
                            <div class="row pb-2">
                                <div class="col-sm-12 col-md p-field">
                                    <label for="client-con">Client: {{matterForm.value.clientName}}</label>
                                    <app-contact-search-popup container=".form-container" buttonText="Client Contact"
                                        searchType="company"
                                        (clickedContact)="onReferralSourceClicked($event)"></app-contact-search-popup>
                                </div>
                                <div class="col-sm-12 col-md d-flex flex-column gap-1">
                                    <label for="client-ref">Client Reference</label>
                                    <input pInputText id="client-ref" placeholder="Client Reference"
                                        formControlName="clientReference">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-6 pb-2">
                                    <div class="p-field">
                                        <label class="pb-1" for="matter-type">Matter Type</label>
                                        <p-dropdown id="matter-type" [options]="matterTypes"
                                            formControlName="matterType" placeholder="Select a Matter Type..."
                                            optionLabel="displayValue" (onChange)="onMatterTypeChange($event)"
                                            [required]></p-dropdown>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-6 pb-2">
                                    <div class="p-field">
                                        <label class="pb-1" for="phase">Phase</label>
                                        <p-dropdown id="phase" [options]="phases" formControlName="phase"
                                            placeholder="Select a Phase..." optionLabel="displayValue"
                                            [required]></p-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-6 pb-2">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="p-field">
                                                <label class="pb-1" for="responsible-attorneys">Responsible
                                                    Attorney</label>
                                                <p-dropdown id="responsible-attorneys" [options]="attorneys"
                                                    formControlName="responsibleAttorney"
                                                    placeholder="Select a Responsible Attorney..."
                                                    optionLabel="displayValue" [required]></p-dropdown>
                                            </div>
                                        </div>
                                        <div class="mt-2 col-sm-12 col-md pb-2 p-field">
                                            <app-responsible-attorney-view-change-history-popup
                                                (processCompleted)="onProcessCompleted($event)"></app-responsible-attorney-view-change-history-popup>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-sm-12 col-6 pb-2">
                                    <div class="p-field">
                                        <label class="pb-1" for="matter-statues">Matter Status</label>
                                        <p-dropdown id="matter-statues" [options]="matterStatuses"
                                            formControlName="matterStatus" placeholder="Select a Matter Status..."
                                            optionLabel="displayValue" [required]></p-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md pb-2">
                                    <div class="p-field">
                                        <label class="pb-1" for="originating-attorneys">Originating Attorney</label>
                                        <p-dropdown id="originating-attorneys" [options]="attorneys"
                                            formControlName="originatingAttorney"
                                            placeholder="Select an Originating Attorney..." optionLabel="displayValue"
                                            [required]></p-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="(currentMatterId > 0)" class="row">
                                <div class="col-sm-12 col-md pb-2">
                                    <div class="p-field">
                                        <label class="pb-1" for="assigned-attorneys">Assigned Attorney</label>
                                        <p-dropdown id="assigned-attorneys" [options]="attorneys"
                                            formControlName="assignedAttorney" placeholder="Assigned Attorney..."
                                            optionLabel="displayValue" [required]></p-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="(currentMatterId > 0)" class="row">
                                <div class="col-sm-12 col-md pb-2">
                                    <div class="p-field">
                                        <label class="pb-1" for="paralegal">Paralegal</label>
                                        <p-dropdown id="paralegal" [options]="paralegals" formControlName="paralegal"
                                            placeholder="Paralegal..." optionLabel="displayValue"
                                            [required]></p-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="(currentMatterId > 0)" class="col-sm">
                    <div class="row">
                        <div class="col">
                            <div *ngIf="showOpinionType()" class="card mt-2">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="p-field">
                                                <label class="pb-1" for="opinion-type">Opinion Type</label>
                                                <p-dropdown id="opinion-type" [options]="OpinionTypes"
                                                    formControlName="opinionType" optionLabel="Type"></p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="showApplication()" class="card mt-2">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md pb-2">
                                            <div class="p-field">
                                                <label class="pb-1" for="application-type">Application Type</label>
                                                <p-dropdown id="application-type" [options]="applicationTypes"
                                                    formControlName="applicationType" placeholder="Application Type..."
                                                    optionLabel="displayValue" [required]></p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row pb-2">
                                        <div class="col d-flex flex-column gap-1">
                                            <label for="application-serial-number">Application Serial Number</label>
                                            <input pInputText id="application-serial-number"
                                                formControlName="applicationSerialNumber">
                                        </div>
                                    </div>
                                    <div class="row pb-2">
                                        <div class="col d-flex flex-column gap-1">
                                            <label for="filing-dt">Filing Date</label>
                                            <p-calendar formControlName="filingDT"></p-calendar>
                                        </div>
                                    </div>
                                    <div *ngIf="showPatentNumber()" class="row pb-2">
                                        <div class="col d-flex flex-column gap-1">
                                            <label for="patent-number">Patent Number</label>
                                            <input pInputText id="patent-number" formControlName="patentNumber">
                                        </div>
                                    </div>
                                    <div *ngIf="showIssueDate()" class="row pb-2">
                                        <div class="col d-flex flex-column gap-1">
                                            <label for="issue-dt">Issue Date</label>
                                            <p-calendar formControlName="registrationDT"></p-calendar>
                                        </div>
                                    </div>
                                    <div *ngIf="showCountry()" class="row pb-2">
                                        <div class="col d-flex flex-column gap-1">
                                            <label for="country">Country</label>
                                            <input pInputText id="country" formControlName="country">
                                        </div>
                                    </div>
                                    <div *ngIf="showRegistrationNumber()" class="row pb-2">
                                        <div class="col d-flex flex-column gap-1">
                                            <label for="reg-number">Registration Number</label>
                                            <input pInputText id="reg-number" formControlName="registrationNumber">
                                        </div>
                                    </div>
                                    <div *ngIf="showRegistrationDate()" class="row pb-2">
                                        <div class="col d-flex flex-column gap-1">
                                            <label for="issue-dt">Registration Date</label>
                                            <p-calendar formControlName="registrationDT"></p-calendar>
                                        </div>
                                    </div>
                                    <div *ngIf="showReceivingOffice()" class="row pb-2">
                                        <div class="col d-flex flex-column gap-1">
                                            <label for="receiving-office">Receiving Office</label>
                                            <input pInputText id="receiving-office" formControlName="receivingOffice">
                                        </div>
                                    </div>
                                    <div *ngIf="showSOUCompleted()" class="row col pb-2">
                                        <div>
                                            <p-checkbox formControlName="isSOUCompleted" [binary]="true"
                                                inputId="sou-completed"></p-checkbox>
                                            <label for="sou-completed">&nbsp;&nbsp;SOU Completed</label>
                                        </div>
                                    </div>
                                    <div *ngIf="showGoodsServices()" class="row col">
                                        <div class="d-flex flex-column gap-1">
                                            <label for="goods-services">Goods & Services</label>
                                            <textarea pKeyFilter="int" rows="5" cols="30"
                                                formControlName="goodsServices" pInputTextarea></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div *ngIf="showFileForeign()" class="card mt-2">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <h6>Options</h6>
                                        </div>
                                        <div class="col-12">
                                            <div class="p-field">
                                                <label class="pb-1" for="file-foreign">File Foreign</label>
                                                <p-dropdown id="file-foreign" [options]="fileForeigns"
                                                    formControlName="fileForeign" optionLabel="Type"
                                                    [required]></p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card mt-2">
                                <div class="card-body">
                                    <div class="row pt-2">
                                        <div class="col-12">
                                            <h6>Pay Options</h6>
                                        </div>
                                        <div class="col-12">
                                            <div class="d-flex align-items-center">
                                                <label for="is-pay-after">Advance&nbsp;&nbsp;</label>
                                                <p-inputSwitch inputId="is-pay-after"
                                                    formControlName="isPayAfter"></p-inputSwitch>
                                                <label for="is-pay-after">&nbsp;&nbsp;Pay After</label>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="d-flex align-items-center">
                                                <label for="is-flat-rate">Hourly&nbsp;&nbsp;</label>
                                                <p-inputSwitch inputId="is-flat-rate"
                                                    formControlName="isFlatRate"></p-inputSwitch>
                                                <label for="is-flat-rate">&nbsp;&nbsp;Flat Rate</label>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="d-flex align-items-center">
                                                <label for="is-scheduled">Completion&nbsp;&nbsp;</label>
                                                <p-inputSwitch inputId="is-scheduled"
                                                    formControlName="isScheduled"></p-inputSwitch>
                                                <label for="is-scheduled">&nbsp;&nbsp;Scheduled</label>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="d-flex align-items-center">
                                                <label for="is-not-billable">Billable&nbsp;&nbsp;</label>
                                                <p-inputSwitch inputId="is-not-billable"
                                                    formControlName="isNotBillable"></p-inputSwitch>
                                                <label for="is-not-billable">&nbsp;&nbsp;Not Billable</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card mt-2">
                                <div class="card-body">
                                    <div class="row col pt-2">
                                        <h6>Current Project Budget</h6>
                                        <div class="row">
                                            <div class="col d-flex flex-column gap-1">
                                                <label for="budget-amount">Budget Amount</label>
                                                <input pInputText id="budget-amount" formControlName="budgetAmount">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="p-field">
                                                <label class="pb-1" for="budget-type">Budget Type</label>
                                                <p-dropdown id="budget-type" [options]="budgetTypes"
                                                    formControlName="budgetType" placeholder="Budget Type..."
                                                    optionLabel="displayValue" [required]></p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="activeMenuItem.label === 'Memos'">
                <div class="row pt-2">
                    <div class="col">
                        <div class="d-flex flex-column gap-1">
                            <label for="client-memo">Client Memo</label>
                            <textarea pKeyFilter="int" rows="5" cols="30" formControlName="clientMemo"
                                pInputTextarea></textarea>
                        </div>
                    </div>
                    <div class="col">
                        <div class="d-flex flex-column gap-1">
                            <label for="internal-matter-and-docker-memo">Internal Matter & Docket Memo</label>
                            <textarea pKeyFilter="int" rows="5" cols="30" formControlName="intMatDocMemo"
                                pInputTextarea></textarea>
                        </div>
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col">
                        <div class="d-flex flex-column gap-1">
                            <label for="note">Note</label>
                            <textarea pKeyFilter="int" rows="5" cols="30" formControlName="note"
                                pInputTextarea></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <app-time-entry-popup #timeEntryEditComponent [isEdit]="true" [currentMatterId]="currentMatterId"
                [canChangeMatter]="false" (savedTimeEntry)="refreshMatterTimes()"></app-time-entry-popup>
            <div *ngIf="activeMenuItem.label === 'Time'">
                <div class="mb-4 mt-4">
                    <button class="btn btn-primary" (click)="refreshMatterTimes()" type="button">Refresh Time
                        Entries</button>
                </div>
                <dx-data-grid #matterTimesGrid [dataSource]="matterTimeDataSource" [remoteOperations]="false"
                    [allowColumnReordering]="true" [hoverStateEnabled]="true" [showBorders]="true"
                    (onRowClick)="onRowClick($event)" [wordWrapEnabled]="true">
                    <dxo-paging [pageSize]="5"> </dxo-paging>
                    <dxo-pager [visible]="true" [allowedPageSizes]="[5, 10, 15]" displayMode="full"
                        [showPageSizeSelector]="true" [showInfo]="true" [showNavigationButtons]="true">
                    </dxo-pager>
                    <dxi-column dataField="matterRef" [width]="155" caption="Matter"></dxi-column>
                    <dxi-column dataField="eeRef" [width]="75" caption="Staff"></dxi-column>
                    <dxi-column dataField="entryDate" [width]="100" dataType="date" caption="Entry Date"></dxi-column>
                    <dxi-column dataField="hoursQty" [width]="100" caption="Hours Qty"></dxi-column>
                    <dxi-column dataField="noCharge" [width]="105" caption="No Charge"></dxi-column>
                    <dxi-column dataField="nonBill" [width]="85" caption="Non-Bill"></dxi-column>
                    <dxi-column dataField="entryDesc" caption="Desc"></dxi-column>
                    <dxi-column dataField="timeExpAmt" dataType="number" [width]="85" caption="Amount">
                        <dxo-format type="currency" [precision]="2">
                        </dxo-format>
                    </dxi-column>
                    <dxi-column dataField="billId" [width]="95" caption="Invoice #"></dxi-column>
                </dx-data-grid>
            </div>
        </form>
    </div>
</div>