import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, finalize, forkJoin, map, Observable, switchMap, tap} from 'rxjs';
import { Document, IDocumentCategory } from 'src/app/modules/core/models/document';
import { DocumentApiService } from 'src/app/modules/core/services/document-api/document-api.service';
import { PropertyStatus } from 'src/app/modules/shared/constants';
import { DocumentVersion } from '../../models/document-version';
import { IProperty } from '../../models/property';
import { ICopyRequest, SelectionNode } from '../../models/selection-node';
import { PropertyApiService } from '../../services/property-api/property-api.service';
import { ToastService } from '../../services/toast/toast.service';


@Injectable()
export class DocumentDetailsService {
    private _propertyCategories: BehaviorSubject<IDocumentCategory[]> = new BehaviorSubject<IDocumentCategory[]>([]);
    private _documentVersions: BehaviorSubject<DocumentVersion[]> = new BehaviorSubject<DocumentVersion[]>([]);
    private _selectionNodes: BehaviorSubject<SelectionNode[]> = new BehaviorSubject<SelectionNode[]>([]);
    private _dataLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    get documentVersions$(): Observable<DocumentVersion[]> {
        return this._documentVersions.asObservable();
    }

    get propertyCategories$(): Observable<IDocumentCategory[]> {
        return this._propertyCategories.asObservable();
    }

    get propertyCategoriesSnapshot(): IDocumentCategory[] {
        return this._propertyCategories.value;
    }

    get documentVersionsSnapshot(): DocumentVersion[] {
        return this._documentVersions.value;
    }

    get selectionNodes$(): Observable<SelectionNode[]> {
        return this._selectionNodes.asObservable();
    }

    get dataLoading$(): Observable<boolean> {
        return this._dataLoading.asObservable();
    }

    constructor(private api: DocumentApiService, private propertyApi: PropertyApiService, private toast: ToastService) {
    }

    public initData(documentId: string, propertyId: string){
        this._dataLoading.next(true);
        forkJoin({
            categories: this.getPropertyCategories(propertyId),
            properties: this.getOpenProperties(),
            _: this.getDocumentVersions(documentId),
        }).subscribe(({categories: categories, properties}) => {
            this._selectionNodes.next(properties.map(p => new SelectionNode(p, categories.filter(t => t.propertyId === p.id))));
            this._dataLoading.next(false);
        });
    }

    private getPropertyCategories(propertyId: string): Observable<IDocumentCategory[]> {
        return this.api.getPropertyCategories().pipe(
            map(response => {
                this._propertyCategories.next(response.data.filter(t => t.propertyId === propertyId));
                return response.data;
            }),
        );
    }

    public getOpenProperties(): Observable<IProperty[]>{
        return this.propertyApi.getProperties(PropertyStatus.Active).pipe(map(response =>  response.data));
    }

    public getDocumentVersions(documentId: string): Observable<boolean> {
        return this.api.getDocumentVersions(documentId).pipe(
            map(response => {
                const versions = response.data.map(d => new DocumentVersion(d));
                this._documentVersions.next(versions);
                return true;
            }),
        );
    }

    public downloadDocumentVersion(versionId: string): Observable<boolean> {
        return this.api.downloadDocumentVersion(versionId);
    }

    public uploadNewDocumentVersion(file: FormData, documentId: string): Observable<boolean> {
        this._dataLoading.next(true);
        return this.api.uploadDocumentVersion(file, documentId).pipe(
            map(res =>  {
                this.toast.success(res.message);
                this.getDocumentVersions(documentId).subscribe(_ => this._dataLoading.next(false));
                return true;
            }),
            catchError(_ => {
                this._dataLoading.next(false);
                throw new Error('Error uploading document version.');
            })
        );
    }

    public copyDocument(request: ICopyRequest, categoryName: string): Observable<boolean> {
        this._dataLoading.next(true);
        return this.api.copyDocument(request).pipe(
            map(_ => {
                this.toast.success(`Copied to ${categoryName}`);
                return true;
            }),
            finalize(() => this._dataLoading.next(false))
        );
    }

    public moveDocument(document: Document, categoryName: string): Observable<boolean> {
        return this.api.updateDocument(document).pipe(
            map(_ => {
                this.toast.success(`Moved to ${categoryName}`);
                return true;
            })
        );
    }

    public saveDocument(document: Document): Observable<boolean> {
        return this.api.updateDocument(document).pipe(
            map(response => {
                this.toast.success(response.message);
                return true;
            })
        );
    }

    public deleteDocumentVersion(documentVersionId: string, documentId: string): Observable<boolean> {
        return this.api.deleteDocumentVersion(documentVersionId).pipe(
            tap(response => this.toast.success(response.message)),
            switchMap(_ => {
                return this.getDocumentVersions(documentId);
            }),
        );
    }
}
