import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IDocumentCategory, IFileUpload } from '../../models/document';
import { UploadDocumentsService } from './upload-documents.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-upload-documents',
    templateUrl: './upload-documents.component.html',
    providers: [UploadDocumentsService],
})
export class UploadDocumentsComponent implements OnInit {
    public incomingFiles: File[];
    public propertyId: string;
    public categoryId: string;
    public categories: Observable<IDocumentCategory[]>;
    public uploadRequest: IFileUpload[] = [];
    public validForm: boolean = false;

    constructor(
    private documentSvc: UploadDocumentsService,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    ) {
        this.incomingFiles = this.dialogConfig.data.incomingFiles;
        this.propertyId = this.dialogConfig.data.propertyId;
        this.categoryId = this.dialogConfig.data.categoryId;
        this.documentSvc.getPropertyCategories(this.propertyId);
    }

    ngOnInit(): void {
        this.categories = this.documentSvc.categories$;
    }

    cancel() {
        this.dialogRef.close();
    }

    handleSelectionChange(selectedFiles: IFileUpload[]) {
        this.uploadRequest = selectedFiles;
        this.validForm = selectedFiles.every(x => x.name !== null && x.name !== '');
    }

    upload() {
        this.dialogRef.close();
        this.documentSvc.uploadDocuments(this.uploadRequest, this.propertyId).subscribe();
    }
}
