import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Matter } from '../../models/matter.interface';
import { MatterTimeService } from '@core.services/timekeeping/matter-time.service';
import { TimeEntryFormComponent } from '../time-entry-form/time-entry-form.component';
import { ConfirmationService } from 'primeng/api';
import { ToastService } from '@core.services/toast/toast.service';
import { UserService } from '@core.services/user/user.service';
import { TimerService } from '@core.services/timekeeping/timer.service';
import { TimerWithRun } from '@core.models/timer.model';

@Component({
    selector: 'app-time-entry-popup',
    templateUrl: './time-entry-popup.component.html',
    styleUrls: ['./time-entry-popup.component.scss']
})
export class TimeEntryPopupComponent {
    @ViewChild('timeEntryFormComp', { static: false }) timeEntryFormComp: TimeEntryFormComponent;

    timeEntryPopupVisible = false;
    mobileSectionVisible = false;
    closeButtonOptions: any;

    @Input() showCreateButton = true;
    @Input() isEdit = false;
    @Input() buttonText = 'New Time Entry';
    @Input() closeOnContactClicked = true;
    @Input() currentMatterId: number = 0;
    @Input() canChangeMatter = true;
    @Input() container: string = '.form-container';
    @Input() currentMatterTimeId: number = 0;
    @Output() savedTimeEntry = new EventEmitter<any>();

    constructor(public matterTimeService: MatterTimeService, private toast: ToastService, private confirmationService: ConfirmationService, private userService: UserService, private timerService: TimerService) { }

    public emitMatterTimeId(matterTimeId: number) {
        if (this.closeOnContactClicked) {
            this.timeEntryPopupVisible = false;
        }
        this.savedTimeEntry.emit(matterTimeId);
    }

    public onEditTimeEntryClicked(matterTimeId: number, matterId: number): void {
        this.currentMatterTimeId = matterTimeId;
        this.currentMatterId = matterId;
        const tenthOfASec = 100;
        setTimeout(() => {
            this.timeEntryPopupVisible = true;
            this.matterTimeService.triggerLoadModuleData();
        }, tenthOfASec);
    }

    public newTimeEntryInit(openTimer?: TimerWithRun) {
        this.currentMatterTimeId = 0;
        this.timeEntryPopupVisible = true;

        if (openTimer) {
            this.timerService.openTimer = openTimer;
        } else {
            const eeId = this.userService.userSnapshot?.eeInfo.eeId;

            this.timerService.openTimer = this.timerService.spawnTimer(
                null,
                null,
                null,
                eeId,
                false,
                false
            );
        }

        this.matterTimeService.triggerLoadModuleData();
    }

    public closePopup($event: Event) {
        let canClose = true;
        for (const key in this.timeEntryFormComp.timeEntryForm.controls) {
            if (this.timeEntryFormComp.timeEntryForm.controls[key].dirty) canClose = false;
        }
        if (canClose) {
            this.currentMatterTimeId = 0;
            this.timeEntryPopupVisible = false;
        } else {
            this.confirmationService.confirm({
                target: $event.target as EventTarget,
                message: 'Are you sure you want to close without saving?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.timeEntryPopupVisible = false;
                },
                reject: () => { }
            });
        }
    }
}
