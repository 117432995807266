export interface IAIPRole {
  roleId: number;
  roleDescription: string | null;
  reportAccess: string | null;
  loadDate: Date | null;
}
export class AIPRole implements IAIPRole {
  roleId: number;
  roleDescription: string | null;
  reportAccess: string | null;
  loadDate: Date | null;

    constructor(init?: Partial<IAIPRole>) {
        if (init?.roleId) this.roleId = init.roleId;
        if (init?.roleDescription) this.roleDescription = init.roleDescription;
        if (init?.reportAccess) this.reportAccess = init.reportAccess;
        if (init?.loadDate) this.loadDate = init.loadDate;
    }
}
