import { IDocumentCategory } from './document';
import { Property } from './property';
import {TruncatePipe} from '../../shared/truncate.pipe';

const TRUNC_LIMIT_LENGTH = 30;
export class SelectionNode {
    key: string;
    label: string;
    data?: ISelectionNodeData;
    expandedIcon: string;
    collapsedIcon: string;
    selectable: boolean;
    children: SelectionNode[] = [];

    constructor(property: Property, categories: IDocumentCategory[]) {
        this.key = property.id;
        this.label = property.name;
        this.data = null;
        this.expandedIcon = 'pi pi-building';
        this.collapsedIcon = this.expandedIcon;
        this.selectable = false;

        const truncatePipe = new TruncatePipe();
        for (let i = 0 ; i < categories.length; i++) {
            const node: SelectionNode = {
                key: property.id + categories[i].id,
                label: truncatePipe.transform(categories[i].name, TRUNC_LIMIT_LENGTH),
                data: {categoryId: categories[i].id, categoryName: categories[i].name, propertyId: property.id, propertyName: property.name},
                expandedIcon: 'pi pi-folder',
                collapsedIcon: 'pi pi-folder',
                selectable: true,
                children: []
            };
            this.children.push(node);
        }
    }
}

export interface ISelectionNodeData {
    categoryId: string;
    categoryName: string;
    propertyId: string;
    propertyName: string;
}

export interface ICopyRequest {
    fileInfoIdToCopy: string;
    categoryId: string;
    propertyId: string;
}
