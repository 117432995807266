import { BehaviorSubject, Observable } from 'rxjs';

export interface IUser {
    id: string;
    firstName: string;
    lastName: string;
    userName: string;
    phoneNumber: string;
    emailConfirmed: boolean;
    emailSent: boolean;
    roles: string[];
    lastPasswordReset: Date;
    twoFactorEnabled: boolean;
    twoFactorType: string;
    twoFactorConfirmed: boolean;
    notificationsEnabled: boolean;
    profilePic: string;
    standardLoginEnabled: boolean;
}
export class User implements IUser {
    id: string;
    firstName: string;
    lastName: string;
    userName: string;
    phoneNumber: string;
    emailConfirmed: boolean;
    emailSent: boolean;
    roles: string[];
    lastPasswordReset: Date;
    twoFactorEnabled: boolean;
    twoFactorType: string;
    twoFactorConfirmed: boolean;
    notificationsEnabled: boolean;
    profilePic: string;
    standardLoginEnabled: boolean;

    eeInfo$ = new BehaviorSubject<eeInfo>(null);
    get eeInfo(): eeInfo {
        return this.eeInfo$.value;
    }
    set eeInfo(value: eeInfo) {
        this.eeInfo$.next(value);
    }

    constructor(init?: Partial<IUser>) {
        if (init?.id) this.id = init.id;
        this.firstName = init?.firstName ?? null;
        this.lastName = init?.lastName ?? null;
        this.userName = init?.userName ?? null;
        this.phoneNumber = init?.phoneNumber ?? null;
        this.emailConfirmed = init?.emailConfirmed ?? false;
        this.emailSent = init?.emailSent ?? false;
        this.roles = init?.roles ?? [];
        this.lastPasswordReset = init?.lastPasswordReset ?? null;
        this.twoFactorEnabled = init?.twoFactorEnabled ?? false;
        this.twoFactorType = init?.twoFactorType ?? 'email';
        this.twoFactorConfirmed = init?.twoFactorConfirmed ?? false;
        this.notificationsEnabled = init?.notificationsEnabled ?? false;
        this.profilePic = init?.profilePic ?? null;
        this.standardLoginEnabled = init?.standardLoginEnabled ?? false;
    }
}

export interface eeInfo {
    eeId: number;
    eeRef: string;
    eeFullName: string;
}
