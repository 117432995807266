import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { finalize, Observable, Subject, takeUntil, tap } from 'rxjs';
import { CategoryGroup, Document, IDocumentCategory } from '../../models/document';
import { UploadDocumentsComponent } from '../../components/upload-documents/upload-documents.component';
import { DocumentsByPropertyService } from './documents-by-property.service';
import { DocumentDetailsComponent } from '../../components/document-details/document-details.component';
import { UserService } from '../../services/user/user.service';
import { Property } from '../../models/property';
import { UserRole } from 'src/app/modules/shared/constants';
import { EventService } from '../../services/event/event.service';

@Component({
    selector: 'app-documents-by-property',
    templateUrl: './documents-by-property.component.html',
    styleUrls: ['./documents-by-property.component.scss'],
    providers: [DocumentsByPropertyService]
})
export class DocumentsByPropertyComponent implements OnInit, OnDestroy {
    public categoryGroups: Observable<CategoryGroup[]>;
    public categories: IDocumentCategory[];
    public propertyId: string;
    public currentDocumentId: string;
    public showConfirmModal: boolean;
    public expandCategories: boolean = false;
    public expandedRows: { [id: string]: boolean; } = {};
    public property: Property;
    public displayModal: boolean;
    public items: MenuItem[];
    public home: MenuItem;
    public loading: boolean = false;
    public isAdmin: boolean;
    public allowStatusChange: boolean = false;
    public UserRole = UserRole;
    public onDestroy$: Subject<boolean> = new Subject();


    constructor(
    private documentSvc: DocumentsByPropertyService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    public userService: UserService,
    private eventService: EventService,
    ) { }


    ngOnInit(): void {
        this.items = [];
        this.home = {label:' Properties', icon: 'pi pi-home', routerLink: '/app/properties'};

        this.activatedRoute.params.subscribe((params: Params) => {
            this.propertyId = params['propertyId'];
            this.documentSvc.getPropertyCategories(this.propertyId).subscribe(categories => {
                this.categories = categories;
            });
            this.documentSvc.getPropertyById(this.propertyId).pipe(
                tap((res) => {
                    this.property = res.data;
                    this.allowStatusChange = this.isAdmin || (this.property.accountManager.userName === this.userService.getUserName());
                }),
            ).subscribe();
        });
        this.isAdmin = this.userService.getRole() === UserRole.Admin;
        this.categoryGroups = this.documentSvc.categoryGroups$;

        this.reloadDocuments();
        this.eventService.uploadsCompleted$.pipe(takeUntil(this.onDestroy$)).subscribe(event => {
            if (event?.propertyId === this.propertyId) {
                this.reloadDocuments();
            }
        });
        this.categoryGroups.subscribe((x: CategoryGroup[]) =>
            x.forEach((y: CategoryGroup) => { this.expandedRows[y.id] = false; }));
    }
    fileIcon(filename: string){
        const extension = filename.split('.').pop();
        if (extension === 'pdf')  return 'pdf';
        if (extension === 'xlsx') return 'excel';
        if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') return 'images';
        return 'file';
    }
    expandAllToggle() {
        if (this.expandCategories) {
            this.collapseRows();
        } else {
            this.expandRows();
        }
    }
    expand(id: string){
        this.expandedRows[id] = !this.expandedRows[id];
    }
    expandRows() {
        this.categoryGroups.subscribe((x: CategoryGroup[]) =>
            x.forEach((y: CategoryGroup) => { this.expandedRows[y.id] = true; }));
        this.expandCategories = true;
    }
    collapseRows() {
        this.categoryGroups.subscribe((x: CategoryGroup[]) =>
            x.forEach((y: CategoryGroup) => { this.expandedRows[y.id] = false; }));
        this.expandCategories = false;
    }

    findLatest(files:any[]){
        const dates = files.map(x => new Date(x.createDate.slice(0, -1)));
        return dates.reduce((first,second) => first.getTime() >second.getTime() ? first : second);
    }

    deleteDocument(id: string) {
        this.currentDocumentId = id;
        this.showConfirmModal = true;
    }

    updateCategoryStatus(categoryId: string, newStatus: string) {
        this.documentSvc.updateCategoryStatus(categoryId, newStatus).subscribe(_ => {
            this.reloadDocuments();
        });
    }
    updateCategory(newCategory: IDocumentCategory, document: Document) {
        this.documentSvc.moveDocument(newCategory, document).subscribe(_ => this.reloadDocuments());
    }

    showUploadDialog(files: File[] = [], categoryId: string = null) {
        const dialog = this.dialogService.open(UploadDocumentsComponent, {
            header: 'Upload Documents',
            width: '70%',
            data: {
                propertyId: this.propertyId,
                incomingFiles: files,
                categoryId: categoryId
            }
        });
    }

    showDocumentDetails(document: Document) {
        const dialog = this.dialogService.open(DocumentDetailsComponent, {
            header: 'Document Details',
            width: '70%',
            data: {
                document,
                allowStatusChange: this.allowStatusChange,
                propertyId: this.propertyId
            },
            style: {'overflow-y': 'auto'}
        });
        dialog.onClose.subscribe(_ => {
            this.reloadDocuments();
        });
    }

    onConfirmDelete() {
        this.showConfirmModal = false;
        this.documentSvc.deleteDocument(this.currentDocumentId).pipe(
            tap(() => this.reloadDocuments()),
        ).subscribe();
    }

    onCancelDelete() {
        this.showConfirmModal = false;
    }

    reloadDocuments(): void {
        this.loading = true;
        this.documentSvc.getPropertyDocuments(this.propertyId).pipe(
            finalize(() => { this.loading = false; })
        ).subscribe();
    }

    search(event: any, dt: Table) {
        if (event.target.value) this.expandRows();
        else this.collapseRows();
        dt.filterGlobal(event.target.value, 'contains');
    }

    dropFunction(event: DragEvent, categoryGroup: CategoryGroup) {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files.length > 0) {
            const fileListAsArray = Array.from(event.dataTransfer.files);
            this.showUploadDialog(fileListAsArray, categoryGroup.id);
        }
    }

    dragFunction(event: any) {
        event.preventDefault();
        event.stopPropagation();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }
}
