import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { ParamObj, ParamQuery } from '../../models/param-query.interface';
import { map, Observable } from 'rxjs';
import { ActionOdataResult, ActionResult } from '../../models/action-result.interface';
import { environment } from 'src/environments/environment';
import { FormFieldListItem } from '../../models/form-field-list-item.interface';


@Injectable({
    providedIn: 'root'
})
export class FormFieldLookupService {

    constructor(private http: HttpClient, private userService: UserService) { }
    /**
     * Returns an array of items for a form field lookup based on 'field' and 'secondaryField'
     */
    public get(field: string, secondaryField: string = ''): Observable<Array<FormFieldListItem>> {
        return this.getFormFieldLookups(field, secondaryField).pipe(map((results) => results as Array<FormFieldListItem>));
    }

    private getFormFieldLookups(field: string, secondaryField: string = ''): Observable<Array<FormFieldListItem>> {
        return this.http.get<Array<FormFieldListItem>>(`${environment.apiBaseURL}ListFormFieldLookup?field=${field}&secondaryField=${secondaryField}`);
    }
}
