<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="mb-1">
    <label for="passwordInput" class="form-label">New Password</label>

    <input
      type="password"
      [ngClass]="{
        'form-control': true,
        'is-invalid':
          form.controls['password'].invalid &&
          (form.controls['password'].dirty || form.controls['password'].touched)
      }"
      id="passwordInput"
      aria-describedby="passwordHelp"
      formControlName="password"
    >
    <ng-container
      *ngIf="
        form.controls['password'].invalid &&
        (form.controls['password'].dirty || form.controls['password'].touched)
      "
    >
      <div
        class="invalid-feedback"
        *ngIf="form.controls['password'].errors?.['required']"
      >
        Password required.
      </div>
      <div
        class="invalid-feedback"
        *ngIf="form.controls['password'].errors?.['pattern']"
      >
        Password should contain an uppercase letter, lowercase letter, number,
        symbol and be at least 6-character long.
      </div>
    </ng-container>
  </div>
  <div class="mb-3">
    <label for="confirmPasswordInput" class="form-label"
      >Confirm New Password</label
    >

    <input
      type="password"
      [ngClass]="{
        'form-control': true,
        'is-invalid':
          form.errors?.['passwordMatch'] ||
          (form.controls['confirmedPassword'].invalid &&
          (form.controls['confirmedPassword'].dirty ||
            form.controls['confirmedPassword'].touched))
      }"
      id="confirmPasswordInput"
      aria-describedby="confirmPasswordHelp"
      formControlName="confirmedPassword"
    >
    <div
      class="invalid-feedback"
      *ngIf="form.controls['confirmedPassword'].errors?.['required']"
    >
      Password confirmation required.
    </div>
    <div class="invalid-feedback" *ngIf="form.errors?.['passwordMatch']">
      Passwords do not match.
    </div>
  </div>
  <div class="mb-3">
    <input type="checkbox" (click)="changeVisibility()">
    &nbsp;Show password
  </div>
  <div class="d-flex flex-row-reverse">
    <button type="submit" class="btn btn-primary">Submit</button>
  </div>
</form>
