<div *ngIf="popupLoading">
    <div class="loader">
        <p-progressSpinner></p-progressSpinner>
    </div>
</div>
<div *ngIf="!popupLoading">
    <div>
        <h5>{{currentMatter?.matterRef}} - {{currentMatter?.matterTitle}}</h5>
    </div>
    <div class="mt-4 mb-4 text-center">
        <h2>Responsible Attorney History View / Edit</h2>
    </div>
    <dx-data-grid #responsiblesGrid [dataSource]="matterResponsibleAttorneys" [remoteOperations]="false"
        [allowColumnReordering]="true" [hoverStateEnabled]="true" [showBorders]="true"
        (onRowClick)="onRowClick($event)">
        <dxi-column dataField="responsible" caption="Responsible">
            <dxo-lookup [dataSource]="ees" valueExpr="eeRef" displayExpr="fullName"></dxo-lookup>
        </dxi-column>
        <dxi-column dataField="startDate" dataType="date" caption="Start Date"></dxi-column>
        <dxi-column dataField="endDate" dataType="date" caption="End Date"></dxi-column>
    </dx-data-grid>
    <div>
        * Click row above to edit
    </div>
    <div class="card mt-4">
        <div class="card-body row">
            <div class="col-sm-12 col-md pb-2">
                <div class="p-field">
                    <label class="pb-1" for="organization-type">Action</label>
                    <dx-select-box [items]="actionTypes" [(value)]="currentActionType" displayExpr="Type"></dx-select-box>
                </div>
            </div>
            <div class="col-sm-12 col-md pb-2">
                <div class="p-field">
                    <label class="pb-1" for="company-role">Responsible</label>
                    <dx-select-box [items]="ees" [(value)]="responsibleAttorney" displayExpr="fullName"></dx-select-box>
                </div>
            </div>
            <div class="col-sm-12 col-md signed-col">
                <div class="col d-flex flex-column gap-1">
                    <label for="annual-filing-dt">Start Date</label>
                    <dx-date-box type="date" [useMaskBehavior]="true" [inputAttr]="{ 'aria-label': 'Date' }"
                        [(ngModel)]="startDate"></dx-date-box>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-4 d-none d-sm-block col-sm">
        <button class="btn btn-primary" type="button" (click)="processRequest()">Process Request</button>
    </div>
    <dx-toast [(visible)]="showErrorMessage" type="error" [displayTime]="8000" [message]="currentErrorMessage"></dx-toast>
</div>