const API_BASE_URL = 'https://aiptest.bufip.com/api/';


export const environment = {
  production: false,
  apiBaseURL: API_BASE_URL,
  azureADAuthProvider: {
    clientId: "4ee96310-6883-4fb0-86c3-5584f399d023",
    tenantId: "46583c47-44fc-4da8-8660-2e591f151a9b"
  },
  googleClientId: "<google-client-id>",
};
