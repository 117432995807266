export interface IRole {
  id: string;
  name: string;
  users: string[];
}
export class Role implements IRole {
    id: string;
    name: string;
    users: string[];

    constructor(init?: Partial<IRole>) {
        if (init?.id) this.id = init.id;
        if (init?.name) this.name = init.name;
        if (init?.users) this.users = init.users;
    }
}
