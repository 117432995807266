export enum ApiResponseType {
  Error = 'error',
  Success = 'success'
}

export interface IApiResponse {
    type: ApiResponseType,
    message: string;
}

export interface IApiDataResponse<T> extends IApiResponse {
  data: T
}
