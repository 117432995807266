import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Contact } from '../../models/contact.interface';
import { ContactService } from '../../services/contact/contact.service';
import { ODataFilterBuilder } from 'odata-filter-builder';
import { ParamObj } from '../../models/param-query.interface';
import { forkJoin, map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { ToastService } from '../../services/toast/toast.service';
import { ContactRequest } from '../../models/contact-request.interface';
import { ContactSearchResult } from '../../models/contact-search-result.interface';
import { FormFieldListItem } from '../../models/form-field-list-item.interface';
import { FormFieldLookupService } from '../../services/lookup/form-field-lookup.service';

@Component({
    selector: 'app-contact-details',
    templateUrl: './contact-details.component.html',
    styleUrls: ['./contact-details.component.scss']
})
export class ContactDetailsComponent implements OnInit {
    public isFormLoading: boolean = true;
    public isArchived: boolean = false;
    private sameAsBillingSubject$ = new Subject<boolean>();
    public attorneys: Array<FormFieldListItem> = [];
    public currentContactId: number = 0;
    public contactForm: FormGroup = null;

    public organizationTypes: Array<any> = [
        { Id: 0, Type: 'Individual' },
        { Id: 1, Type: 'Organization' }
    ];

    public companyRoles: Array<any> = [
        { Id: 0, Type: 'Client - Current' },
        { Id: 1, Type: 'Client - Prospective' },
        { Id: 2, Type: 'Client - Former' },
        { Id: 3, Type: 'Employee' },
        { Id: 4, Type: 'Former Employee' },
        { Id: 5, Type: 'Accountant' },
        { Id: 6, Type: 'Attorney' },
        { Id: 7, Type: 'Engineer/Design/Manuf' },
        { Id: 8, Type: 'Financial/Insurance' },
        { Id: 9, Type: 'Govt Office' },
        { Id: 10, Type: 'IT/Network/Software' },
        { Id: 11, Type: 'Marketing/Advertising' },
        { Id: 12, Type: 'Media/News/PR' },
        { Id: 13, Type: 'Other' },
        { Id: 14, Type: 'Other Professional' },
        { Id: 15, Type: 'Outside Consultant' },
        { Id: 16, Type: 'Restaurant' },
        { Id: 17, Type: 'Vendor' },
    ];

    public fileForeigns: Array<any> = [
        { Id: 0, Type: 'Check' },
        { Id: 1, Type: 'Yes' },
        { Id: 2, Type: 'No' }
    ];

    public entityStatuses: Array<any> = [
        { Id: 0, Type: 'Large' },
        { Id: 1, Type: 'Small' },
        { Id: 2, Type: 'Micro' }
    ];

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private contactService: ContactService,
        private toast: ToastService,
        private lookupService: FormFieldLookupService
    ) {

    }

    public ngOnInit(): void {
        this.currentContactId = +this.activatedRoute.snapshot.paramMap.get('contactId');
        this.initForm();
    }

    private initForm() {
        this.contactForm = new FormGroup(
            {
                //Main Type Fields
                organizationType: new FormControl(null, [Validators.required]),
                companyRole: new FormControl(null, [Validators.required]),
                connectedStaff: new FormControl(null),
                //Contact Information Fields
                clientName: new FormControl(''),
                contactTitle: new FormControl(''),
                firstName: new FormControl(''),
                middleName: new FormControl(''),
                lastName: new FormControl(''),
                nameSuffix: new FormControl(''),
                namePrefix: new FormControl(''),
                //Review & Memo Fields
                needsReview: new FormControl(false),
                sharedMemo: new FormControl(''),
                //Primary Address Fields
                primaryStreet1: new FormControl(''),
                primaryStreet2: new FormControl(''),
                primaryCity: new FormControl(''),
                primaryState: new FormControl(''),
                primaryZip: new FormControl(''),
                primaryCountry: new FormControl(''),
                signedEngagementLetter: new FormControl(false),
                //Phone & Email Entry Fields
                primaryPhone: new FormControl(''),
                alternatePhone: new FormControl(''),
                cellPhone: new FormControl(''),
                primaryEmail: new FormControl(''),
                secondaryEmail: new FormControl(''),
                website: new FormControl(''),
                //Memo Field
                memo: new FormControl(''),
                //Billing Address Fields
                usePrimaryAddrB: new FormControl(false),
                billingStreet1: new FormControl(''),
                billingStreet2: new FormControl(''),
                billingCity: new FormControl(''),
                billingState: new FormControl(''),
                billingZip: new FormControl(''),
                billingCountry: new FormControl(''),
                billingEmail: new FormControl(''),
                billingEmailCC: new FormControl(''),
                //Client Default Fields
                isPayAfter: new FormControl(''),
                isFlatRate: new FormControl(''),
                isScheduled: new FormControl(''),
                isNotBillable: new FormControl(''),
                fileForeign: new FormControl(this.fileForeigns[0]),
                entityStatus: new FormControl(this.entityStatuses[0]),
                //Company Contact
                companyContact: new FormControl(''),
                companyContactId: new FormControl(null),
                //Tax Information Fields
                taxId: new FormControl(''),
                accountNumber: new FormControl(''),
                send1099: new FormControl(false),
                //Referral Source Fields
                referralSource: new FormControl(''),
                referralSourceId: new FormControl(null),
                //Extra Fields
                archiveDate: new FormControl(null)
            }
        );

        if (this.currentContactId > 0) {
            this.loadFormDataOnEdit();
        } else {
            this.loadFormDataOnCreate();
        }
    }

    private loadFormDataOnCreate() {
        this.getListOfAttorneys().pipe(map((attorneys) => {
            this.attorneys = attorneys;
        }), tap((_) => {
            this.isFormLoading = false;
        })).subscribe();
    }

    private loadFormDataOnEdit() {
        forkJoin([this.getCurrentContact(), this.getListOfAttorneys()]).pipe(map(([contact, attorneys]) => {
            this.attorneys = attorneys;
            this.contactForm.patchValue({
                organizationType: this.organizationTypes.find(ot => ot.Type === contact.orgType),
                companyRole: this.companyRoles.find(cr => cr.Type === contact.companyRole),
                connectedStaff: this.attorneys.find(att => att.validValue === contact.crm),
                //Contact Information Fields
                clientName: (contact.clientName) ? contact.clientName : contact.company,
                contactTitle: contact.contactTitle,
                firstName: contact.firstName,
                middleName: contact.middleName,
                lastName: contact.lastName,
                nameSuffix: contact.nameSuffix,
                namePrefix: contact.namePrefix,
                //Review & Memo Fields
                needsReview: contact.needsReview,
                sharedMemo: contact.sharedMemo,
                //Primary Address Fields
                primaryStreet1: contact.primaryStreet1,
                primaryStreet2: contact.primaryStreet2,
                primaryCity: contact.primaryCity,
                primaryState: contact.primaryState,
                primaryZip: contact.primaryZip,
                primaryCountry: contact.primaryCountry,
                signedEngagementLetter: contact.signedEngagementLetter,
                //Phone & Email Entry Fields
                primaryPhone: contact.primaryPhone,
                alternatePhone: contact.alternatePhone,
                cellPhone: contact.cellPhone,
                primaryEmail: contact.primaryEmail,
                secondaryEmail: contact.secondaryEmail,
                website: contact.website,
                //Memo Field
                memo: contact.memo,
                //Billing Address Fields
                usePrimaryAddrB: (contact.usePrimaryAddrB === 'Y'),
                billingStreet1: contact.billingStreet1,
                billingStreet2: contact.billingStreet2,
                billingCity: contact.billingCity,
                billingState: contact.billingState,
                billingZip: contact.billingZip,
                billingCountry: contact.billingCountry,
                billingEmail: contact.additionalCcemail1,
                billingEmailCC: contact.additionalCcemail2,
                //Client Default Fields
                isPayAfter: (contact.payOptionsAdvance === 'Pay After'),
                isFlatRate: (contact.payOptionsRate === 'Flat Rate'),
                isScheduled: (contact.payOptionsSchedule === 'Scheduled'),
                isNotBillable: (contact.payOptionsBillable === 'Not Billable'),
                fileForeign: (contact.foreignFilings === null) ? this.fileForeigns[0] : this.fileForeigns.find(ot => ot.Type === contact.foreignFilings),
                entityStatus: (contact.defaultEntityStatus === null) ? this.entityStatuses[0] : this.entityStatuses.find(ot => ot.Type === contact.defaultEntityStatus),
                //Company Contact
                companyContact: contact.company,
                companyContactId: contact.companyContactId,
                //Tax Information Fields
                taxId: contact.taxIdNum,
                accountNumber: contact.accountNum,
                send1099: (contact.send1099 === 'Y'),
                //Referral Source Fields
                referralSource: contact.referralSource,
                referralSourceId: null, //TODO: Implement a referral id get (contact.ReferralSourceId),
                //Extra Fields
                archiveDate: contact.archiveDate
            });
            this.isArchived = (contact.archiveDate !== null);
            this.UsePrimaryAddrBChange({ checked: (contact.usePrimaryAddrB === 'Y') });
        }), tap((_) => {
            this.isFormLoading = false;
        })).subscribe();
    }

    private getCurrentContact(): Observable<Contact> {
        const params = { $filter: ODataFilterBuilder().eq('ContactId', this.currentContactId).toString() } as ParamObj;
        return this.contactService.get(params).pipe(map((contacts) => contacts[0]));
    }

    private getListOfAttorneys = (): Observable<Array<FormFieldListItem>> => this.lookupService.get('Staff', 'AttorneyFull').pipe(map((items) => items));

    public onCompanyContactClicked(contact: ContactSearchResult) {
        this.contactForm.patchValue({
            companyContact: `${contact.companyName}`,
            companyContactId: contact.contactID
        });
    }

    public onReferralSourceClicked(contact: ContactSearchResult) {
        this.contactForm.patchValue({
            referralSource: `${contact.firstName} ${contact.lastName}`,
            referralSourceId: contact.contactID
        });
    }

    public UsePrimaryAddrBChange(event: any) {
        if (event.checked) {
            // Disable editing of billing information as it now depends on primary address fields
            this.contactForm.controls['billingStreet1'].disable();
            this.contactForm.controls['billingStreet2'].disable();
            this.contactForm.controls['billingCity'].disable();
            this.contactForm.controls['billingState'].disable();
            this.contactForm.controls['billingZip'].disable();
            this.contactForm.controls['billingCountry'].disable();

            // Initial Patch to match Primary Address Fields
            this.contactForm.patchValue({
                billingStreet1: this.contactForm.controls['primaryStreet1'].value,
                billingStreet2: this.contactForm.controls['primaryStreet2'].value,
                billingCity: this.contactForm.controls['primaryCity'].value,
                billingState: this.contactForm.controls['primaryState'].value,
                billingZip: this.contactForm.controls['primaryZip'].value,
                billingCountry: this.contactForm.controls['primaryCountry'].value,
            });

            // Subscribe to each valueChanges functionality for primary to always match with Billing when UsePrimaryAddrB is checked. (TakeUntil as long as UsePrimaryAddrB is checked)
            this.contactForm.get('primaryStreet1').valueChanges.pipe(takeUntil(this.sameAsBillingSubject$)).subscribe(value => {
                this.contactForm.patchValue({ billingStreet1: value }, { emitEvent: false });
            });
            this.contactForm.get('primaryStreet2').valueChanges.pipe(takeUntil(this.sameAsBillingSubject$)).subscribe(value => {
                this.contactForm.patchValue({ billingStreet2: value }, { emitEvent: false });
            });
            this.contactForm.get('primaryCity').valueChanges.pipe(takeUntil(this.sameAsBillingSubject$)).subscribe(value => {
                this.contactForm.patchValue({ billingCity: value }, { emitEvent: false });
            });
            this.contactForm.get('primaryState').valueChanges.pipe(takeUntil(this.sameAsBillingSubject$)).subscribe(value => {
                this.contactForm.patchValue({ billingState: value }, { emitEvent: false });
            });
            this.contactForm.get('primaryZip').valueChanges.pipe(takeUntil(this.sameAsBillingSubject$)).subscribe(value => {
                this.contactForm.patchValue({ billingZip: value }, { emitEvent: false });
            });
            this.contactForm.get('primaryCountry').valueChanges.pipe(takeUntil(this.sameAsBillingSubject$)).subscribe(value => {
                this.contactForm.patchValue({ billingCountry: value }, { emitEvent: false });
            });

        } else {
            // When unchecking UsePrimaryAddrB re-enable editability for fields as they are no longer bound to primary address fields
            this.contactForm.controls['billingStreet1'].enable();
            this.contactForm.controls['billingStreet2'].enable();
            this.contactForm.controls['billingCity'].enable();
            this.contactForm.controls['billingState'].enable();
            this.contactForm.controls['billingZip'].enable();
            this.contactForm.controls['billingCountry'].enable();
            // Patch Default values on contact forms
            this.contactForm.patchValue({
                billingStreet1: '',
                billingStreet2: '',
                billingCity: '',
                billingState: '',
                billingZip: '',
                billingCountry: '',
            });
            // Unsubscribe from two-binding between primary and billing fields
            this.sameAsBillingSubject$.next(true);
            this.sameAsBillingSubject$.complete();
        }
    }

    public submitForm() {
        this.contactForm.markAllAsTouched();
        if (!this.contactForm.valid) {
            this.toast.error('Contact Invalid, make sure to fill out all required fields');
        } else {
            this.saveContact();
        }
    }

    private saveContact() {
        const request = { Contact: this.getCurrentContactObj() } as ContactRequest;
        if (this.currentContactId > 0) {
            this.contactService.update(request).pipe(map(() => {
                this.toast.success('Successfully updated the contact');
            })).subscribe();
        } else {
            this.contactService.post(request).pipe(map(() => {
                this.toast.success('Successfully created new contact');
                this.router.navigate(['/app/contacts']);
            })).subscribe();
        }
    }

    private getCurrentContactObj(): Contact {
        const contact = {} as Contact;
        const orgType = this.contactForm.value.organizationType.Type;
        contact.contactId = this.currentContactId;
        contact.orgType = orgType;
        contact.companyRole = this.contactForm.value.companyRole.Type;
        contact.crm = this.contactForm.value.connectedStaff.validValue;
        //Contact Information Fields
        contact.contactTitle = (orgType !== 'Organization') ? this.contactForm.value.contactTitle : null;
        contact.firstName = (orgType !== 'Organization') ? this.contactForm.value.firstName : null;
        contact.middleName = (orgType !== 'Organization') ? this.contactForm.value.middleName : null;
        contact.lastName = (orgType !== 'Organization') ? this.contactForm.value.lastName : null;
        contact.nameSuffix = (orgType !== 'Organization') ? this.contactForm.value.nameSuffix : null;
        contact.namePrefix = (orgType !== 'Organization') ? this.contactForm.value.namePrefix : null;
        //FullName
        contact.fullName = (orgType !== 'Organization') ? `${this.contactForm.value.firstName} ${this.contactForm.value.lastName}` : this.contactForm.value.companyContact;
        //Review & Memo Fields
        contact.needsReview = this.contactForm.value.needsReview;
        contact.sharedMemo = this.contactForm.value.sharedMemo;
        //Primary Address Fields
        contact.primaryStreet1 = this.contactForm.value.primaryStreet1;
        contact.primaryStreet2 = this.contactForm.value.primaryStreet2;
        contact.primaryCity = this.contactForm.value.primaryCity;
        contact.primaryState = this.contactForm.value.primaryState;
        contact.primaryZip = this.contactForm.value.primaryZip;
        contact.primaryCountry = this.contactForm.value.primaryCountry;
        contact.signedEngagementLetter = this.contactForm.value.signedEngagementLetter;
        //Phone & Email Entry Fields
        contact.primaryPhone = this.contactForm.value.primaryPhone;
        contact.alternatePhone = this.contactForm.value.alternatePhone;
        contact.cellPhone = this.contactForm.value.cellPhone;
        contact.primaryEmail = this.contactForm.value.primaryEmail;
        contact.secondaryEmail = this.contactForm.value.secondaryEmail;
        contact.website = this.contactForm.value.website;
        //Memo Field
        contact.memo = this.contactForm.value.memo;
        //Billing Address Fields
        contact.usePrimaryAddrB = (this.contactForm.value.usePrimaryAddrB) ? 'Y' : null;
        contact.billingStreet1 = this.contactForm.controls['billingStreet1'].value;
        contact.billingStreet2 = this.contactForm.controls['billingStreet2'].value;
        contact.billingCity = this.contactForm.controls['billingCity'].value;
        contact.billingState = this.contactForm.controls['billingState'].value;
        contact.billingZip = this.contactForm.controls['billingZip'].value;
        contact.billingCountry = this.contactForm.controls['billingCountry'].value;
        contact.additionalCcemail1 = this.contactForm.value.billingEmail;
        contact.additionalCcemail2 = this.contactForm.value.billingEmailCC;
        //Client Default Fields
        contact.payOptionsAdvance = (this.contactForm.value.isPayAfter) ? 'Pay After' : 'Advance';
        contact.payOptionsRate = (this.contactForm.value.isFlatRate) ? 'Flat Rate' : 'Hourly';
        contact.payOptionsSchedule = (this.contactForm.value.isScheduled) ? 'Scheduled' : 'Completion';
        contact.payOptionsBillable = (this.contactForm.value.isNotBillable) ? 'Not Billable' : 'Billable';
        contact.foreignFilings = this.contactForm.value.fileForeign.Type;
        contact.defaultEntityStatus = this.contactForm.value.entityStatus.Type;
        //Company Contact
        contact.company = this.contactForm.value.companyContact;
        contact.clientName = this.contactForm.value.companyContact;
        contact.companyContactId = this.contactForm.value.companyContactId;
        //Tax Information Fields
        contact.taxIdNum = this.contactForm.value.taxId;
        contact.accountNum = this.contactForm.value.accountNumber;
        contact.send1099 = (this.contactForm.value.send1099) ? 'Y' : null;
        //Referral Source Fields
        contact.referralSource = this.contactForm.value.referralSource;
        //Extra Fields
        contact.archiveDate = this.contactForm.value.archiveDate;
        return contact;
    }

    public cloneContact() {
        const request = { Contact: this.getCurrentContactObj() } as ContactRequest;
        this.contactService.clone(request).pipe(map((response) => {
            this.toast.success('Successfully cloned the contact, opening new cloned contact');
            this.router.navigate([`/app/contact/${response.newContactID}`]).then(() => {
                window.location.reload();
            });
        })).subscribe();
    }

    public archiveContact() {
        const request = { Contact: this.getCurrentContactObj() } as ContactRequest;
        this.contactService.archive(request).pipe(map(() => {
            this.toast.success('Successfully archived the contact');
            this.router.navigate(['/app/contacts']);
        })).subscribe();
    }

    public activateContact() {
        const request = { Contact: this.getCurrentContactObj() } as ContactRequest;
        this.contactService.activate(request).pipe(map(() => {
            this.toast.success('Successfully activated the contact');
            this.router.navigate(['/app/contacts']);
        })).subscribe();
    }

    public deleteContact() {
        const request = { Contact: this.getCurrentContactObj() } as ContactRequest;
        this.contactService.delete(request).pipe(map((contact) => {
            this.toast.success('Successfully deleted the contact');
            this.router.navigate(['/app/contacts']);
        })).subscribe();
    }

    public cancel() {
        this.router.navigate(['/app/contacts']);
    }
}

