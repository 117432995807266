import { Component, OnInit } from '@angular/core';
import { filter, map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { AuthAbstractService } from 'src/app/modules/core/services/auth/auth-abstract.service';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { eeInfo, User } from 'src/app/modules/core/models/user';
import { ExternalAuthService } from '../../services/auth/external-auth.service';
import { UserManagementService } from 'src/app/modules/admin/users/services/user-management/user-management.service';
import { UserRole } from 'src/app/modules/shared/constants';
import { EeService } from '@core.services/ee/ee.service';
import { IEe } from '@core.models/ee';

@Component({
    selector: 'app-app-layout',
    templateUrl: './app-layout.component.html',
    styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent implements OnInit {
    private _subscribedSubjects$ = new Subject<boolean>();
    public currentDate: Date = new Date();
    public user$: Observable<User | null>;
    public isAdmin: boolean = false;
    public impersonationModal: boolean = false;
    userList: User[];
    userId: string = null;
    eeUsername: string = null;
    currentEeInfo: eeInfo = null;
    selectedUser: User;
    public impersonating: boolean = false;
    public externalLogin: boolean = false;
    private readonly _destroying$ = new Subject<void>();
    public collapsedMenu = false;

    public menuItems = [
        { url: '/app/dashboard', name: 'Dashboard', icon: 'pi pi-th-large', adminOnly: false },
        { url: '/app/contacts', name: 'Contacts', icon: 'pi pi-users', adminOnly: false },
        { url: '/app/matters', name: 'Matters', icon: 'pi pi-briefcase', adminOnly: false },
        { url: '/app/timekeeping', name: 'TimeKeeping', icon: 'pi pi-stopwatch', adminOnly: false },
        { url: '/app/admin/users', name: 'User Admin', icon: 'pi pi-users', adminOnly: true },
    ];

    constructor(
        private authSvc: AuthAbstractService,
        private userSvc: UserService,
        private userMgmtService: UserManagementService,
        private externalAuthSvc: ExternalAuthService
    ) { }

    ngOnInit(): void {
        this.user$ = this.userSvc.user$;
        this.user$.pipe(filter(user => user !== null)).subscribe((user) => {
            this.userId = user.id;
            user?.roles.forEach((role) => {
                if (role === UserRole.Admin) this.isAdmin = true;
            });
        });
        this.impersonating = this.authSvc.isImpersonating();
        if (this.isAdmin) {
            this.loadUsers();
        }
        this.externalLogin = this.externalAuthSvc.isExternalAuth;
    }
    toggleMenu() {
        this.collapsedMenu = !this.collapsedMenu;
    }

    loadUsers() {
        this.userMgmtService.getUsers().pipe(
            tap((result) => { this.userList = result.data.filter(u => u.id !== this.userId); }),
        ).subscribe();
    }

    logout() {
        this.authSvc.logout(true).subscribe();
    }

    impersonateClicked() {
        this.impersonationModal = true;
    }

    clearClicked() {
        this.userId = null;
    }

    userSelected(event: any) {
        if (event.value === null) {
            return;
        }

        this.selectedUser = this.userList.find((x) => x.id === event.value.id);
    }

    cancelClicked() {
        this.impersonationModal = false;
        this.userId = null;
    }

    confirmClicked() {
        this.authSvc.impersonate(this.selectedUser.id);
        this.impersonationModal = false;
        this.userId = null;
    }

    stopImpersonatingClicked() {
        this.authSvc.stopImpersonating();
    }

    ngOnDestroy(): void {
        this._subscribedSubjects$.next(true);
        this._subscribedSubjects$.complete();
    }
}
