import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { DXODataService } from '../../services/devextreme/dx-odata.service';

export class matterType {
    Id: number;
    Type: string;
}

const ACTIVE_MATTER_ID = 0;
const ARCHIVED_MATTERS_MATTER_ID = 1;
const MAINTENANCE_ONLY_MATTER_ID = 3;
@Component({
    selector: 'app-matters',
    templateUrl: './matters.component.html',
    styleUrls: ['./matters.component.scss']
})
export class MattersComponent {
    @ViewChild('mattersGrid', { static: false }) mattersGrid: DxDataGridComponent;
    public matterTypeOptions: Array<matterType> = [
        { Id: 0, Type: 'Active Matters' },
        { Id: 1, Type: 'Archived Matters' },
        { Id: 2, Type: 'All' },
        { Id: 3, Type: 'Maintenance Only' }
    ];


    public selectedMatterTypeOption: matterType = null;
    public mattersDataSource: DataSource = null;

    constructor(private dxOdataService: DXODataService, private router: Router) {
        this.selectedMatterTypeOption = this.matterTypeOptions[0];
    }

    public ngOnInit(): void {
        this.getMatters();
    }

    public onToolbarPreparing(event: any) {
        const toolbarItems = event.toolbarOptions.items;
        toolbarItems.forEach((item: any) => {
            if (item.name === 'searchPanel') {
                item.location = 'before';
            }
        });
    }

    private getMatters() {
        this.mattersDataSource = new DataSource({
            store: this.dxOdataService.context.vwMatterListings,
            filter: ['archiveDate', '=', null],
            pageSize: 20
        });
    }

    onMatterTypeChange(event: any) {
        const matterId = event.value.Id;
        this.mattersDataSource.filter(this.getMatterTypeFilter(matterId));
        this.mattersDataSource.load();
        this.mattersGrid.instance.refresh();
    }

    getMatterTypeFilter(matterId: number): Array<any> {
        const filters: any[] = [];
        if (matterId === ACTIVE_MATTER_ID) {
            filters.push(
                ['archiveDate', '=', null]
            );
        } else if (matterId === ARCHIVED_MATTERS_MATTER_ID) {
            filters.push(
                ['!', ['archiveDate', '=', null]]
            );
        }
        else if (matterId === MAINTENANCE_ONLY_MATTER_ID) {
            filters.push(
                ['matterStatus', '=', 'maintenance Only']
            );
        } else { //ALL_MATTER_ID 2
            // Show all matters, no filters
        }
        return (filters.length <= 0) ? null : filters;
    }

    public newMatter() {
        this.router.navigate(['/app/matter/0']);
    }

    public onRowClick(event: any) {
        this.router.navigate(['/app/matter/', event.data.matterId]);
    }

}
