import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-simple-page-layout',
    templateUrl: './simple-page-layout.component.html',
    styleUrls: ['./simple-page-layout.component.scss'],
})
export class SimplePageLayoutComponent implements OnInit {
    public currentDate: Date = new Date();

    constructor() {}

    ngOnInit(): void {}
}
