import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import {
    IApiDataResponse,
    IApiResponse,
} from '../../models/api-response.interface';
import { ResetPasswordRequest } from '../../models/reset-password-request.interface';
import { IUser, User } from '../../models/user';
import { IUser2FAChangeRequest } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class UserApiService {
    private USER_API = `${environment.apiBaseURL}User`;

    constructor(private http: HttpClient) { }

    public createUser(userRequestBody: IUser2FAChangeRequest): Observable<IApiDataResponse<IUser>> {
        return this.http.post<IApiDataResponse<IUser>>(
            `${this.USER_API}`,
            {
                User: userRequestBody.user,
                Ee: userRequestBody.ee,
                EnableStandardLogin: userRequestBody.enableStandardLogin,
                Password: userRequestBody.password
            }
        );
    }

    public updateUser(userRequestBody: User): Observable<IApiDataResponse<IUser>> {
        return this.http.put<IApiDataResponse<IUser>>(
            `${this.USER_API}`,
            userRequestBody
        );
    }

    public deleteUser(id: string): Observable<IApiResponse> {
        return this.http.delete<IApiResponse>(`${this.USER_API}?id=${id}`);
    }

    public sendUserRegistrationEmail(id: string): Observable<IApiResponse> {
        return this.http.post<IApiResponse>(
            `${this.USER_API}/SendRegisterEmail?id=${id}`,
            {}
        );
    }

    public getUsers(): Observable<IApiDataResponse<IUser[]>> {
        return this.http.get<IApiDataResponse<IUser[]>>(`${this.USER_API}/List`);
    }

    public getUsersByRole(role: string): Observable<IApiDataResponse<IUser>> {
        return this.http.get<IApiDataResponse<IUser>>(
            `${this.USER_API}/ByRole?roleName=${role}`
        );
    }

    public getUserById(id: string): Observable<IApiDataResponse<IUser>> {
        return this.http.get<IApiDataResponse<IUser>>(
            `${this.USER_API}/ById?id=${id}`
        );
    }

    public getUserDetails(): Observable<IApiDataResponse<IUser>> {
        return this.http.get<IApiDataResponse<IUser>>(`${this.USER_API}/Details`);
    }

    public forceResetPassword(id: string): Observable<IApiResponse> {
        return this.http.put<IApiResponse>(
            `${this.USER_API}/ForceResetPassword?id=${id}`,
            {}
        );
    }

    public changePassword(id: string, request: { oldPassword: string, newPassword: string }): Observable<IApiResponse> {
        return this.http.put<IApiResponse>(
            `${this.USER_API}/ChangePassword?id=${id}`,
            request
        );
    }

    public forgotPassword(userName: string): Observable<IApiResponse> {
        const forgotPasswordRequestBody = { userName: userName };
        return this.http.post<IApiResponse>(`${this.USER_API}/ForgotPassword`, forgotPasswordRequestBody);
    }

    public resetPassword(resetPasswordRequestBody: ResetPasswordRequest): Observable<IApiResponse> {
        const url = `${this.USER_API}/ResetPassword`;
        return this.http.put<IApiResponse>(url, resetPasswordRequestBody);
    }

    public getProfilePicById(id: string): Observable<IApiDataResponse<string>> {
        return this.http.get<IApiDataResponse<string>>(`${this.USER_API}/GetProfilePic${id}`);
    }
}
