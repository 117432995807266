<div *ngIf="isLoading">
    <div class="loader">
        <p-progressSpinner></p-progressSpinner>
    </div>
</div>
<dx-scroll-view *ngIf="!isLoading" #scrollView id="scrollview">
    <div class="row">
        <div class="col-10">
            <h3 *ngIf="timeEntryForm?.value.entryType.Type === 'Expense'; else timeType">Expense Entry for
                {{timeEntryForm?.controls['matterTitle'].value}} - [Docket]</h3>
            <ng-template #timeType>
                <h3>Time Entry for
                    {{timeEntryForm?.controls['matterTitle'].value}} - [Docket]</h3>
            </ng-template>
        </div>
        <div class="col d-flex justify-content-end">
            <button class="btn btn-primary btn-block" (click)="onSaveClicked()" type="submit">Save</button>
        </div>
    </div>
    <form *ngIf="timeEntryForm" [formGroup]="timeEntryForm">
        <div class="row mb-2">
            <div class="col p-field">
                <label class="pb-1" for="entry-type">Entry Type</label>
                <p-dropdown id="entry-type" [options]="entryTypes" formControlName="entryType"
                    optionLabel="Type"></p-dropdown>
            </div>

            <!-- TIMER LOGIC -->
            <div *ngIf="currentMatter" class="col">
                <button
                    *ngIf="timerService.openTimer.matterTimeId !== currentMatterTimeId && !timerService.openTimer.isRunning && timerService.openTimer.id === 0 else hasRunningTimer"
                    (click)="onStartTimerClicked()" class="btn btn-primary btn-block mt-4" type="button"><i
                        class="bi bi-stopwatch-fill"></i> Start
                    Timer
                </button>
                <ng-template #hasRunningTimer>
                    <div class="mt-4">
                        <div class="timer-button-container">
                            <div class="timer-stack">
                                <span>
                                    <span class="timer">{{ timerService.openTimer.run_time | time }}</span>
                                </span>
                            </div>

                            <div class="button-stack">
                                <ng-container *ngIf="!timerService.openTimer.isRunning; else runningOpts">
                                    <p-button class="stacked-button" (click)="resumeTimer(timerService.openTimer)">
                                        Resume
                                    </p-button>
                                </ng-container>
                                <ng-template #runningOpts>
                                    <p-button *ngIf="timerService.openTimer.is_cancellable; else stopOpt"
                                        (click)="cancelTimer(timerService.openTimer)">
                                        Cancel
                                    </p-button>
                                    <ng-template #stopOpt>
                                        <p-button (click)="stopTimer(timerService.openTimer)">Stop</p-button>
                                    </ng-template>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>

        <div *ngIf="!canChangeMatter" class="row mb-2">
            <div class="col d-flex flex-column gap-1">
                <input class="w-100" type="text" pInputText formControlName="matterTitle">
            </div>
        </div>
        <div *ngIf="canChangeMatter" class="row mb-2">
            <div class="col d-flex flex-column gap-1">
                <input class="w-100" type="text" pInputText placeholder="Start Typing to Search for Matters..."
                    formControlName="matterTitle" (keyup)="onSearchChanged($event)">
            </div>
            <dx-data-grid *ngIf="mattersDataSource.length > 0" #mattersGrid [dataSource]="mattersDataSource"
                [remoteOperations]="false" [allowColumnReordering]="true" [hoverStateEnabled]="true"
                [showBorders]="true" (onRowClick)="onRowClick($event)">
                <dxi-column dataField="matterRef" caption="Reference"></dxi-column>
                <dxi-column dataField="matterTitle" caption="Title"></dxi-column>
            </dx-data-grid>
        </div>

        <div class="row mb-2">
            <div class="col-4 d-flex flex-column gap-1">
                <label for="day">Day</label>
                <dx-date-box id="day" type="date" [useMaskBehavior]="true" [inputAttr]="{ 'aria-label': 'Date' }"
                    formControlName="date" (onValueChanged)="onBillingRateParamChange()"></dx-date-box>
            </div>
        </div>

        <div class="card mb-2">
            <div class="card-body">
                <div class="row">
                    <div class="col d-flex flex-column gap-1">
                        <label for="time">Time</label>
                        <input pInputText id="time" type="number" (keyup)="onTimeChange($event)" formControlName="time"
                            placeholder="Hours">
                    </div>
                    <div class="col d-flex flex-column gap-1">
                        <label for="adjustments">Adjustments</label>
                        <input pInputText id="adjustments" type="number" (keyup)="onTimeChange($event)"
                            formControlName="adjustments" placeholder="No Charge Hours">
                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex flex-column gap-1">
                        <label for="rate">Rate</label>
                        <input pInputText id="rate" type="number" (keyup)="onTimeChange($event)" formControlName="rate"
                            placeholder="Hours">
                    </div>
                    <div class="col d-flex flex-column gap-1">
                        <label for="non-billable">Bill Hours</label>
                        <input pInputText id="non-billable" type="number" (keyup)="onTimeChange($event)"
                            formControlName="nonBillableHours" placeholder="Non Bill Hours">
                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex flex-column gap-1">
                        <label for="charge-amount">Charge Amount</label>
                        <input pInputText id="charge-amount" formControlName="chargeAmount">
                    </div>
                    <div class="col d-flex flex-column gap-1">
                        <label for="total-adjustments">Total Adjustments</label>
                        <input pInputText id="total-adjustments" formControlName="totalAdjustments">
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-2">
            <div class="col">
                <div class="d-flex flex-column gap-1">
                    <label for="description">Description</label>
                    <textarea id="description" pKeyFilter="int" rows="3" cols="30" formControlName="description"
                        pInputTextarea></textarea>
                </div>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col">
                <div>
                    <label for="ready-to-bill">Check if Matter is Ready to Bill (Final Time Entry):&nbsp;&nbsp;</label>
                    <p-checkbox formControlName="isReadyToBill" [binary]="true" inputId="ready-to-bill"></p-checkbox>
                </div>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col d-flex justify-content-center">
                <h3>Total Fee Amount: ${{totalFeeAmount| number: '1.2-2'}}</h3>
            </div>
        </div>

        <div class="row">
            <div class="col pt-2">
                <div>
                    <label for="delete">Delete Time Entry:&nbsp;&nbsp;</label>
                    <p-checkbox formControlName="delete" [binary]="true" inputId="delete"></p-checkbox>
                </div>
            </div>
            <div class="col"></div>
            <div class="col d-flex justify-content-end">
                <button class="btn btn-secondary btn-block" (click)="onCloneTimeEntryClicked()" type="button">Clone Time
                    Entry</button>
            </div>
        </div>

    </form>
</dx-scroll-view>