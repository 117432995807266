import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { IApiDataResponse, IApiResponse } from 'src/app/modules/core/models/api-response.interface';
import { Document, IDocument, IDocumentCategory } from 'src/app/modules/core/models/document';
import { DocumentApiService } from 'src/app/modules/core/services/document-api/document-api.service';
import { IProperty } from '../../models/property';
import { PropertyApiService } from '../../services/property-api/property-api.service';
import { ToastService } from '../../services/toast/toast.service';

@Injectable()
export class DocumentsByCategoryService {
    private _documents: BehaviorSubject<Document[]> = new BehaviorSubject<Document[]>([]);

    get documents$(): Observable<Document[]> {
        return this._documents.asObservable();
    }

    constructor(private api: DocumentApiService, private propertyApi: PropertyApiService, private toast: ToastService) {}


    public getPropertyCategories(propertyId: string): Observable<IDocumentCategory[]>{
        return this.api.getPropertyCategories(propertyId).pipe(
            map((response: IApiDataResponse<IDocumentCategory[]>) => {
                return response.data;
            })
        );
    }

    public getProperty(propertyId: string): Observable<IProperty> {
        return this.propertyApi.getPropertyById(propertyId).pipe(
            map((res: IApiDataResponse<IProperty>) => res.data)
        );
    }
  
    public getPropertyDocumentsByCategory(propertyId: string, categoryId: string) {
        return this.api.getPropertyDocumentsByCategory(propertyId, categoryId).pipe(
            map((response: IApiDataResponse<IDocument[]>) => {
                this._documents.next(response.data.map(r => new Document(r)));
            })
        );
    }

    public downloadDocument(documentId: string): Observable<boolean> {
        return this.api.downloadDocument(documentId);
    }

    public deleteDocument(documentId: string): Observable<boolean> {
        return this.api.deleteDocument(documentId).pipe(
            map((response: IApiResponse) => {
                this.toast.success(response.message);
                return true;
            })
        );
    }

    public saveDocument(document: Document): Observable<boolean> {
        return this.api.updateDocument(document).pipe(
            map(_ => {
                this.toast.success('Saved');
                return true;
            })
        );
    }

    public moveDocument(newCategory: IDocumentCategory, document: Document): Observable<boolean> {
        document.categoryId = newCategory.id;
        return this.api.updateDocument(document).pipe(
            map(_ => {
                this.toast.success(`Moved to ${newCategory.name}`);
                return true;
            })
        );  
    }

    public mergeDocuments(from: string, to: string): Observable<boolean> {
        return this.api.mergeDocuments(from, to).pipe(
            map((response: IApiResponse) => {
                this.toast.success(response.message);
                return true;
            })
        );
    }
}
