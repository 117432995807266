import {
    NgModule
} from '@angular/core';
import {
    DxButtonModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxFileUploaderModule,
    DxFormModule,
    DxLoadPanelModule,
    DxLookupModule,
    DxNumberBoxModule,
    DxPopupModule,
    DxProgressBarModule,
    DxRadioGroupModule,
    DxScrollViewModule,
    DxSelectBoxModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxToastModule,
    DxValidationGroupModule,
    DxValidationSummaryModule,
    DxValidatorModule
} from 'devextreme-angular';

@NgModule({
    imports: [
        DxCheckBoxModule,
        DxSelectBoxModule,
        DxNumberBoxModule,
        DxFormModule,
        DxRadioGroupModule,
        DxButtonModule,
        DxTextBoxModule,
        DxTextAreaModule,
        DxDateBoxModule,
        DxDataGridModule,
        DxLookupModule,
        DxScrollViewModule,
        DxLoadPanelModule,
        DxValidatorModule,
        DxValidationSummaryModule,
        DxValidationGroupModule,
        DxPopupModule,
        DxRadioGroupModule,
        DxFileUploaderModule,
        DxProgressBarModule,
        DxToastModule
    ],
    exports: [
        DxCheckBoxModule,
        DxSelectBoxModule,
        DxNumberBoxModule,
        DxFormModule,
        DxRadioGroupModule,
        DxButtonModule,
        DxTextBoxModule,
        DxTextAreaModule,
        DxDateBoxModule,
        DxDataGridModule,
        DxLookupModule,
        DxScrollViewModule,
        DxLoadPanelModule,
        DxValidatorModule,
        DxValidationSummaryModule,
        DxValidationGroupModule,
        DxPopupModule,
        DxRadioGroupModule,
        DxFileUploaderModule,
        DxProgressBarModule,
        DxToastModule
    ]
})
export class DxModule {}
