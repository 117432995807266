<div class="mb-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page"><i class="bi bi-stopwatch-fill"></i> Timekeeper</li>
        </ol>
    </nav>
</div>

<div class="timesheet-container">
    <div class="row mb-2">
        <div class="col">
            <div class="p-field">
                <label class="pb-1" for="users">Timekeeper:</label>
                <p-dropdown id="users" [options]="timekeepers" [(ngModel)]="currentTimekeeper"
                    placeholder="Select an Attorney..." (onChange)="onTimekeeperChange($event)"
                    optionLabel="fullName"></p-dropdown>
            </div>
        </div>
        <div class="d-md-none d-lg-block col-lg-1 col-xl-3"></div>
        <div class="col">
            <div class="pt-4">
                <app-time-entry-popup container=".timesheet-container"
                    (savedTimeEntry)="savedTimeEntry($event)"></app-time-entry-popup>
            </div>
        </div>
    </div>
    <div class="row d-flex justify-content-end mb-2 mt-2">
        <div class="d-sm-none d-lg-block col"></div>
        <div class="col-xs col-sm-8 col-md-6 col-lg-2 d-flex justify-content-end mt-1">
            <button class="btn btn-secondary btn-block w-100" (click)="changeWeek('today')">Today</button>
        </div>
        <div class="col-xs col-sm-4 col-md-2 mt-1 d-flex justify-content-end">
            <button class="btn btn-secondary btn-block custom-left" (click)="changeWeek('previous')"><i
                    class="pi pi-chevron-left"></i></button>
            <button class="btn btn-secondary btn-block custom-right" (click)="changeWeek('next')"><i
                    class="pi pi-chevron-right"></i></button>
        </div>
    </div>
    <div *ngIf="isLoading">
        <div class="loader">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
    <app-time-entry-popup #timeEntryEditComponent container=".timesheet-container" [isEdit]="true"
        (savedTimeEntry)="savedTimeEntry($event)"></app-time-entry-popup>
    <div class="week-calendar" *ngIf="!isLoading">
        <div class="days" *ngFor="let d of weekObj; let i = index">
            <header>{{ d.day }} {{ d.date }}</header>
            <section>
                <div (click)="modifyTime(weekObj[i].entries[ti])"
                    class="event {{ (t.entryType) ? t.entryType : 'Time' }}"
                    *ngFor="let t of weekObj[i].entries; let ti = index">
                    <div class="type">{{ (t.entryType) ? t.entryType : 'TIME' | uppercase }} ENTRY</div>
                    <div class="matterRef">{{ t.matterRef }}</div>
                    <div class="hours">Hours: {{ t.hoursQty }}</div>
                </div>
            </section>
            <footer>
                <span>Total: &nbsp;</span>
                <span *ngIf="weekObj[i].totalTime">{{ weekObj[i].totalTime }}</span>
            </footer>
        </div>
    </div>
</div>