import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { ParamObj, ParamQuery } from '../../models/param-query.interface';
import { map, Observable } from 'rxjs';
import { Contact, NewIdClonedContactResponse } from '../../models/contact.interface';
import { ActionOdataResult, ActionResult } from '../../models/action-result.interface';
import { environment } from 'src/environments/environment';
import { ContactRequest } from '../../models/contact-request.interface';
import { ContactSearchRequest } from '../../models/contact-search-request.interface';
import { ContactSearchResult } from '../../models/contact-search-result.interface';


@Injectable({
    providedIn: 'root'
})
export class ContactService {

    constructor(private http: HttpClient, private userService: UserService) { }
    /**
     * Returns an array of contacts based on paramQuery
     */
    public get(params?: ParamObj): Observable<Array<Contact>> {
        const paramQuery: ParamQuery = (params) ? { params: params } : null;
        return this.getContacts(paramQuery).pipe(map((result) => result.value as Array<Contact>));
    }

    private getContacts(paramQuery: ParamQuery): Observable<ActionOdataResult> {
        const httpParams = (paramQuery) ? paramQuery : {};
        return this.http.get<ActionOdataResult>(`${environment.apiBaseURL}odata/contacts`, httpParams);
    }

    /**
     * Will create a new contact and Return the newly created contact
     */
    public post(request: ContactRequest): Observable<Contact> {
        return this.postContact(request).pipe(map((result) => result.data as Contact));
    }

    private postContact(event: ContactRequest): Observable<ActionResult> {
        return this.http.post<ActionResult>(`${environment.apiBaseURL}Contact/InsertContact`, event);
    }

    /**
     * Will update an existing contact and Return the newly updated contact
     */
    public update(request: ContactRequest): Observable<Contact> {
        return this.updateContact(request).pipe(map((result) => result.data as Contact));
    }

    private updateContact(event: ContactRequest): Observable<ActionResult> {
        return this.http.post<ActionResult>(`${environment.apiBaseURL}Contact/UpdateContact`, event);
    }

    /**
     * Will clone an existing contact into a new contact and return the newly created contact
     */
    public clone(request: ContactRequest): Observable<NewIdClonedContactResponse> {
        return this.cloneContact(request).pipe(map((result) => result.data as NewIdClonedContactResponse));
    }

    private cloneContact(event: ContactRequest): Observable<ActionResult> {
        return this.http.post<ActionResult>(`${environment.apiBaseURL}Contact/CloneContact`, event);
    }

    /**
    * Will archive the existing contact
    */
    public archive(request: ContactRequest): Observable<Contact> {
        return this.archiveContact(request).pipe(map((result) => result.data as Contact));
    }

    private archiveContact(event: ContactRequest): Observable<ActionResult> {
        return this.http.post<ActionResult>(`${environment.apiBaseURL}Contact/ArchiveContact`, event);
    }

    /**
    * Will delete the existing contact
    */
    public delete(request: ContactRequest): Observable<Contact> {
        return this.deleteContact(request).pipe(map((result) => result.data as Contact));
    }

    private deleteContact(event: ContactRequest): Observable<ActionResult> {
        return this.http.post<ActionResult>(`${environment.apiBaseURL}Contact/DeleteContact`, event);
    }

    /**
    * Will acctivate the existing contact from being archived
    */
    public activate(request: ContactRequest): Observable<Contact> {
        return this.activateContact(request).pipe(map((result) => result.data as Contact));
    }

    private activateContact(event: ContactRequest): Observable<ActionResult> {
        return this.http.post<ActionResult>(`${environment.apiBaseURL}Contact/ActivateContact`, event);
    }

    /**
     * Returns an array of contact search results based on contact search request
     */
    public getContactsViaSearch(request: ContactSearchRequest): Observable<Array<ContactSearchResult>> {
        return this.contactSearch(request).pipe(map((result) => result as Array<ContactSearchResult>));
    }

    private contactSearch(request: ContactSearchRequest): Observable<Array<ContactSearchResult>> {
        return this.http.post<Array<ContactSearchResult>>(`${environment.apiBaseURL}Contact/ContactSearch`, request);
    }
}
