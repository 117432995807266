<div class="d-flex flex-column justify-content-start align-items-center mt-4">
    <div>
        <div class="d-flex flex-row">
            <a class="d-block btn btn-secondary mb-2" routerLink="/login"><i class="bi bi-arrow-left"></i> Back to
                Login</a>
        </div>
        <div class="card shadow-sm">
            <div class="card-body">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="mb-1" style="height: 120px">
                        <label for="exampleInputEmail1" class="form-label">Email address</label>
                        <div id="userNameHelp" class="form-text">
                            Please enter the email address associated with your account.
                        </div>
                        <input type="email" [ngClass]="{
                'form-control': true,
                'is-invalid':
                  form.controls['userName'].invalid &&
                  (form.controls['userName'].dirty ||
                    form.controls['userName'].touched)
              }" id="exampleInputEmail1" aria-describedby="userNameHelp" formControlName="userName">
                        <ng-container *ngIf="
                form.controls['userName'].invalid &&
                (form.controls['userName'].dirty ||
                  form.controls['userName'].touched)
              ">
                            <div class="invalid-feedback" *ngIf="form.controls['userName'].errors?.['required']">
                                Email required.
                            </div>
                            <div class="invalid-feedback" *ngIf="form.controls['userName'].errors?.['email']">
                                Invalid email address.
                            </div>
                        </ng-container>
                    </div>
                    <div class="d-flex flex-row-reverse">
                        <button type="submit" class="btn btn-primary">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>