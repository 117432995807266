abstract class TypedLocalStorageService {
    public static store_string(key: string, data: string) {
        localStorage.setItem(key, data);
    }

    public static store(key: string, data: any) {
        localStorage.setItem(key, JSON.stringify(data));
    }

    public static fetch_string(key: string): string | null {
        const obj = localStorage.getItem(key);
        return obj ? obj : null;
    }

    public static fetch<T>(key: string): T | null {
        const obj = localStorage.getItem(key);
        if (obj) {
            const parsed = this.tryParseObj(obj);
            return parsed as T;
        }

        return null;
    }

    public static fetch_array<T>(key: string): T[] | null {
        const obj = localStorage.getItem(key);

        if (obj) {
            const parsed = this.tryParseObj(obj);

            if (Array.isArray(parsed)) {
                return parsed as T[];
            }
        }

        return null;
    }

    public static remove(key: string) {
        localStorage.removeItem(key);
    }

    private static tryParseObj(obj: string): any {
        try {
            const parsedObj = JSON.parse(obj);
            return parsedObj;
        } catch {
            // parse failed
        }

        return null;
    }
}

export const typedLocalStorage = TypedLocalStorageService;