import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ActionOdataResult,
    ActionResult
} from '@core.models/action-result.interface';
import {
    ApiResponseType,
    IApiResponse
} from '@core.models/api-response.interface';
import { ParamObj, ParamQuery } from '@core.models/param-query.interface';
import { UserService } from '@core.services/user/user.service';
import { DBTimer } from '@core.models/timer.model';
import { map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

export type saveType = 'insert' | 'update';

export interface IGetTimerParams {
    eeId: number;
    matterId: number | null;
}

@Injectable()
export class TimerDbService {
    private readonly baseUrl = `${environment.apiBaseURL}odata/timers`;
    constructor(private http: HttpClient, private userService: UserService) { }

    public getTimers(params?: ParamObj): Observable<DBTimer[]> {
        const paramQuery = this.toParamsQuery(params) || {};

        return this.http
            .get<ActionOdataResult>(this.baseUrl, paramQuery)
            .pipe(map((result) => result.value as DBTimer[]));
    }

    public saveTimer(
        dbTimer: DBTimer
    ): Observable<[saveType, DBTimer | boolean, string?]> {
        if (dbTimer.id <= 0) {
            // Create timer
            return this.createTimer(dbTimer).pipe(
                map((response) => ['insert', ...response])
            );
        } else {
            // Update timer
            return this.updateTimer(dbTimer).pipe(
                map((response) => ['update', ...response])
            );
        }
    }

    public deleteTimer(timerId: number): Observable<[boolean, string?]> {
        return this.http
            .delete<[boolean, string?]>(`${this.baseUrl}/${timerId}`, {
                observe: 'response'
            })
            .pipe(
                map((response) =>
                    response.ok ? [true] : [response.ok, response.statusText]
                )
            );
    }

    private createTimer(dbTimer: DBTimer): Observable<[DBTimer, string?]> {
        return this.http
            .post<DBTimer>(this.baseUrl, dbTimer, {
                observe: 'response'
            })
            .pipe(
                map((response) => {
                    if (response.ok && response.body) {
                        return [response.body as DBTimer];
                    }

                    return [null, response.statusText];
                })
            );
    }

    private updateTimer(dbTimer: DBTimer): Observable<[boolean, string?]> {
        return this.http
            .patch<[boolean, string?]>(
                `${this.baseUrl}/${dbTimer.id}`,
                dbTimer,
                { observe: 'response' }
            )
            .pipe(
                map((response) =>
                    response.ok ? [true] : [response.ok, response.statusText]
                )
            );
    }

    private toParamsQuery(params?: ParamObj): ParamQuery {
        return params ? { params } : null;
    }
}
