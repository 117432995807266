import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { finalize, Observable, Subject, takeUntil, tap } from 'rxjs';
import { Document, IDocumentCategory } from '../../models/document';
import { DocumentsByCategoryService } from './documents-by-category.service';
import { UploadDocumentsComponent } from '../../components/upload-documents/upload-documents.component';
import { DocumentDetailsComponent } from '../../components/document-details/document-details.component';
import { Location } from '@angular/common';
import { Property } from '../../models/property';
import { UserService } from '../../services/user/user.service';
import { UserRole } from 'src/app/modules/shared/constants';
import { EventService } from '../../services/event/event.service';

@Component({
    selector: 'app-documents-by-category',
    templateUrl: './documents-by-category.component.html',
    styleUrls: ['./documents-by-category.component.scss'],
    providers: [DocumentsByCategoryService],
})
export class DocumentsByCategoryComponent implements OnInit, OnDestroy {
    public documents: Observable<Document[]>;
    public isAdmin: boolean = false;
    public allowStatusChange: boolean = false;
    public propertyId: string;
    public categoryId: string;
    public currentDocumentId: string;
    public showConfirmModal: boolean;
    public property: Property;
    public currentCategory: IDocumentCategory;
    public categories: IDocumentCategory[];
    public displayModal: boolean;
    public items: MenuItem[];
    public home: MenuItem;
    public loading: boolean = false;
    public draggedDocumentId: string = null;
    public draggedRowPreview = document.createElement('span');
    public UserRole = UserRole;
    public onDestroy$: Subject<boolean> = new Subject();

    constructor(
    private documentSvc: DocumentsByCategoryService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private location: Location,
    private confirmationService: ConfirmationService,
    public userService: UserService,
    private eventService: EventService,
    ) {
    }


    ngOnInit(): void {
        this.home = {label:' Properties', icon: 'pi pi-home', routerLink: '/app/properties'};
        this.activatedRoute.params.subscribe((params: Params) => {
            this.propertyId = params['propertyId'];
            this.categoryId = params['categoryId'];

            this.documentSvc.getPropertyCategories(this.propertyId).subscribe(categories => {
                this.categories = categories;
                this.currentCategory = categories.find(t => t.id === this.categoryId);
            });

            this.documentSvc.getProperty(this.propertyId).subscribe(property => {
                this.property = property;
                this.allowStatusChange = this.isAdmin || this.property.accountManager.userName === this.userService.getUserName();
                this.items = [
                    {label: this.property.name, command: (event) => { this.location.back(); }},
                    {label: ''},
                ];
            });

        });
        this.documents = this.documentSvc.documents$;
        this.isAdmin = this.userService.getRole() === UserRole.Admin;

        this.reloadDocuments();
        this.eventService.uploadsCompleted$.pipe(takeUntil(this.onDestroy$)).subscribe(event => {
            if (event?.categoryIds.includes(this.categoryId)) {
                this.reloadDocuments();
            }
        });

    }

    deleteDocument(id: string) {
        this.currentDocumentId = id;
        this.showConfirmModal = true;
    }

    showUploadDialog(files: File[] = []) {
        const dialog = this.dialogService.open(UploadDocumentsComponent, {
            header: 'Upload Documents',
            width: '70%',
            data: {
                propertyId: this.propertyId,
                incomingFiles: files,
                categoryId: this.categoryId
            }
        });
    }

    showDocumentDetails(document: Document) {
        const dialog = this.dialogService.open(DocumentDetailsComponent, {
            header: 'Document Details',
            width: '70%',
            data: {
                document: document,
                categoryId: this.categoryId,
                allowStatusChange: this.allowStatusChange,
                propertyId: this.propertyId
            },
            style: {'overflow-y': 'auto'}
        });
        dialog.onClose.subscribe(_ => {
            this.reloadDocuments();
        });
    }

    onConfirmDelete() {
        this.showConfirmModal = false;
        this.documentSvc.deleteDocument(this.currentDocumentId).pipe(
            tap(() => this.reloadDocuments()),
        ).subscribe();
    }

    onCancelDelete() {
        this.showConfirmModal = false;
    }

    reloadDocuments(): void {
        this.loading = true;
        this.documentSvc.getPropertyDocumentsByCategory(this.propertyId, this.categoryId).pipe(
            finalize(() => { this.loading = false; })
        ).subscribe();
    }

    updateStatus(newStatus: string, document: Document) {
        document.status = newStatus;
        this.documentSvc.saveDocument(document).subscribe();
    }

    updateCategory(newCategory: IDocumentCategory, document: Document) {
        this.documentSvc.moveDocument(newCategory, document).subscribe(_ => this.reloadDocuments());
    }

    search(event: any, dt: Table) {
        dt.filterGlobal(event.target.value, 'contains');
    }

    uploadInit(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (event.dataTransfer.files.length > 0) {
            const fileListAsArray = Array.from(event.dataTransfer.files);
            this.showUploadDialog(fileListAsArray);
        }
    }

    mergeFiles(event: DragEvent, toDocId: string) {
        if (this.draggedDocumentId && this.draggedDocumentId !== toDocId) {
            this.confirmMerge(event, toDocId);
        }
    }

    confirmMerge(event: Event, toId: string) {
        const fromId = this.draggedDocumentId; // save before it gets cleared out by DragEnd event
        this.confirmationService.confirm({
            key: 'createVersion',
            target: event.target,
            message: 'Are you sure that you want to create a new version?',
            accept: () => {
                this.documentSvc.mergeDocuments(fromId, toId).pipe(
                    tap(() => this.reloadDocuments()),
                ).subscribe();
            },
        });
    }

    dragFunction(event: any) {
        event.preventDefault();
        event.stopPropagation();
    }

    dragStart(event: DragEvent, documentId: string, documentName: string) {
        this.draggedRowPreview.textContent = `Drop ${documentName} as version`;
        this.draggedRowPreview.style.padding = '15px';
        this.draggedRowPreview.style.color = 'white';
        this.draggedRowPreview.style.backgroundColor = '#1c38eb';
        this.draggedRowPreview.style.position = 'absolute';
        this.draggedRowPreview.style.top = '-1000px';
        this.draggedRowPreview.style.fontWeight = 'bold';
        document.body.appendChild(this.draggedRowPreview);
        event.dataTransfer.setDragImage(this.draggedRowPreview, 0, 0);

        this.draggedDocumentId = documentId;
    }

    onDragEnd() {
        this.draggedDocumentId = null;
        document.body.removeChild(this.draggedRowPreview);
    }

    downloadDocument(documentId: string) {
        this.documentSvc.downloadDocument(documentId).subscribe();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }

}
