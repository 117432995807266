import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BillingRateRequest, MatterTime, TimeEntry } from '@core.models/timekeeper.interface';
import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { DateTime } = require('luxon');

const NEW_WEEK_NUMBER = 52;

@Injectable()
export class MatterTimeService {

    // BehaviorSubject to emit matterTime info when the popup component is opened
    private _timeEntryFormModuleDataSubj$ = new BehaviorSubject<boolean>(false);
    private currentMatterTime: MatterTime = null;

    constructor(private http: HttpClient) { }

    /**
     * Returns the current matterTime within an Observable by matterTimeId
     */
    public latestMatterTime(matterTimeId: number): Observable<MatterTime> {
        if (this.currentMatterTime?.timeId === matterTimeId) {
            return of(this.currentMatterTime);
        } else {
            return this.getMatterTimeByMatterTimeId(matterTimeId);
        }
    }

    /**
     * Returns a matterTime by matterTimeId from the Api
     */
    private getMatterTimeByMatterTimeId(matterTimeId: number): Observable<MatterTime> {
        return this.http.get<MatterTime>(
            `${environment.apiBaseURL}MatterTime/${matterTimeId}`
        );
    }

    /**
     * Returns a matterTime by matterTimeId from the Api
    */
    public setMatterTimeByNewMatterTime(matterTime: MatterTime) {
        // console.log('testing 2: ', matterTime);
        this.currentMatterTime = matterTime;
    }


    /**
     * Subscribed on the time-entry-form to get data on popup opened.
     * Observable to subscribe to events for getting emitted popup opened.
     */
    public loadTimeEntryFormModuleData$ = this._timeEntryFormModuleDataSubj$.asObservable();
    /**
     * Load Data for time-entry-form.
     * Method to trigger and emit matterTime info and load the data on the internal component.
     */
    triggerLoadModuleData() {
        this._timeEntryFormModuleDataSubj$.next(true);
    }

    /**
     * Returns a time entry by timeId
     */
    public GetMatterTimeById(timeId: number): Observable<MatterTime> {
        return this.http.get<MatterTime>(
            `${environment.apiBaseURL}MatterTime/${timeId}`
        );
    }

    /**
     * Returns an array of MatterTimes based on a date range
     */
    public getTimeByDateEE(eeId: number, startDate: string, endDate: string): Observable<Array<TimeEntry>> {
        return this.getTimeEntries(eeId, startDate, endDate).pipe(map((result) => result));
    }

    private getTimeEntries(eeId: number, startDate: string, endDate: string): Observable<Array<TimeEntry>> {
        return this.http.post<Array<TimeEntry>>(`${environment.apiBaseURL}TimeSheet/GetTimeEntries`, { eeId, startDate, endDate, type: 'Time' });
    }

    /**
     * Returns an array of MatterTimes based on matterId
     */
    public getMatterTimes(matterId: number): Observable<Array<MatterTime>> {
        return this.http.get<Array<MatterTime>>(
            `${environment.apiBaseURL}MatterTime/matter/${matterId}`
        );
    }

    /**
     * Will create a new matterTime or edit an existing matterTime based on timeId and Return the newly created time Id or the existing timeId
     */
    public save(matterTime: MatterTime): Observable<number> {
        return this.http.post<number>(`${environment.apiBaseURL}MatterTime/Save`, matterTime).pipe(map((timeId) => timeId));
    }

    /**
     * Will clone an existing matterTime return the newly created matterTime
     */
    public clone(matterTime: MatterTime): Observable<number> {
        return this.http.post<number>(`${environment.apiBaseURL}MatterTime/Clone`, matterTime).pipe(map((timeId) => timeId));
    }

    /**
     * Will delete a matterTime and return true if it succeeds
     */
    public delete(timeId: number): Observable<boolean> {
        return this.http.delete<boolean>(`${environment.apiBaseURL}MatterTime/${timeId}`).pipe(map((success) => success));
    }

    buildLuxonDate(day: number, week: number, year: number, format: string) {
        const newWeek = (week === 0) ? NEW_WEEK_NUMBER : week;
        const newYear = (week === 0) ? year - 1 : year;
        return DateTime.fromObject({
            weekYear: newYear,
            weekNumber: newWeek,
            weekday: day,
        }).toFormat(format);
    }

    getTimeEntrySum(timeList: Array<TimeEntry>) {
        let total = 0.0;
        timeList.forEach(time => {
            total = total + time.hoursQty;
        });
        return total;
    }

    public getBillingRate(request: BillingRateRequest): Observable<number> {
        return this.http.post(`${environment.apiBaseURL}TimeSheet/GetBillingRateByEEInfo`, request).pipe(map((billingRateInfo: any) => billingRateInfo.billingRate));
    }
}

