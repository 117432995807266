<div class="row">
    <div class="d-none d-sm-block col-sm">
        <button class="btn btn-primary" type="button" (click)="popupVisible = true"><i class="pi pi-user"></i>
            {{buttonText}}</button>
    </div>

    <div class="d-xs-block d-sm-none col-xs">
        <div *ngIf="!mobileSectionVisible">
            <button class="btn btn-primary" type="button" (click)="mobileSectionVisible = true"><i
                    class="pi pi-user"></i>
                {{buttonText}}</button>
        </div>
        <div *ngIf="mobileSectionVisible">
            <div class="d-flex flex-row-reverse">
                <button (click)="mobileSectionVisible = false" class="btn btn-secondary" type="button"><i
                        class="pi pi-times"></i></button>
            </div>
            <hr>

            <app-contact-search (clickedContact)="returnedContact($event)"></app-contact-search>
        </div>
    </div>
</div>

<dx-popup [showTitle]="true" [title]="buttonText" [dragEnabled]="true" [hideOnOutsideClick]="false" [showCloseButton]="true"
    [container]="container" [(visible)]="popupVisible">
    <div *dxTemplate="let data of 'content'">
        <app-contact-search (clickedContact)="returnedContact($event)" [searchType]="searchType"></app-contact-search>
    </div>
</dx-popup>