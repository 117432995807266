/* eslint no-fallthrough: 0 */ // disable fallthrough rule for better switch performance when running the pipe
import { Pipe, PipeTransform } from '@angular/core';
import { MINUTE, SECOND, SECOND_LIMIT } from '@core.models/timer.model';

// convenience times
const PAD_LEN = 2;
const HOUR_LIMIT = 24;
const DAY_LIMIT = 365;
const HOUR = MINUTE * SECOND_LIMIT;
const DAY = HOUR * HOUR_LIMIT;
const YEAR = DAY * DAY_LIMIT;

@Pipe({
    name: 'time'
})
export class TimePipe implements PipeTransform {
    /*
    Takes JS date timestamp (new Date().getTime(), Date.now(), etc.)
    returns string: "[[[yy:]dd:]hh:]mm:ss"
  */
    transform(value: number, ...args: any[]) {
        let years = '',
            days = '',
            hours = '',
            minutes = '',
            seconds = '';

        switch (true) {
            // Case fallthrough to allow all sub-sections to generate on single case
            case value > YEAR:
                years = this.pad((value / YEAR) | 0);
            case value > DAY:
                days = this.pad((value / DAY) % DAY_LIMIT | 0);
            case value > HOUR:
                hours = this.pad((value / HOUR) % HOUR_LIMIT | 0);
            default:
                minutes = this.pad((value / MINUTE) % SECOND_LIMIT | 0);
                seconds = this.pad((value / SECOND) % SECOND_LIMIT | 0, false);
                break;
        }

        return `${years}${days}${hours}${minutes}${seconds}`;
    }

    private pad(n: number, separator: boolean = true): string {
        return (`00${n}`).slice(-PAD_LEN) + (separator ? ':' : '');
    }
}