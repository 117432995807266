export const SECOND = 1000;
export const SECOND_LIMIT = 60;
export const MINUTE = SECOND * SECOND_LIMIT;
export const _2MINUTES = MINUTE + MINUTE;
export const HOUR_PRECISION = 100;

export function timestamp(): number {
    return Date.now();
}

export function millisToHours(
    millis: number,
    precision: number = HOUR_PRECISION
): number {
    const hours = (millis || 0) / SECOND / SECOND_LIMIT / SECOND_LIMIT;
    const rounding = Math.ceil(hours * precision);

    return rounding / precision;
}

// basic time tracking interface
export interface Timer {
    id: number;
    eeId: number;
    matterId: number;
    matterRef: string;
    matterTimeId: number;
    accruedTime: number;
    startTime: number | null;
    endTime: number | null;
    entryDesc: string;
    isRunning: boolean;
    saved: boolean; // whether timer was successfully saved to db on last save
    pinned: boolean;
}

export interface DBTimer {
    id: number;
    eeId: number;
    matterId: number;
    matterRef: string;
    matterTimeId: number;
    accruedTime: number;
    startTime: string; // ISO-8601 date
    entryDesc: string;
    isRunning: boolean;
}

export class BaseTimer implements Timer {
    public id: number;
    public eeId: number;
    public matterId: number;
    public matterRef: string;
    public matterTimeId: number;
    public accruedTime: number = 0;
    public startTime: number | null = null;
    public endTime: number | null = null;
    public entryDesc: string;
    public isRunning: boolean = false;
    public saved: boolean;
    public pinned: boolean = false;

    constructor(itimer?: Timer) {
        if (itimer) {
            this.id = itimer.id;
            this.eeId = itimer.eeId;
            this.matterId = itimer.matterId;
            this.matterRef = itimer.matterRef;
            this.matterTimeId = itimer.matterTimeId;
            this.accruedTime = itimer.accruedTime;
            this.startTime = itimer.startTime;
            this.endTime = itimer.endTime;
            this.entryDesc = itimer.entryDesc;
            this.isRunning = itimer.isRunning;
            this.saved = itimer.saved;
            this.pinned = itimer.pinned;
        }
    }

    public toDBTimer(): DBTimer {
        let startTime = null;
        if (this.isRunning && this.startTime) {
            startTime = new Date(this.startTime).toISOString();
        }

        return {
            id: this.id,
            eeId: this.eeId,
            matterId: this.matterId,
            matterRef: this.matterRef,
            matterTimeId: this.matterTimeId,
            accruedTime: this.accruedTime,
            startTime: startTime,
            entryDesc: this.entryDesc,
            isRunning: this.isRunning
        } as DBTimer;
    }

    public static fromDBTimer(timer_data: DBTimer): BaseTimer {
        let start_time = null;
        if (timer_data.startTime) {
            start_time = new Date(timer_data.startTime).getTime();
        }

        const base = new BaseTimer();
        base.id = timer_data.id;
        base.eeId = timer_data.eeId;
        base.matterId = timer_data.matterId;
        base.matterRef = timer_data.matterRef;
        base.matterTimeId = timer_data.matterTimeId;
        base.accruedTime = timer_data.accruedTime;
        base.startTime = start_time;
        base.endTime = null;
        base.entryDesc = timer_data.entryDesc;
        base.isRunning = timer_data.isRunning;

        return base;
    }
}

// main timer class which includes run time field
export class TimerWithRun extends BaseTimer {
    private _run_time: number = 0;
    public get run_time(): number {
        return this.isRunning ? this._run_time : this.accruedTime;
    }
    public set run_time(time: number) {
        this._run_time = time;
    }

    public get is_cancellable(): boolean {
        return TimerWithRun.is_cancellable(this);
    }

    public static is_cancellable(timer: Timer): boolean {
        if (
            timer.isRunning &&
            timestamp() - timer.startTime + timer.accruedTime < _2MINUTES
        ) {
            return true;
        }

        return false;
    }

    constructor(timer?: Timer) {
        super();
        // existing timer object
        if (timer) {
            this.id = timer.id;
            this.eeId = timer.eeId;
            this.matterId = timer.matterId;
            this.matterRef = timer.matterRef;
            this.matterTimeId = timer.matterTimeId;
            this.accruedTime = timer.accruedTime;
            this.startTime = timer.startTime;
            this.endTime = timer.endTime;
            this.entryDesc = timer.entryDesc;
            this.isRunning = timer.isRunning;
            this.pinned = timer.pinned;
        } else {
            this.id = 0; // set by db on first save
            this.matterTimeId = 0;
            this.matterId = 0;
            this.eeId = 0;
        }

        if (this.isRunning) {
            const now = timestamp();
            this._run_time = this.accruedTime + (now - this.startTime);
        } else {
            this._run_time = this.accruedTime;
        }
    }

    public static override fromDBTimer(timer_data: DBTimer): TimerWithRun {
        const base_timer = BaseTimer.fromDBTimer(timer_data);
        const timers = new TimerWithRun(base_timer);
        return timers;
    }
}
