<div class="mb-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page"><i class="bi bi-house-door-fill"></i> Contacts</li>
        </ol>
    </nav>
</div>
<div class="mb-2">
    <div class="row">
        <div class="col">
            <p-dropdown [options]="contactTypeOptions" (onChange)="onContactTypeChange($event)"
                [(ngModel)]="selectedContactTypeOption" optionLabel="Type"></p-dropdown>
        </div>
        <div class="col"></div>
        <div class="col d-flex justify-content-end">
            <button class="btn btn-primary" (click)="newContact()">+ Add New Contact</button>
        </div>
    </div>
</div>
<div>
    <dx-data-grid #contactsGrid [dataSource]="contactsDataSource" [remoteOperations]="true"
        [allowColumnReordering]="true" [hoverStateEnabled]="true" [showBorders]="true" (onRowClick)="onRowClick($event)"
        (onToolbarPreparing)="onToolbarPreparing($event)">
        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search Contacts"></dxo-search-panel>
        <dxo-paging [pageSize]="20"> </dxo-paging>
        <dxo-pager [visible]="true" [allowedPageSizes]="[20, 50]" displayMode="full" [showPageSizeSelector]="true"
            [showInfo]="true" [showNavigationButtons]="true">
        </dxo-pager>
        <dxi-column dataField="company" caption="Company"></dxi-column>
        <dxi-column dataField="firstName" caption="First Name"></dxi-column>
        <dxi-column dataField="lastName" caption="Last Name"></dxi-column>
        <dxi-column dataField="companyRole" caption="Company Role"></dxi-column>
        <dxi-column dataField="primaryPhone" caption="Phone Number"></dxi-column>
        <dxi-column dataField="primaryEmail" caption="Email"></dxi-column>
    </dx-data-grid>
</div>