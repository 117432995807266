<header>
    <nav class="navbar shadow-sm justify-content-center">
        <div class="d-flex flex-row justify-content-center">
            <img class="image" src="assets/images/advance1.png">
        </div>
    </nav>
</header>

<main class="container d-flex justify-content-center" style="align-items: start;">
    <router-outlet></router-outlet>
</main>