import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, tap } from 'rxjs';
import { UserRole } from 'src/app/modules/shared/constants';
import { eeInfo, IUser, User } from '../../models/user';
import { HttpClient } from '@angular/common/http';
import {
    ApiResponseType,
    IApiDataResponse,
    IApiResponse
} from '@core.models/api-response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private _baseUrl = `${environment.apiBaseURL}EE/`;
    private _user: BehaviorSubject<User | null> =
        new BehaviorSubject<User | null>(null);

    get userSnapshot(): User | null {
        return this._user.value;
    }

    get user$(): Observable<User | null> {
        return this._user.asObservable();
    }

    constructor(private http: HttpClient) { }

    public setUser(user: IUser | null) {
        this._user.next(user ? new User(user) : null);
        this.getEEInfo(this._user.value);
    }

    public getRole(): UserRole | null {
        if (this.userSnapshot?.roles.some((r) => r === UserRole.Admin)) {
            return UserRole.Admin;
        } else if (
            this.userSnapshot?.roles.some((r) => r === UserRole.InternalUser)
        ) {
            return UserRole.InternalUser;
        } else if (
            this.userSnapshot?.roles.some((r) => r === UserRole.ExternalUser)
        ) {
            return UserRole.ExternalUser;
        } else return null;
    }

    public getUserName(): string {
        return this.userSnapshot?.userName ?? '';
    }

    public getEEInfo(user: User): Observable<IApiResponse> {
        if (user) {
            const url = `${this._baseUrl}${user.userName}`;

            const setEeInfo$ = this.http.get<IApiDataResponse<eeInfo>>(url).pipe(
                tap((response) => {
                    if (response?.type === ApiResponseType.Success) {
                        const info = response.data;

                        if (info) {
                            user.eeInfo = info;
                        }
                    }
                }),
                map((response) => {
                    const { message, type } = response;
                    return { message, type } as IApiResponse;
                })
            );
            setEeInfo$.subscribe();

            return setEeInfo$;
        }

        return of({
            type: ApiResponseType.Error,
            message: 'User cannot be null'
        });
    }
}
