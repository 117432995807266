import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin, map, Observable } from 'rxjs';
import ODataFilterBuilder from 'odata-filter-builder';

import {
    AIPRole,
    Ee,
    IUser2FAChangeRequest,
    ParamObj,
    User
} from 'src/app/modules/core/models';
import { EeRole, USER_EDIT_BILL_RATE_MAX, USER_EDIT_EE_REF_MAX, USER_EDIT_NAME_MAX, USER_EDIT_SORT_ORDER_MAX, UserRole } from '../../shared/constants';
import { passwordMatch } from '../../core/validators/confirm.validator';
import { AIPRoleService } from '@core.services/aip-role/aip-role.service';
import { EeService } from '@core.services/ee/ee.service';

export function requiredIfValidator(predicate: BooleanFn) {
    return ((formControl: FormControl) => {
        if (!formControl.parent) return null;
        if (predicate()) return Validators.required(formControl);
        return null;
    });
}

export interface BooleanFn {
    (): boolean;
}

@Component({
    selector: 'app-user-editor',
    templateUrl: './user-editor.component.html',
    styleUrls: ['./user-editor.component.scss'],
})
export class UserEditorComponent implements OnInit {
    @Input() user: User | null;
    @Input() adminForm: boolean = false;
    @Input() create: boolean = false;
    @Output() submitUser: EventEmitter<IUser2FAChangeRequest> = new EventEmitter<IUser2FAChangeRequest>();

    public eeUser: Ee | null;
    public isFormLoading: boolean = true;
    public form: FormGroup;
    roles: Array<AIPRole>;
    public enableStandardLogin: boolean = false;
    // 1+ of each: digit, lowercase char, uppercase char, symbol; no whitespace; min length: 6
    private passwordPattern = '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)(?!.*\\s).{6,}$';

    constructor(
        private fb: FormBuilder,
        private aipRoleService: AIPRoleService,
        private eeService: EeService,
    ) { }

    ngOnInit(): void {
        if (this.user) {
            this.initEdit();
        }
        else {
            this.initCreate();
        }
    }

    private initCreate() {
        this.getAIPRoles().subscribe({
            next: (roles) => {
                this.roles = roles;
                this.initForm();
                this.form.patchValue({ ...new User(), ...new Ee() });
                this.isFormLoading = false;
            },
            error: (e) => {
                this.isFormLoading = false;
            }
        });
    }

    private initEdit() {
        forkJoin({
            roles: this.getAIPRoles(),
            ee: this.getEEUser(),
        }).subscribe({
            next: ({ roles, ee }) => {
                this.roles = roles;
                this.initForm();
                this.form.patchValue({ 
                    ...this.user,
                    ...ee,
                    userAdminEnabled: (this.user.roles.findIndex(r => r === UserRole.Admin) > -1)
                    //enableStandardLogin: only self can edit password (Change Password link)
                });
                this.isFormLoading = false;
            },
            error: (e) => {
                this.isFormLoading = false;
            }
        });
    }

    private getAIPRoles(): Observable<Array<AIPRole>> {
        return this.aipRoleService.get();
    }

    private getEEUser() {
        const params = {
            $filter: ODataFilterBuilder().eq('email', this.user.userName).toString()
        } as ParamObj;
        return this.eeService.get(params).pipe(map((ee) => ee[0]));
    }

    private initForm() {
        this.form = this.fb.group({
            id: new FormControl(null),
            profilePic: new FormControl(null),
            eeRef: new FormControl<string|null>(
                null,
                [Validators.required, Validators.maxLength(USER_EDIT_EE_REF_MAX), Validators.pattern('[a-zA-Z]*')]
            ),
            firstName: new FormControl<string|null>(null, [Validators.required, Validators.maxLength(USER_EDIT_NAME_MAX)]),
            middleName: new FormControl<string|null>(null, [Validators.maxLength(USER_EDIT_NAME_MAX)]),
            lastName: new FormControl<string|null>(null, [Validators.required, Validators.maxLength(USER_EDIT_NAME_MAX)]),
            userName: new FormControl<string|null>(
                { value: null, disabled: !this.adminForm || !this.create },
                [Validators.required, Validators.email, Validators.maxLength(USER_EDIT_NAME_MAX)],
            ),
            phoneNumber: [
                null, 
                [requiredIfValidator(() => this.form.controls['twoFactorEnabled'].value === true
                        && this.form.controls['twoFactorType'].value === 'text')]
            ],
            userAdminEnabled: new FormControl<boolean|null>(false),
            eeRoleId: new FormControl<number|null>(null),
            lastPasswordReset: new FormControl<Date|null>(null),
            twoFactorEnabled: new FormControl<boolean|null>(false),
            twoFactorType: new FormControl<string|null>(null),
            twoFactorConfirmed: new FormControl<boolean|null>(false),
            notificationsEnabled: new FormControl<boolean|null>(false),
            password: new FormControl<string|null>( 
                { value: null, disabled: !this.adminForm || !this.create || !this.enableStandardLogin },
                [Validators.required, Validators.pattern(this.passwordPattern)]
            ),
            confirmedPassword: new FormControl<string|null>(
                { value: null, disabled: !this.adminForm || !this.create || !this.enableStandardLogin },
                [Validators.required]
            ),
            billingRate: new FormControl<number|null>(null,
                [Validators.required, Validators.min(0), Validators.max(USER_EDIT_BILL_RATE_MAX)]
            ),
            sortOrder: new FormControl<number|null>(null,
                [Validators.required, Validators.min(0), Validators.max(USER_EDIT_SORT_ORDER_MAX)]
            )
        },
        {
            validator: passwordMatch,
        }
        );
    }

    public onRoleSelect(roleId: number) {
        this.form.patchValue({ eeRoleId: roleId });
    }

    public toggleEnableStandardLogin(): void {
        this.enableStandardLogin = !this.enableStandardLogin;
        if (this.enableStandardLogin) {
            this.form.controls['password'].enable();
            this.form.controls['confirmedPassword'].enable();
        } else {
            this.form.controls['password'].reset();
            this.form.controls['password'].disable();
            this.form.controls['confirmedPassword'].reset();
            this.form.controls['confirmedPassword'].disable();
        }
    }

    public changeVisibility(): void {
        const x = (document.getElementById('passwordInput') as HTMLInputElement).type;
        if (x === 'password')
            (document.getElementById('passwordInput') as HTMLInputElement).type =
                'text';
        else if (x === 'text')
            (document.getElementById('passwordInput') as HTMLInputElement).type =
                'password';
        const y = (
            document.getElementById('confirmPasswordInput') as HTMLInputElement
        ).type;
        if (y === 'password')
            (
                document.getElementById('confirmPasswordInput') as HTMLInputElement
            ).type = 'text';
        else if (y === 'text')
            (
                document.getElementById('confirmPasswordInput') as HTMLInputElement
            ).type = 'password';
    }

    /**
     * Select and format Identity and EE data from form values.
     * Submit create user request.
     * @returns 
     */
    public onSubmitUser(): void {
        this.form.markAllAsTouched();
        if (!this.form.valid)
            return;

        const formValues = this.form.getRawValue();
        const currentUser = new User({
            ...formValues,
            roles: formValues.userAdminEnabled ? [UserRole.Admin] : null,
        });
        const currentEe = new Ee({
            ...formValues,
            email: formValues.userName,
            fullName: [formValues.firstName, formValues.middleName, formValues.lastName].join(' '),
        });

        const userChangeRequest: IUser2FAChangeRequest = {
            user: currentUser,
            ee: currentEe,
            check2FA: (!this.user?.twoFactorEnabled && currentUser.twoFactorEnabled),
            enableStandardLogin: this.adminForm && this.create && this.enableStandardLogin,
            password: this.adminForm && this.create && this.enableStandardLogin ? formValues.password : null
        };
        this.submitUser.emit(userChangeRequest);
    }
}
