<div class="d-flex flex-row justify-content-center">
    <img class="image" src="assets/images/AIP_Logo_Letters_Large.png">
</div>
<div class="d-flex flex-row justify-content-center align-items-start">
    <div *ngIf="!twoFactor">
        <div id="loginCard" class="card shadow-lg">
            <div class="card-header">Login</div>
            <div class="card-body">
                <div *ngIf="loginProviders.length > 0">
                    <ng-container *ngFor="let provider of loginProviders; index as i">
                        <div class="row mb-2" style="max-width: 400px; margin: 0">
                            <ng-container [ngSwitch]="provider.name">
                                <div *ngSwitchCase="'Google'" id="googleButton" style="padding: 0"></div>
                                <button *ngSwitchDefault pButton (click)="externalLogin(provider.name)"
                                    class="microsoft-button" [loading]="externalAuthLoading" type="button"
                                    [label]="provider.label" [icon]="provider.icon" iconPos="left"></button>
                            </ng-container>
                        </div>
                        <p-divider *ngIf="loginProviders.length > i + 1" align="center">OR</p-divider>
                    </ng-container>
                </div>
                <p-accordion>
                    <p-accordionTab header="Sign in with Username & Password">
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="d-flex flex-row align-items-bottom">
                                <label for="usernameInput" class="col-form-label flex-grow-1">Username
                                    <input type="email" [ngClass]="{
                                            'form-control': true,
                                            'is-invalid':
                                                form.controls['username']
                                                    .invalid &&
                                                (form.controls['username']
                                                    .dirty ||
                                                    form.controls['username']
                                                        .touched)
                                        }" id="usernameInput" aria-describedby="usernameHelp"
                                        formControlName="username">
                                    <ng-container *ngIf="
                                            form.controls['username'].invalid &&
                                            (form.controls['username'].dirty ||
                                                form.controls['username']
                                                    .touched)
                                        ">
                                        <div class="invalid-feedback"
                                            *ngIf="form.controls['username'].errors?.['required']">
                                            Username required.
                                        </div>
                                    </ng-container></label>
                            </div>
                            <div class="d-flex flex-row align-items-bottom">
                                <label for="passwordInput" class="col-form-label flex-grow-1">Password
                                    <input type="password" [ngClass]="{
                                            'form-control': true,
                                            'is-invalid':
                                                form.controls['password']
                                                    .invalid &&
                                                (form.controls['password']
                                                    .dirty ||
                                                    form.controls['password']
                                                        .touched)
                                        }" id="passwordInput" formControlName="password">
                                    <ng-container *ngIf="
                                            form.controls['password'].invalid &&
                                            (form.controls['password'].dirty ||
                                                form.controls['password']
                                                    .touched)
                                        ">
                                        <div class="invalid-feedback"
                                            *ngIf="form.controls['password'].errors?.['required']">
                                            Password required.
                                        </div>
                                    </ng-container>
                                </label>
                            </div>
                            <div class="d-flex flex-row align-items-bottom">
                                <input type="checkbox" (click)="changeVisibility()">
                                &nbsp;Show password
                            </div>
                            <div class="d-flex flex-row justify-content-center mt-3">
                                <button type="submit" class="btn btn-primary" [disabled]="submitting">
                                    <ng-container *ngIf="submitting; else defaultContent">
                                        <div class="spinner-border spinner-border-sm" role="status"></div>
                                        Loading...
                                    </ng-container>

                                    <ng-template #defaultContent>Login</ng-template>
                                </button>
                            </div>
                        </form>
                        <div class="d-flex flex-row justify-content-center align-items-bottom mt-3">
                            <p>
                                <a routerLink="/forgot-password" id="pass">Forgot Password?</a>
                            </p>
                        </div>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
    </div>
    <div *ngIf="twoFactor">
        <div class="card shadow-sm">
            <div class="card-header">Two-factor Authentication</div>
            <div class="card-body">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="d-flex flex-row align-items-bottom" style="height: 120px">
                        <label for="codeInput" class="col-form-label flex-grow-1">
                            Enter Code
                            <div class="form-text">
                                Please enter the 6-digit code that was sent to
                                your
                                {{
                                twoFactorType == 'email'
                                ? 'email'
                                : 'phone'
                                }}.
                            </div>
                            <input type="text" [ngClass]="{
                                    'form-control': true,
                                    'is-invalid':
                                        form.controls['code'].invalid &&
                                        (form.controls['code'].dirty ||
                                            form.controls['code'].touched)
                                }" id="codeInput" aria-describedby="codeHelp" formControlName="code">

                            <ng-container *ngIf="
                                    form.controls['code'].invalid &&
                                    (form.controls['code'].dirty ||
                                        form.controls['code'].touched)
                                ">
                                <div class="invalid-feedback" *ngIf="form.controls['code'].errors?.['required']">
                                    Please enter the 6-digit code.
                                </div>
                            </ng-container>
                        </label>
                    </div>
                    <div class="d-flex flex-row-reverse mt-2">
                        <button type="submit" class="btn btn-primary" [disabled]="submitting">
                            <ng-container *ngIf="submitting; else defaultContent">
                                <div class="spinner-border spinner-border-sm" role="status"></div>
                                Verifying...
                            </ng-container>

                            <ng-template #defaultContent>Verify</ng-template>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>