import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Contact } from '../../models/contact.interface';
import { ContactService } from '../../services/contact/contact.service';
import { BehaviorSubject, debounceTime, distinctUntilChanged, map, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { ContactSearchRequest } from '../../models/contact-search-request.interface';
import { ContactSearchResult } from '../../models/contact-search-result.interface';
import { DxDataGridComponent } from 'devextreme-angular';

const DEBOUNCE_TIME_MILISECONDS = 600;
const ENTER_KEY_NUMBER = 13;
@Component({
    selector: 'app-contact-search',
    templateUrl: './contact-search.component.html',
    styleUrls: ['./contact-search.component.scss']
})
export class ContactSearchComponent implements OnInit {
    @ViewChild('contactsGrid', { static: false }) contactsGrid: DxDataGridComponent;
    public contactsDataSource: Array<ContactSearchResult> = [];

    public searchRequested$ = new BehaviorSubject<string>(null);
    public onDestroy$: Subject<boolean> = new Subject();
    public searchText: string = null;

    @Input() searchType = 'company';
    @Output() clickedContact = new EventEmitter<ContactSearchResult>();

    constructor(private contactService: ContactService) {}

    public ngOnInit(): void {
        this.initSearchObservable();
    }

    public onToolbarPreparing(event: any) {
        const toolbarItems = event.toolbarOptions.items;
        toolbarItems.forEach((item: any) => {
            if (item.name === 'searchPanel') {
                item.location = 'before';
            }
        });
    }

    private contactSearch() {
        const req = this.filterContacts();
        req.searchText = (this.searchText) ? this.searchText : '';
        if (req.searchText !== '') {
            this.contactService.getContactsViaSearch(req).pipe(map((contacts) => {
                this.contactsDataSource = contacts;
                this.contactsGrid.instance.refresh(true);
            })).subscribe();
        } else {
            this.clearSearch();
        }
    }

    public clearSearch() {
        this.searchText = '';
        this.contactsDataSource = [];
    }

    public initSearchObservable() {
        this.searchRequested$.pipe(
            takeUntil(this.onDestroy$),
            distinctUntilChanged(),
            debounceTime(DEBOUNCE_TIME_MILISECONDS),
            switchMap(() => of(this.contactSearch()))).subscribe();
    }

    public onSearchChanged($event: any) {
        // short fuse for searching immediately on enter
        if ($event.keyCode === ENTER_KEY_NUMBER) {
            this.contactSearch();
        } else {
            this.requestSearch();
        }
    }

    private requestSearch() {
        this.searchRequested$.next(this.searchText);
    }

    private filterContacts(): ContactSearchRequest {
        const req = {} as ContactSearchRequest;
        if (this.searchType === 'company') {
            req.records = 'Client';
            return req;
        } else {
            return req;
        }
    }

    public onRowClick(event: any) {
        this.clickedContact.emit(event.data as ContactSearchResult);
    }

    public ngOnDestroy() {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }
}