import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { AuthApiService } from '../auth-api/auth-api.service';
import { UserApiService } from '../user-api/user-api.service';
import { LoginRequest } from '../../models/login-request.interface';
import { AuthAbstractService } from './auth-abstract.service';
import { TokenService } from '../token/token.service';
import { UserService } from '../user/user.service';
import { ResetPasswordRequest } from '../../models/reset-password-request.interface';
import { CreatePasswordRequest } from '../../models/create-password-request';
import {
    IApiDataResponse,
    IApiResponse
} from '../../models/api-response.interface';
import {
    HttpErrorResponse,
    HttpResponse,
    HttpResponseBase
} from '@angular/common/http';
import { IUser } from '../../models/user';
import { MsalService } from '@azure/msal-angular';
import { ToastService } from '../toast/toast.service';
import { Router } from '@angular/router';
import {
    HTTP_FOUND,
    HTTP_MFA_REQUIRED,
    HTTP_OK,
    HTTP_UNAUTHORIZED
} from '../../../shared/constants';
import { TimerService } from '@core.services/timekeeping/timer.service';

export enum LoginResponseStatus {
    Success = HTTP_OK,
    Requires2FA = HTTP_MFA_REQUIRED,
    PasswordExpired = HTTP_FOUND,
    Error = HTTP_UNAUTHORIZED
}

export interface ILoginResponse {
    status: LoginResponseStatus;
    info: any; // Response info in a more human readable form from API.
}

@Injectable()
export class AuthService extends AuthAbstractService {
    constructor(
        private router: Router,
        private tokenSvc: TokenService,
        private userSvc: UserService,
        private authApi: AuthApiService,
        private userApi: UserApiService,
        private microsoftAuthSvc: MsalService,
        private toast: ToastService,
        private timerService: TimerService
    ) {
        super();
    }

    login(loginRequestObject: LoginRequest): Observable<ILoginResponse> {
        let request = loginRequestObject;
        if (request.code === null) {
            request = {
                username: loginRequestObject.username,
                password: loginRequestObject.password
            };
        }
        return this.authApi.login(request).pipe(
            map((response: HttpResponseBase) => {
                const r = response as HttpResponse<IApiDataResponse<string>>;

                // Two Factor Authentication Required
                if (response.status === LoginResponseStatus.Requires2FA)
                    return { status: response.status, info: r.body };

                // Authentication successful
                this.tokenSvc.storeToken(r.body?.data);
                return { status: LoginResponseStatus.Success, info: r.body };
            }),
            catchError((errorResponse: HttpResponseBase) => {
                const e = errorResponse as HttpErrorResponse;
                return of({ status: errorResponse.status, info: e.error });
            })
        );
    }

    logout(showToast: boolean = false): Observable<boolean> {
        return of(true).pipe(
            tap(() => {
                this.timerService.flushTimers();
                this.userSvc.setUser(null);
                this.tokenSvc.clearAuthLocalStorage();
                this.router.navigateByUrl('/login');
                if (showToast) this.toast.success('Signed Out');
                this.microsoftAuthSvc.logoutRedirect({
                    onRedirectNavigate: (url) => {
                        return false;
                    }
                });
            }),
            catchError(() => of(false))
        );
    }

    forgotPassword(userName: string): Observable<boolean> {
        return this.userApi.forgotPassword(userName).pipe(
            map((response: IApiResponse) => {
                this.toast.success(response.message);
                return true;
            })
        );
    }

    resetPassword(resetPasswordRqt: ResetPasswordRequest): Observable<boolean> {
        return this.userApi.resetPassword(resetPasswordRqt).pipe(
            map((response: IApiResponse) => {
                this.toast.success(response.message);
                return true;
            })
        );
    }

    register(createPasswordRqt: CreatePasswordRequest): Observable<boolean> {
        return this.authApi.register(createPasswordRqt).pipe(
            map((response: IApiResponse) => {
                this.toast.success(response.message);
                return true;
            })
        );
    }

    authenticated(): boolean {
        return this.tokenSvc.validToken();
    }

    isImpersonating(): boolean {
        return this.tokenSvc.isImpersonating();
    }

    impersonate(id: string): void {
        this.authApi.impersonate(id).subscribe((result) => {
            this.tokenSvc.impersonate(result.data);
            location.reload();
        });
    }

    stopImpersonating(): void {
        this.tokenSvc.stopImpersonating();
        location.reload();
    }

    checkAuthForRoute(): Observable<boolean> {
        if (!this.authenticated()) return of(false);
        if (this.userSvc.userSnapshot) return of(true);
        return this.userApi.getUserDetails().pipe(
            map((response: IApiDataResponse<IUser>) => {
                this.userSvc.setUser(response.data);
                return true;
            }),
            catchError(() => of(false))
        );
    }
}
