<div *ngIf="isFormLoading">
  <div class="loader">
      <p-progressSpinner></p-progressSpinner>
  </div>
</div>

<div *ngIf="!isFormLoading" class="form-container">

  <div class="d-flex flex-row justify-content-center align-item-center">
    <div class="card shadow-sm" style="width: 90%">

      <div class="card-header">
        <ng-content select="[title]"></ng-content>
      </div>

      <div class="card-body">
        <div class="d-flex flex-column justify-content-start align-items-center">
          
          <form [formGroup]="form" style="width: 100%" (ngSubmit)="onSubmitUser()">

            <div class="d-flex flex-row align-items-bottom">
              <label for="eeRef" class="col-form-label flex-grow-1">
                EE Ref:
                <input type="text" id="eeRef" [ngClass]="{
                    'form-control': true,
                    'is-invalid':
                      form.controls['eeRef'].invalid
                      &&
                      (form.controls['eeRef'].dirty || form.controls['eeRef'].touched)
                  }" formControlName="eeRef">
                <ng-container *ngIf="
                    form.controls['eeRef'].invalid
                    &&
                    (form.controls['eeRef'].dirty || form.controls['eeRef'].touched)
                  ">
                  <div class="invalid-feedback" *ngIf="form.controls['eeRef'].errors?.['required']">
                    Please enter an EE Ref.
                  </div>
                  <div class="invalid-feedback" *ngIf="form.controls['eeRef'].errors?.['maxlength']">
                    Please enter up to 50 characters.
                  </div>
                  <div class="invalid-feedback" *ngIf="form.controls['eeRef'].errors?.['pattern']">
                    Please enter only letters.
                  </div>
                </ng-container>
              </label>
            </div>

            <div class="d-flex flex-row align-items-bottom">
              <label for="firstName" class="col-form-label flex-grow-1">
                First Name:
                <input type="text" id="firstName" [ngClass]="{
                    'form-control': true,
                    'is-invalid':
                      form.controls['firstName'].invalid
                      &&
                      (form.controls['firstName'].dirty || form.controls['firstName'].touched)
                  }" formControlName="firstName">
                <ng-container *ngIf="
                    form.controls['firstName'].invalid
                    &&
                    (form.controls['firstName'].dirty || form.controls['firstName'].touched)
                  ">
                  <div class="invalid-feedback" *ngIf="form.controls['firstName'].errors?.['required']">
                    Please enter a first name.
                  </div>
                  <div class="invalid-feedback" *ngIf="form.controls['firstName'].errors?.['maxlength']">
                    Please enter up to 30 characters.
                  </div>
                </ng-container>
              </label>
            </div>

            <div class="d-flex flex-row align-items-bottom">
              <label for="middleName" class="col-form-label flex-grow-1">
                Middle Name:
                <input type="text" id="middleName" [ngClass]="{
                    'form-control': true,
                    'is-invalid':
                      form.controls['middleName'].invalid
                      &&
                      (form.controls['middleName'].dirty || form.controls['middleName'].touched)
                  }" formControlName="middleName">
                <ng-container *ngIf="
                    form.controls['middleName'].invalid
                    &&
                    (form.controls['middleName'].dirty || form.controls['middleName'].touched)
                  ">
                  <div class="invalid-feedback" *ngIf="form.controls['middleName'].errors?.['maxlength']">
                    Please enter up to 30 characters.
                  </div>
                </ng-container>
              </label>
            </div>

            <div class="d-flex flex-row align-items-bottom">
              <label for="lastName" class="col-form-label flex-grow-1">
                Last Name:
                <input type="text" id="lastName" [ngClass]="{
                    'form-control': true,
                    'is-invalid':
                      form.controls['lastName'].invalid
                      &&
                      (form.controls['lastName'].dirty || form.controls['lastName'].touched)
                  }" formControlName="lastName">
                <ng-container *ngIf="
                    form.controls['lastName'].invalid
                    &&
                    (form.controls['lastName'].dirty || form.controls['lastName'].touched)
                  ">
                  <div class="invalid-feedback" *ngIf="form.controls['lastName'].errors?.['required']">
                    Please enter a last name.
                  </div>
                  <div class="invalid-feedback" *ngIf="form.controls['lastName'].errors?.['maxlength']">
                    Please enter up to 30 characters.
                  </div>
                </ng-container>
              </label>
            </div>

            <div class="d-flex flex-row align-items-bottom">
              <label for="userName" class="col-form-label flex-grow-1">
                Email:
                <input type="email" id="userName" formControlName="userName" [ngClass]="{
                    'form-control': true,
                    'is-invalid':
                      form.controls['userName'].invalid
                      &&
                      (form.controls['userName'].dirty || form.controls['userName'].touched)
                  }">
                <ng-container *ngIf="
                    form.controls['userName'].invalid
                    &&
                    (form.controls['userName'].dirty || form.controls['userName'].touched)
                  ">
                  <div class="invalid-feedback" *ngIf="form.controls['userName'].errors?.['required']">
                    Please enter an email.
                  </div>
                  <div class="invalid-feedback" *ngIf="form.controls['userName'].errors?.['email']">
                    Please enter a valid email address.
                  </div>
                  <div class="invalid-feedback" *ngIf="form.controls['userName'].errors?.['maxlength']">
                    Please enter up to 30 characters.
                  </div>
                </ng-container>
              </label>
            </div>

            <div class="d-flex flex-row align-items-start">
              <label for="role" class="col-form-label flex-grow-1" style="padding-bottom: 0;">
                Roles:
              </label>
            </div>
            <div class="row">
              <div class="d-flex flex-wrap align-items-center" style="padding-top: 0.375rem; padding-bottom: 0.375rem">
                <div class="form-check form-check-inline" *ngFor="let role of roles">
                  <input id="{{ role.roleDescription }}Checkbox" class="form-check-input" type="radio"
                    [checked]="form.controls['eeRoleId']?.value == role.roleId"
                    (click)="onRoleSelect(role.roleId)">
                  <label class="form-check-label">
                    {{ role.roleDescription }}
                  </label>
                </div>
              </div>
            </div>

            <div class="d-flex flex-row align-items-bottom">
              <label for="billingRate" class="col-form-label flex-grow-1">
                Billing Rate:
                <input type="number" id="billingRate" formControlName="billingRate" [ngClass]="{
                    'form-control': true,
                    'is-invalid':
                      form.controls['billingRate'].invalid
                      &&
                      (form.controls['billingRate'].dirty || form.controls['billingRate'].touched)
                  }">
                <ng-container *ngIf="
                    form.controls['billingRate'].invalid
                    &&
                    (form.controls['billingRate'].dirty || form.controls['billingRate'].touched)
                  ">
                  <div class="invalid-feedback" *ngIf="form.controls['billingRate'].errors?.['required']">
                    Please enter a billing rate.
                  </div>
                  <div class="invalid-feedback" *ngIf="form.controls['billingRate'].errors?.['min'] || form.controls['billingRate'].errors?.['max']">
                    Please enter a value between 0 - 9999.99.
                  </div>
                </ng-container>
              </label>
            </div>

            <div class="d-flex flex-row align-items-bottom">
              <label for="sortOrder" class="col-form-label flex-grow-1">
                Sort Order:
                <input type="number" id="sortOrder" formControlName="sortOrder" [ngClass]="{
                    'form-control': true,
                    'is-invalid':
                      form.controls['sortOrder'].invalid
                      &&
                      (form.controls['sortOrder'].dirty || form.controls['sortOrder'].touched)
                  }">
                <ng-container *ngIf="
                    form.controls['sortOrder'].invalid
                    &&
                    (form.controls['sortOrder'].dirty || form.controls['sortOrder'].touched)
                  ">
                  <div class="invalid-feedback" *ngIf="form.controls['sortOrder'].errors?.['required']">
                    Please enter a sort order.
                  </div>
                  <div class="invalid-feedback" *ngIf="form.controls['sortOrder'].errors?.['min'] || form.controls['sortOrder'].errors?.['max']">
                    Please enter a value between 0 - 9999.
                  </div>
                </ng-container>
              </label>
            </div>

            <div *ngIf="adminForm && create"
              class="d-flex flex-row align-items-bottom">
              <label for="enableStandardLogin" class="col-form-label flex-grow-1">
                Standard Login:
                <div class="form-check form-switch" style="padding-top: 0.375rem; padding-bottom: 0.375rem">
                  <input class="form-check-input" type="checkbox" role="switch" id="enableStandardLogin"
                    [checked]="enableStandardLogin" (click)="toggleEnableStandardLogin()">
                  <div class="d-none d-sm-block">
                    <label class="form-check-label" for="enableStandardLogin">
                      {{enableStandardLogin ? 'Enabled' : 'Disabled'}}
                    </label>
                  </div>
                </div>
              </label>
            </div>
            <ng-container *ngIf="adminForm && create && enableStandardLogin">
              <div class="mb-1">
                <label for="passwordInput" class="form-label">
                  Password:
                </label>
                <input type="password" [ngClass]="{
                    'form-control': true,
                    'is-invalid':
                      form.controls['password'].invalid &&
                      (form.controls['password'].dirty || form.controls['password'].touched)
                  }" id="passwordInput" aria-describedby="passwordHelp" formControlName="password">
                <ng-container *ngIf="
                    form.controls['password'].invalid &&
                    (form.controls['password'].dirty || form.controls['password'].touched)
                  ">
                  <div class="invalid-feedback" *ngIf="form.controls['password'].errors?.['required']">
                    Password required.
                  </div>
                  <div class="invalid-feedback" *ngIf="form.controls['password'].errors?.['pattern']">
                    Password should contain an uppercase letter, lowercase letter, number,
                    symbol and be at least 6-character long.
                  </div>
                </ng-container>
              </div>
              <div class="mb-3">
                <label for="confirmPasswordInput" class="form-label">
                  Confirm Password:
                </label>
                <input type="password" [ngClass]="{
                    'form-control': true,
                    'is-invalid':
                      form.errors?.['passwordMatch'] ||
                      (form.controls['confirmedPassword'].invalid &&
                      (form.controls['confirmedPassword'].dirty ||
                        form.controls['confirmedPassword'].touched))
                  }" id="confirmPasswordInput" aria-describedby="confirmPasswordHelp"
                  formControlName="confirmedPassword">
                <div class="invalid-feedback" *ngIf="form.controls['confirmedPassword'].errors?.['required']">
                  Password confirmation required.
                </div>
                <div class="invalid-feedback" *ngIf="form.errors?.['passwordMatch']">
                  Passwords do not match.
                </div>
              </div>
              <div class="mb-3">
                <input type="checkbox" (click)="changeVisibility()">
                &nbsp;Show password
              </div>
            </ng-container>
            
            <div class="row my-2">
              <label class="">User Admin:</label>
            </div>
            <div class="d-flex">
              <div class="col form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="userAdminEnabled"
                  formControlName="userAdminEnabled">
                <div class="d-none d-sm-block">
                  <ng-container *ngIf="form.controls['userAdminEnabled'].value">
                    <label class="form-check-label" for="userAdminEnabled">Enabled</label>
                  </ng-container>
                  <ng-container *ngIf="!form.controls['userAdminEnabled'].value">
                    <label class="form-check-label" for="userAdminEnabled">Disabled</label>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="d-flex flex-row-reverse mt-2">
              <ng-content select="[submitButton]"></ng-content>
              <ng-content select="[cancelButton]"></ng-content>
            </div>

          </form>

        </div>
      </div>

    </div>
  </div>

</div>