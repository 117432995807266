import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Constants, DocumentStatus } from 'src/app/modules/shared/constants';
import { IDocumentCategory, IFileUpload } from '../../models/document';

const FILE_LIMIT = 10;
@Component({
    selector: 'app-file-selector',
    templateUrl: './file-selector.component.html',
})
export class FileSelectorComponent implements OnInit {
    @Input() incomingFiles: File[];
    @Input() defaultCategoryId: string;
    @Input() categories: IDocumentCategory[];
    @Output() onSelectionChange: EventEmitter<IFileUpload[]> = new EventEmitter<IFileUpload[]>();
    selectedList: IFileUpload[] = [];
    maxFileSize = Constants.maxFileSize;
    fileLimit = FILE_LIMIT;
    statusOptions = Constants.documentStatuses;

    constructor(
    ) { }

    ngOnInit(): void {
        this.selectFiles(this.incomingFiles);
    }

    onRemoveFile(index: number) {
        this.selectedList.splice(index, 1);
        this.emitSelectionChange();
    }

    selectFiles(files: File[]) {
        for (let i = 0; i < files.length && i<this.fileLimit; i++) {
            const file = files[i];
            const selectedFile: IFileUpload = {
                file: file,
                categoryId: this.categories[0].id,
                comment: '',
                status: DocumentStatus.NotReviewed,
                name: file.name,
                hasSensitiveInfo: false
            };
            if (file.size < this.maxFileSize) {
                if (this.defaultCategoryId) {
                    selectedFile.categoryId = this.categories.find(t => t.id === this.defaultCategoryId)?.id;
                }
                this.selectedList.push(selectedFile);
            }
        }
        this.emitSelectionChange();
    }

    emitSelectionChange() {
        if (this.selectedList.length === 0) {
            this.onSelectionChange.emit([]);
        } else {
            const listClone = this.selectedList.map((item): IFileUpload => ({...item}));
            this.onSelectionChange.emit(listClone);
        }
    }


    clearSelectedFiles() {
        this.selectedList = [];
        this.emitSelectionChange();
    }
}
