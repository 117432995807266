<div *ngIf="!isEdit && showCreateButton" class="row">
    <div class="col">
        <button class="col btn btn-primary btn-block w-100" type="button" (click)="newTimeEntryInit()"><i
                class="pi pi-stopwatch"></i>
            {{buttonText}}</button>
    </div>
</div>
<dx-popup [showTitle]="true" [title]="'Time Entry'" [dragEnabled]="true" [hideOnOutsideClick]="false"
    [showCloseButton]="false" [titleTemplate]="'title'" [height]="'90vh'" [container]="container"
    [(visible)]="timeEntryPopupVisible">
    <div *dxTemplate="let data of 'title'">
        <div class="row">
            <div class="col">
                <h3>Time Entry</h3>
            </div>
            <div class="col d-flex justify-content-end">
                <p-toast></p-toast>
                <p-confirmPopup></p-confirmPopup>
                <button class="btn btn-outline-secondary" style="border: none" (click)="closePopup($event)"><i
                        class="pi pi-times"></i></button>
            </div>
        </div>
    </div>
    <div *dxTemplate="let data of 'content'">
        <app-time-entry-form #timeEntryFormComp (savedMatterTime)="emitMatterTimeId($event)"
            [currentMatterTimeId]="currentMatterTimeId" [currentMatterId]="currentMatterId"
            [canChangeMatter]="canChangeMatter" [isEdit]="isEdit"></app-time-entry-form>
    </div>
</dx-popup>