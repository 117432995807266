import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, filter, map, Observable, of, Subject, tap } from 'rxjs';
import { AuthApiService } from '../auth-api/auth-api.service';
import { TokenService } from '../token/token.service';
import {
    IApiDataResponse,
} from '../../models/api-response.interface';
import {
    HttpResponse,
    HttpResponseBase,
} from '@angular/common/http';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';


export type AuthProviderName = 'AzureAD' | 'Google';

export interface ExternalAuthProvider {
  name: AuthProviderName;
  label: string;
  icon: string;
}

export enum ExternalAuthEvent {
  LOADING,
  LOGGED_IN,
  ERROR,
  LOGGED_OUT,
  CANCELLED
}

@Injectable()
export class ExternalAuthService {
    public externalAuthProviders$: BehaviorSubject<ExternalAuthProvider[] | null> = new BehaviorSubject<ExternalAuthProvider[] | null>(null);
    public externalAuthSubject$: Subject<ExternalAuthEvent> = new Subject<ExternalAuthEvent>();

    public get isExternalAuth(): boolean {
        return this.tokenSvc.isExternalAuth();
    }

    constructor(
    private tokenSvc: TokenService,
    private authApi: AuthApiService,
    private microsoftAuthSvc: MsalService,
    private microsoftBroadcastSvc: MsalBroadcastService
    ) {
        this.getExternalAuthProviders().subscribe(_ => {
            this.subscribeToExternalAuthEvents();
        });
    }

    public login(provider: AuthProviderName) : void {
        if (provider === 'AzureAD') {
            this.microsoftAuthSvc.loginRedirect();
        }
    }

    public makeExternalLoginRequest(externalToken: string, provider: AuthProviderName): void {
        this.authApi.externalLogin(externalToken, provider).pipe(
            map((response: HttpResponseBase) => {
                const r = response as HttpResponse<IApiDataResponse<string>>;
                this.handleExternalAuthSuccess(r.body?.data);
            }),
        ).subscribe();
    }

    private handleExternalAuthSuccess(token: string) {
        this.tokenSvc.storeToken(token);
        this.tokenSvc.setExternalAuth();
        this.externalAuthSubject$.next(ExternalAuthEvent.LOGGED_IN);
    }

    private getExternalAuthProviders() {
        const providers: ExternalAuthProvider[] = [
            {
                name: 'AzureAD',
                label: 'Sign in with Microsoft',
                icon: 'pi pi-microsoft'
            },
            {
                name: 'Google',
                label: 'Login with Google',
                icon: 'pi pi-google'
            }
        ];

        return this.authApi.getExternalLoginProviders().pipe(
            map((response: IApiDataResponse<string[]>) => {
                this.externalAuthProviders$.next(providers.filter(p => response.data.find(d => d === p.name)));
            })
        );
    }

    private subscribeToExternalAuthEvents() {
        if (this.externalAuthProviders$.getValue().find(p => p.name === 'AzureAD')) {
            this.microsoftBroadcastSvc.msalSubject$
                .pipe(
                    filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
                )
                .subscribe((result: EventMessage) => {
                    this.makeExternalLoginRequest((result.payload as AuthenticationResult).idToken, 'AzureAD');
                });

            this.microsoftBroadcastSvc.msalSubject$
                .pipe(
                    filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
                )
                .subscribe((result: EventMessage) => {
                    this.makeExternalLoginRequest((result.payload as AuthenticationResult).idToken, 'AzureAD');
                });
        }
    }

    public logout(): Observable<boolean> {
        return of(true).pipe(
            tap(() => {
                this.microsoftAuthSvc.logoutRedirect({
                    onRedirectNavigate: (url) => {
                        return false;
                    }
                });
            }),
            catchError(() => of(false))
        );
    }
}
