import { User } from './user';

export interface IProperty {
  id: string;
  name: string;
  users: User[];
  favorite: boolean;
  accountManager?: User;
  status: string;
}
export class Property implements IProperty {
    id: string;
    name: string;
    users: User[];
    favorite: boolean;
    accountManager?: User;
    status: string;

    constructor(init?: Partial<IProperty>) {
        if (init?.id) this.id = init.id;
        this.name = init?.name ?? '';
        this.favorite = init?.favorite ?? false;
        this.users = init?.users ?? [];
        this.accountManager = init?.accountManager ?? null;
        this.status = init?.status ?? null;
    }
}
