import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '@core.services/user/user.service';
import { ParamObj, ParamQuery } from '@core.models/param-query.interface';
import { map, Observable, of } from 'rxjs';
import {
    ActionOdataResult,
    ActionResult
} from '@core.models/action-result.interface';
import { environment } from 'src/environments/environment';
import {
    MatterAddRequest,
    MatterRequest
} from '@core.models/matter-request.interface';
import {
    Matter,
    NewIdClonedMatterResponse
} from '@core.models/matter.interface';
import { MatterResponsibleAttorney } from '../../models/matter-responsible-attorney.interface';

@Injectable({
    providedIn: 'root'
})
export class MatterService {
    constructor(private http: HttpClient, private userService: UserService) {}
    /**
     * Returns an array of matters based on paramQuery
     */
    public get(params?: ParamObj): Observable<Array<Matter>> {
        const paramQuery: ParamQuery = params ? { params: params } : null;
        return this.getMatters(paramQuery).pipe(
            map((result) => result.value as Array<Matter>)
        );
    }

    private getMatters(paramQuery: ParamQuery): Observable<ActionOdataResult> {
        const httpParams = paramQuery ? paramQuery : {};
        return this.http.get<ActionOdataResult>(
            `${environment.apiBaseURL}odata/matters`,
            httpParams
        );
    }

    /**
     * Returns an origination attorney of matters based on matterId
     */
    public getOriginAttorneyByMatterId(matterId: number): Observable<number> {
        return this.http.get<number>(
            `${environment.apiBaseURL}matter/getoriginattorneybymatterid?matterId=${matterId}`
        );
    }

    /**
     * Returns an array of MatterResponsibles based on matterId
     */
    public getMatterResponsibles(
        matterId: number
    ): Observable<Array<MatterResponsibleAttorney>> {
        return this.getRespAttnyByMatterID(matterId).pipe(
            map((result) => result)
        );
    }

    private getRespAttnyByMatterID(
        matterId: number
    ): Observable<Array<MatterResponsibleAttorney>> {
        return this.http.get<Array<MatterResponsibleAttorney>>(
            `${environment.apiBaseURL}matter/GetRespAttnyByMatterID?matterId=${matterId}`
        );
    }

    /**
     * Will create a new matter and Return the newly created matter
     */
    public post(request: MatterAddRequest): Observable<number> {
        return this.postMatter(request).pipe(
            map((result: any) => result.newMatterID)
        );
    }

    private postMatter(event: MatterAddRequest): Observable<ActionResult> {
        return this.http.post<ActionResult>(
            `${environment.apiBaseURL}Matter/InsertMatter`,
            event
        );
    }

    /**
     * Will update an existing matter and Return the newly updated matter
     */
    public update(request: MatterRequest): Observable<Matter> {
        return this.updateMatter(request).pipe(
            map((result) => result.data as Matter)
        );
    }

    private updateMatter(req: MatterRequest): Observable<ActionResult> {
        return this.http.post<ActionResult>(
            `${environment.apiBaseURL}Matter/UpdateMatter`,
            req
        );
    }

    /**
     * Will clone an existing matter into a new matter and return the newly created MatterId
     */
    public clone(
        request: MatterRequest
    ): Observable<NewIdClonedMatterResponse> {
        return this.cloneMatter(request).pipe(
            map((result) => result.data as NewIdClonedMatterResponse)
        );
    }

    private cloneMatter(matter: MatterRequest): Observable<ActionResult> {
        return this.http.post<ActionResult>(
            `${environment.apiBaseURL}Matter/CloneMatter`,
            matter
        );
    }
}
