import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, ɵNgSelectMultipleOption } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import ODataFilterBuilder from 'odata-filter-builder';
import { MenuItem } from 'primeng/api';
import { ParamObj } from '../../models/param-query.interface';
import { filter, forkJoin, map, Observable, of, Subject, takeUntil, tap } from 'rxjs';
import { MatterService } from '../../services/matter/matter.service';
import { Contact } from '../../models/contact.interface';
import { FormFieldLookupService } from '../../services/lookup/form-field-lookup.service';
import { FormFieldListItem } from '../../models/form-field-list-item.interface';
import { ToastService } from '../../services/toast/toast.service';
import { Matter } from '../../models/matter.interface';
import { MatterAddRequest } from '../../models/matter-request.interface';
import { IEe } from '../../models/ee';
import { EeService } from '../../services/ee/ee.service';
import { ContactService } from '../../services/contact/contact.service';
import { MatterResponsibleChangeRequest } from '../../models/matter-responsible-attorney.interface';
import { ContactSearchResult } from '../../models/contact-search-result.interface';
import { MatterTime, TimeEntry } from '@core.models/timekeeper.interface';
import { DxDataGridComponent } from 'devextreme-angular';
import { TimeEntryPopupComponent } from '../../components/time-entry-popup/time-entry-popup.component';
import { MatterTimeService } from '@core.services/timekeeping/matter-time.service';
import { UserService } from '@core.services/user/user.service';
import { eeInfo } from '@core.models/user';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { DateTime } = require('luxon');

const MATTERS_TAB_MENU_MEMOS_INDEX_ID = 1;
const MATTERS_TAB_MENU_TIME_INDEX_ID = 2;
@Component({
    selector: 'app-matters-details',
    templateUrl: './matters-details.component.html',
    styleUrls: ['./matters-details.component.scss']
})
export class MattersDetailsComponent implements OnInit {
    @ViewChild('matterTimesGrid', { static: false }) matterTimesGrid: DxDataGridComponent;
    @ViewChild('timeEntryEditComponent', { static: false }) timeEntryEditComponent: TimeEntryPopupComponent;

    private _subscribedSubjects$ = new Subject<boolean>();

    public isTabMenuVisible = true;
    public isFormLoading: boolean = true;
    public currentMatterId: number = 0;
    public isArchived: boolean = false;
    public tabMenuItems: Array<MenuItem> = [
        { label: 'Details', index: 0 } as MenuItem,
        { label: 'Memos', index: 1, disabled: true } as MenuItem,
        { label: 'Time', index: 2, disabled: true } as MenuItem
    ];
    public currentMatter: Matter = null;
    public matterForm: FormGroup = null;
    public activeMenuItem: MenuItem = null;
    public matterTimeDataSource: Array<MatterTime> = [];
    public matterTypes: Array<FormFieldListItem> = [];
    public phases: Array<FormFieldListItem> = [];
    public matterStatuses: Array<FormFieldListItem> = [];
    public attorneys: Array<FormFieldListItem> = [];
    public paralegals: Array<FormFieldListItem> = [];
    public applicationTypes: Array<FormFieldListItem> = [];
    public fileForeigns: Array<any> = [
        { Id: 0, Type: 'Check' },
        { Id: 1, Type: 'Yes' },
        { Id: 2, Type: 'No' }
    ];

    public OpinionTypes: Array<any> = [
        { Id: 0, Type: 'Patentability' },
        { Id: 1, Type: 'Infringement' },
        { Id: 2, Type: 'Freedom to Operate' },
        { Id: 3, Type: 'Trademark Clearance' },
        { Id: 4, Type: '144' },
        { Id: 5, Type: '4(1)' },
        { Id: 6, Type: 'DTC' },
        { Id: 7, Type: 'S-1' },
        { Id: 7, Type: 'Other' },
    ];
    public budgetTypes: Array<FormFieldListItem> = [];
    public ees: Array<IEe> = [];

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private matterService: MatterService, private contactService: ContactService, private eeService: EeService, private lookupService: FormFieldLookupService, private toast: ToastService, private matterTimeService: MatterTimeService, private userService: UserService) {
        this.activeMenuItem = this.tabMenuItems[0];
    }

    ngOnInit(): void {
        this.currentMatterId = +this.activatedRoute.snapshot.paramMap.get('matterId');
        this.initForm();
    }

    private initForm() {
        if (this.currentMatterId > 0) {
            this.tabMenuItems[MATTERS_TAB_MENU_MEMOS_INDEX_ID].disabled = false;
            this.tabMenuItems[MATTERS_TAB_MENU_TIME_INDEX_ID].disabled = false;
        }
        this.matterForm = new FormGroup(
            {
                //Details Fields
                matterRef: new FormControl(null, [Validators.required]),
                matterTitle: new FormControl(null, [Validators.required]),
                clientId: new FormControl(null, [Validators.required]),
                clientName: new FormControl(null),
                clientReference: new FormControl({ value: null, disabled: true }),
                matterType: new FormControl(null, [Validators.required]),
                phase: new FormControl({ value: null, disabled: true }),
                responsibleAttorney: new FormControl(null),
                otherResponsibleAttorney: new FormControl(null),
                matterStatus: new FormControl(null, [Validators.required]),
                originatingAttorney: new FormControl(null),
                assignedAttorney: new FormControl(null),
                paralegal: new FormControl(null),
                //Application
                applicationType: new FormControl(null),
                applicationSerialNumber: new FormControl(null),
                patentNumber: new FormControl(null),
                country: new FormControl(null),
                registrationNumber: new FormControl(null),
                receivingOffice: new FormControl(null),
                isSOUCompleted: new FormControl(false),
                annualFilingDT: new FormControl(null),
                registrationDT: new FormControl(null),
                filingDT: new FormControl(null),
                goodsServices: new FormControl(null),
                opinionType: new FormControl(null),
                //Foreign Options
                fileForeign: new FormControl(this.fileForeigns[0]),
                //Pay Options
                isPayAfter: new FormControl(false),
                isFlatRate: new FormControl(false),
                isScheduled: new FormControl(false),
                isNotBillable: new FormControl(false),
                //Current Project Budget
                budgetAmount: new FormControl(null),
                budgetType: new FormControl(null),
                //Memo
                clientMemo: new FormControl(null),
                intMatDocMemo: new FormControl(null),
                note: new FormControl(null),
            }
        );

        if (this.currentMatterId > 0) {
            this.getFormDataForEdit();
        } else {
            this.getFormDataForCreate();
        }
    }

    public getFormDataForCreate() {
        forkJoin([this.getListOfMatterTypes(), this.getListOfAttorneys(), this.getListOfMatterStatuses(), this.getListOfParalegals(), this.getAttorneyEes()]).pipe(map(([matterTypes, attorneys, matterStatuses, paralegals, ees]) => {
            this.matterTypes = matterTypes;
            this.attorneys = attorneys;
            this.matterStatuses = matterStatuses;
            this.paralegals = paralegals;
            this.ees = ees;
        }), tap(_ => {
            this.isFormLoading = false;
        })).subscribe();
    }

    public getFormDataForEdit() {
        forkJoin([this.getFormFieldsFromCurrentMatterObj(), this.getListOfMatterTypes(), this.getListOfAttorneys(), this.getListOfMatterStatuses(), this.getListOfParalegals(), this.getListOfBudgetTypes(), this.getOriginAttorneyByMatterId(this.currentMatterId), this.getAttorneyEes(), this.getMatterTime()]).pipe(map(([matters, matterTypes, attorneys, matterStatuses, paralegals, budgetTypes, originAttorneyId, ees, matterTimes]) => {
            this.matterTypes = matterTypes;
            this.attorneys = attorneys;
            this.matterStatuses = matterStatuses;
            this.paralegals = paralegals;
            this.budgetTypes = budgetTypes;
            this.ees = ees;

            const matter = matters[0];
            this.currentMatter = matter;
            //Assigned comes back with EERef instead of Id, find it then use it in attorneys search.
            const assignedEeId = this.ees.find(ee => ee.eeRef === matter.assigned)?.eeId;
            this.matterForm.controls['clientReference'].enable();
            this.matterForm.patchValue({
                //Details Fields
                matterRef: matter.matterRef,
                matterTitle: matter.matterTitle,
                clientId: matter.clientId,
                clientReference: matter.clientReference,
                matterType: this.matterTypes.find(mt => mt.validValue === matter.matterType),
                responsibleAttorney: this.attorneys.find(a => a.validValue === matter.responsibleEe),
                matterStatus: this.matterStatuses.find(ms => ms.validValue === matter.matterStatus),
                originatingAttorney: this.attorneys.find(a => a.validValue === originAttorneyId),
                assignedAttorney: this.attorneys.find(a => a.validValue === assignedEeId),
                paralegal: this.paralegals.find(p => p.validValue === matter.paralegal),
                //Application
                applicationSerialNumber: matter.appSerialNum,
                patentNumber: matter.patentNum,
                country: matter.appCountry,
                registrationNumber: matter.registrationNum,
                receivingOffice: matter.registrationNum,
                isSOUCompleted: (matter.stmntOfUseFiled !== null),
                annualFilingDT: DateTime.fromISO(matter.annualFilingDate).toFormat('MM/dd/yyyy'),
                registrationDT: DateTime.fromISO(matter.registrationDate).toFormat('MM/dd/yyyy'),
                filingDT: DateTime.fromISO(matter.filingDate).toFormat('MM/dd/yyyy'),
                goodsServices: matter.goodsServices,
                opinionType: this.OpinionTypes.find(ot => ot.Type === matter.opinionType),
                //Foreign Options
                fileForeign: this.fileForeigns.find(ff => ff.Type === matter.fileForeign),
                //Pay Options
                isPayAfter: (matter.payOptionsAdvance === 'Pay After'),
                isFlatRate: (matter.payOptionsRate === 'Flat Rate'),
                isScheduled: (matter.payOptionsSchedule === 'Scheduled'),
                isNotBillable: (matter.payOptionsBillable === 'Not Billable'),
                //Current Project Budget
                budgetAmount: matter.budget,
                budgetType: this.budgetTypes.find(bt => bt.validValue === matter.budgetType),
                //Memo
                clientMemo: matter.clientMemo,
                intMatDocMemo: matter.memo,
                note: matter.matterNotes,
            });
            this.isArchived = (matter.archiveDate !== null);
            this.matterTimeDataSource = matterTimes;
            if (matter.matterType) this.getExtraListsOffOfEditData(matter.matterType);
        })).subscribe();
    }

    private getListOfMatterTypes = (): Observable<Array<FormFieldListItem>> => this.lookupService.get('MatterType').pipe(map((items) => items));

    private getListOfAttorneys = (): Observable<Array<FormFieldListItem>> => this.lookupService.get('Staff', 'AttorneyFull').pipe(map((items) => items));

    private getAttorneyEes(): Observable<Array<IEe>> {
        const params = { $filter: ODataFilterBuilder().eq('IsAttorney', 'Y').toString() } as ParamObj;
        return this.eeService.get(params).pipe(map((ees) => ees));
    }

    private getListOfMatterStatuses = (): Observable<Array<FormFieldListItem>> => this.lookupService.get('MatterStatus').pipe(map((items) => items));

    private getListOfParalegals = (): Observable<Array<FormFieldListItem>> => this.lookupService.get('Staff', 'Paralegal').pipe(map((items) => items));

    private getListOfBudgetTypes = (): Observable<Array<FormFieldListItem>> => this.lookupService.get('BudgetType', 'Matter').pipe(map((items) => items));

    private getFormFieldsFromCurrentMatterObj(): Observable<Array<Matter>> {
        const params = { $filter: ODataFilterBuilder().eq('MatterId', this.currentMatterId).toString() } as ParamObj;
        return this.matterService.get(params).pipe(map((matters) => matters));
    }

    private getOriginAttorneyByMatterId = (currentMatterId: number): Observable<number> => this.matterService.getOriginAttorneyByMatterId(this.currentMatterId).pipe(map((attId) => attId));

    private getMatterTime(): Observable<Array<MatterTime>> {
        return this.matterTimeService.getMatterTimes(this.currentMatterId).pipe(map((matters) => matters));
    }

    public refreshMatterTimes() {
        this.getMatterTime().pipe(map((matterTimes) => {
            this.matterTimeDataSource = matterTimes;
            this.matterTimesGrid.instance.refresh();
        })).subscribe();
    }

    public onActiveItemChange(tabMenuItem: any) {
        this.activeMenuItem = tabMenuItem;
    }

    public onReferralSourceClicked(contact: ContactSearchResult) {
        this.matterForm.patchValue({
            clientId: contact.contactID,
            clientName: contact.companyName
        });
        this.tabMenuItems[0].label = `Details: ${contact.companyName}`;
        this.refreshTabMenu();
    }

    private refreshTabMenu(): void {
        this.isTabMenuVisible = false;
        setTimeout(() => {
            this.isTabMenuVisible = true;
        }, 0);
    }

    public onMatterTypeChange = (event: any) => this.getExtraListsOffOfEditData(event.value.validValue);

    // Method for extra methods that require MatterType or the initial edit object to load
    public getExtraListsOffOfEditData(matterType: string) {
        forkJoin([this.getListOfPhases(matterType), this.getListOfApplicationTypes(matterType), this.getClientName(), this.getAssignedAttorneyEe()]).pipe(map(([phases, applicationTypes, clients, assignedAttorneyEe]) => {
            // Get proper list of phases and patch if the value is within the list of new values from changed matterType
            this.phases = phases;
            if (matterType !== null) this.matterForm.controls['phase'].enable();
            else this.matterForm.controls['phase'].disable();
            if (this.currentMatterId) {
                const currentPhase = this.phases.find(p => p.validValue === this.currentMatter.phase);
                this.matterForm.patchValue({
                    phase: (currentPhase) ? currentPhase : this.phases[0]
                });

            }
            // Get proper list of application types and patch if the value is within the list of new values from changed matterType
            this.applicationTypes = applicationTypes;
            if (this.currentMatterId) {
                const currentAppType = this.applicationTypes.find(p => p.validValue === this.currentMatter.appType);
                this.matterForm.patchValue({
                    applicationType: (currentAppType) ? currentAppType : this.applicationTypes[0]
                });
            }
            // Get Client Name based on client id pulled from edit data
            if (clients.length > 0) {
                this.matterForm.patchValue({
                    clientName: clients[0].company
                });
                if (this.matterForm.value.clientName) {
                    this.tabMenuItems[0].label = `Details: ${this.matterForm.value.clientName}`;
                }
            }
        }), tap((_) => {
            this.isFormLoading = false;
        })).subscribe();
    }

    private getListOfPhases(matterType: string): Observable<Array<FormFieldListItem>> {
        return this.lookupService.get('Phase', matterType).pipe(map((items) => items));
    }

    private getListOfApplicationTypes(matterType: string): Observable<Array<FormFieldListItem>> {
        return this.lookupService.get('ApplicationType', matterType).pipe(map((items) => items));
    }

    private getClientName(): Observable<Array<Contact>> {
        if (this.currentMatter) {
            const params = { $filter: ODataFilterBuilder().eq('ContactID', this.currentMatter.clientId).toString() } as ParamObj;
            return this.contactService.get(params).pipe(map((contacts) => contacts));
        }
        return of([]);
    }

    public onProcessCompleted(request: MatterResponsibleChangeRequest) {
        if (this.currentMatterId > 0) {
            //TODO: Figure out if anything needs to happen on the form if data in the history module changes
        } else {
            this.matterForm.patchValue({
                otherResponsibleAttorney: request.newResp
            });
        }
    }

    public submitForm() {
        this.matterForm.markAllAsTouched();
        if (!this.matterForm.valid) {
            this.toast.error('Contact Invalid, make sure to fill out all required fields');
        } else {
            this.saveMatter();
        }
    }

    public saveMatter() {
        if (this.currentMatterId > 0) {
            this.connectLatestMatterFromForm();
            this.updateMatter();
        } else {
            this.saveNewMatter();
        }
    }

    public connectLatestMatterFromForm() {
        const convertedFilingDate = (DateTime.fromISO(this.matterForm.value.filingDT).toFormat('yyyy-MM-dd') === 'Invalid DateTime') ? DateTime.fromJSDate(this.matterForm.value.filingDT) : DateTime.fromISO(this.matterForm.value.filingDT);
        const convertedIssueNRegistrationDate = (DateTime.fromISO(this.matterForm.value.registrationDT).toFormat('yyyy-MM-dd') === 'Invalid DateTime') ? DateTime.fromJSDate(this.matterForm.value.registrationDT) : DateTime.fromISO(this.matterForm.value.registrationDT);
        const latestMatter: Matter = this.currentMatter;
        latestMatter.matterId = this.currentMatterId;
        latestMatter.matterRef = this.matterForm.value.matterRef;
        latestMatter.matterTitle = this.matterForm.value.matterTitle;
        latestMatter.matterType = this.matterForm.value.matterType.validValue;
        latestMatter.clientId = this.matterForm.value.clientId;
        latestMatter.clientReference = this.matterForm.value.clientReference;
        latestMatter.phase = this.matterForm.value.phase?.validValue;
        latestMatter.memo = this.matterForm.value.intMatDocMemo;
        latestMatter.clientMemo = this.matterForm.value.clientMemo;
        latestMatter.responsibleEe = this.matterForm.value.responsibleAttorney.validValue;
        //Expects EeRef use ees Search to find the proper Row.
        latestMatter.assigned = this.ees.find(ee => ee.eeId === this.matterForm.value.assignedAttorney?.validValue)?.eeRef;
        latestMatter.paralegal = this.matterForm.value.paralegal?.validValue;
        latestMatter.matterStatus = this.matterForm.value.matterStatus.validValue;
        latestMatter.registrationDate = (this.showIssueDate()) ? convertedIssueNRegistrationDate : null;

        if (this.showReceivingOffice()) {
            latestMatter.registrationNum = this.matterForm.value.receivingOffice;
        } else if (this.showRegistrationNumber()) {
            latestMatter.registrationNum = this.matterForm.value.registrationNumber;
        } else {
            latestMatter.registrationNum = null;
        }


        if (this.showSOUCompleted())
            latestMatter.stmntOfUseFiled = (this.matterForm.value.isSOUCompleted) ? DateTime.now().toUTC() : null;
        else
            latestMatter.stmntOfUseFiled = null;

        latestMatter.appType = (this.showApplication()) ? this.matterForm.value.applicationType?.validValue
            : null;

        latestMatter.appSerialNum = (this.showApplication())
            ? this.matterForm.value.applicationSerialNumber?.toString()
            : null;

        latestMatter.appCountry = (this.showCountry()) ? this.matterForm.value.country : null;
        latestMatter.fileForeign = (this.showFileForeign()) ? this.matterForm.value.fileForeign?.Type : null;
        latestMatter.opinionType = (this.showOpinionType()) ? this.matterForm.value.opinionType?.Type : null;
        latestMatter.payOptionsAdvance = (this.matterForm.value.isPayAfter) ? 'Pay After' : 'Advance';
        latestMatter.payOptionsRate = (this.matterForm.value.isFlatRate) ? 'Flat Rate' : 'Hourly';
        latestMatter.payOptionsSchedule = (this.matterForm.value.isScheduled) ? 'Scheduled' : 'Completion';
        latestMatter.payOptionsBillable = (this.matterForm.value.isNotBillable) ? 'Not Billable' : 'Billable';
        latestMatter.reissuePatentNum = null;
        latestMatter.patentNum = (this.showPatentNumber()) ? this.matterForm.value.patentNumber?.toString() : null;
        latestMatter.budget = this.matterForm.value.budgetAmount; //decimal
        latestMatter.budgetType = this.matterForm.value.budgetType?.validValue;
        latestMatter.matterNotes = this.matterForm.value.note;
        latestMatter.goodsServices = (this.showGoodsServices()) ? this.matterForm.value.goodsServices : null;

        if (this.showApplication()) {
            latestMatter.filingDate = (!convertedFilingDate.invalid) ? convertedFilingDate.toFormat('yyyy-MM-dd\'T00:00:00\'') : this.currentMatter.filingDate;
        } else {
            latestMatter.filingDate = null;
        }

        this.currentMatter = latestMatter;
    }

    public updateMatter() {
        const req = { Matter: this.currentMatter, OriginationAttorneyId: this.matterForm.value.originatingAttorney.validValue };

        this.matterService.update(req).pipe(map(() => {
            this.toast.success('Successfully updated the matter');
        })).subscribe();
    }

    public saveNewMatter() {
        const matterAddRequest = {
            MatterReference: this.matterForm.value.matterRef,
            MatterTitle: this.matterForm.value.matterTitle,
            MatterType: this.matterForm.value.matterType.validValue,
            ClientId: this.matterForm.value.clientId,
            Phase: this.matterForm.value.phase.validValue,
            ResponsibleAttorney: this.matterForm.value.responsibleAttorney?.validValue,
            OriginationAttorney: this.matterForm.value.originatingAttorney?.validValue,
            OtherResponsibleAttorney: this.matterForm.value.otherResponsibleAttorney
        } as MatterAddRequest;
        this.matterService.post(matterAddRequest).pipe(map((newMatterId) => {
            if (newMatterId === -1) {
                this.toast.error('Could not create Matter, Duplicate Matter Reference Id.');
            } else {
                this.router.navigate([`/app/matter/${newMatterId}`]).then(() => {
                    window.location.reload();
                });
            }
        })).subscribe();
    }

    public cloneMatter() {
        this.connectLatestMatterFromForm();

        const req = { Matter: this.currentMatter, OriginationAttorneyId: this.matterForm.value.originatingAttorney.validValue };

        this.matterService.clone(req).pipe(map((response) => {
            this.toast.success('Successfully cloned the matter');
            this.router.navigate([`/app/matter/${response.newMatterID}`]).then(() => {
                window.location.reload();
            });
        })).subscribe();
    }

    public cancel() {
        this.router.navigate(['/app/matters']);
    }

    public showApplication() {
        const matterType = this.matterForm.value.matterType?.validValue;
        switch (matterType) {
            case 'Provisional Patent': return true;
            case 'Utility Patent': return true;
            case 'PCT': return true;
            case 'Foreign Patent': return true;
            case 'Design Patent': return true;
            case 'Trademark': return true;
            case 'Foreign Trademark': return true;
            case 'Copyright': return true;
            case 'Foreign Copyright': return true;
            default: return false;
        }
    }

    public showPatentNumber() {
        const matterType = this.matterForm.value.matterType?.validValue;
        switch (matterType) {
            case 'Provisional Patent': return true;
            case 'Utility Patent': return true;
            case 'Foreign Patent': return true;
            case 'Design Patent': return true;
            default: return false;
        }
    }

    public showCountry() {
        const matterType = this.matterForm.value.matterType?.validValue;
        switch (matterType) {
            case 'Foreign Patent': return true;
            case 'Foreign Trademark': return true;
            case 'Foreign Copyright': return true;
            default: return false;
        }
    }

    public showRegistrationNumber() {
        const matterType = this.matterForm.value.matterType?.validValue;
        switch (matterType) {
            case 'Trademark': return true;
            case 'Foreign Trademark': return true;
            case 'Copyright': return true;
            case 'Foreign Copyright': return true;
            default: return false;
        }
    }

    public showRegistrationDate() {
        const matterType = this.matterForm.value.matterType?.validValue;
        switch (matterType) {
            case 'Trademark': return true;
            case 'Foreign Trademark': return true;
            default: return false;
        }
    }

    public showSOUCompleted() {
        const matterType = this.matterForm.value.matterType?.validValue;
        return (matterType === 'Trademark');
    }

    public showGoodsServices() {
        const matterType = this.matterForm.value.matterType?.validValue;
        switch (matterType) {
            case 'Trademark': return true;
            case 'Foreign Trademark': return true;
            default: return false;
        }
    }

    public showReceivingOffice() {
        const matterType = this.matterForm.value.matterType?.validValue;
        return (matterType === 'PCT');
    }

    public showOpinionType() {
        const matterType = this.matterForm.value.matterType?.validValue;
        return (matterType === 'Legal Opinion');
    }

    public showFileForeign() {
        const matterType = this.matterForm.value.matterType?.validValue;
        switch (matterType) {
            case 'Provisional Patent': return true;
            case 'Utility Patent': return true;
            case 'Design Patent': return true;
            default: return false;
        }
    }

    public showIssueDate() {
        const matterType = this.matterForm.value.matterType?.validValue;
        switch (matterType) {
            case 'Provisional Patent': return true;
            case 'Utility Patent': return true;
            case 'Foreign Patent': return true;
            case 'Design Patent': return true;
            default: return false;
        }
    }

    public getAssignedAttorneyEe(): Observable<IEe> {
        if (this.matterForm.controls['assignedAttorney'].value) {
            const params = {
                $filter: ODataFilterBuilder().eq('eeId', this.matterForm.controls['assignedAttorney'].value.validValue).toString()
            } as ParamObj;
            return this.eeService.get(params).pipe(map((ee) => ee[0]));
        } else {
            return of(null);
        }
    }

    public onRowClick($event: any) {
        this.timeEntryEditComponent.onEditTimeEntryClicked($event.data.timeId, this.currentMatterId);
    }

    public savedTimeEntry($event: any) {
        this.refreshMatterTimes();
    }

    ngOnDestroy(): void {
        this._subscribedSubjects$.next(true);
        this._subscribedSubjects$.complete();
    }
}
