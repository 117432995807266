import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TimerService } from '@core.services/timekeeping/timer.service';
import { TimerWithRun } from '@core.models/timer.model';
import { Subject, takeUntil } from 'rxjs';
import { OverlayPanel } from 'primeng/overlaypanel';
import { UserService } from '@core.services/user/user.service';
import { TimeEntryPopupComponent } from '../time-entry-popup/time-entry-popup.component';
import { Matter } from '@core.models/matter.interface';
import { eeInfo } from '@core.models/user';

@Component({
    selector: 'app-timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy {
    @ViewChild('timeEntryComponent', { static: false }) timeEntryComponent: TimeEntryPopupComponent;
    @ViewChild('timeEntryEditComponent', { static: false }) timeEntryEditComponent: TimeEntryPopupComponent;
    private destroyed$ = new Subject();
    @Input() currentEeInfo: eeInfo = null;
    @Input() show_buttons: boolean = true;
    @Input() disable_start: boolean = false;
    @Input() label: string = 'New Timer';
    @Input() isInEditPopup: boolean = false;
    @Input() currentMatter: Matter = null;
    @ViewChild('activeDialog') activeDialog: OverlayPanel;

    @Input() savedMatterTime: any = null;

    public pinnedTimer: TimerWithRun;
    public openTimer: TimerWithRun = new TimerWithRun(); // Timer viewed in timer dialog
    public activeTimers: TimerWithRun[];

    constructor(
        public timerService: TimerService,
        private userService: UserService
    ) {
        this.timerService.pinned
            .pipe(takeUntil(this.destroyed$))
            .subscribe((pinned) => {
                this.pinnedTimer = pinned;
            });

        this.timerService.timers
            .pipe(takeUntil(this.destroyed$))
            .subscribe((timers) => {
                this.activeTimers = timers;
            });
    }

    ngOnInit() {
        this.timerService.load();
    }

    ngOnDestroy() {
        this.destroyed$.next(null);
        this.destroyed$.complete();
    }

    public openPopup($event: any, timer?: TimerWithRun, create?: boolean) {
        if (create) {
            const info = this.userService.userSnapshot?.eeInfo;

            this.openTimer = this.timerService.spawnTimer(
                null,
                null,
                null,
                info.eeId,
                false,
                false
            );
            this.timeEntryComponent.newTimeEntryInit(this.openTimer);
            this.activeDialog.hide();
        } else {
            this.openTimer = timer;
            this.timerService.openTimer = this.openTimer;
            this.timeEntryEditComponent.onEditTimeEntryClicked(this.openTimer.matterTimeId, this.openTimer.matterId);
            this.activeDialog.hide();
        }
    }

    public showActiveTimers($event: any) {
        this.activeDialog.toggle($event);
    }

    /* Timer pinning */
    public pinTimer(timer: TimerWithRun) {
        this.timerService.pinTimer(timer);
    }

    public resumeTimer(timer?: TimerWithRun) {
        if (timer) {
            this.timerService.startTimer(timer);
        }
    }

    public stopTimer(timer?: TimerWithRun) {
        if (this.pinnedTimer) {
            this.timerService.stopTimer(timer);
            this.openPopup(null, timer);
        }
    }

    public cancelTimer(timer?: TimerWithRun) {
        if (timer) {
            this.openPopup(null, timer);
            const tenthOfASec = 100;
            setTimeout(() => {
                this.timerService.cancelTimer(timer);
                if (!this.activeTimers.length) {
                    this.activeDialog.hide();
                }
            }, tenthOfASec);
        }
    }

    /* Pinned timer control functionality */

    public resumePinnedTimer() {
        if (this.pinnedTimer) {
            this.timerService.startTimer(this.pinnedTimer);
        }
    }

    public stopPinnedTimer() {
        if (this.pinnedTimer) {
            this.timerService.stopTimer(this.pinnedTimer);
            this.openPopup(null, this.pinnedTimer);
        }
    }

    public cancelPinnedTimer() {
        if (this.pinnedTimer) {
            this.openPopup(null, this.pinnedTimer);
            const tenthOfASec = 100;
            setTimeout(() => {
                this.timerService.cancelTimer(this.pinnedTimer);
                this.pinnedTimer = null;
            }, tenthOfASec);
        }
    }
}
