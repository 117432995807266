import { User } from './user';

export interface IDocumentVersion {
    id: string;
    filename: string;
    extension: string;
    uploadDate: Date;
    uploadedBy: User;
}
  
export class DocumentVersion implements IDocumentVersion {
    id: string;
    filename: string;
    extension: string;
    uploadDate: Date;
    uploadedBy: User;
  
    constructor(init?: Partial<IDocumentVersion>) {
        if (init?.id) this.id = init.id;
        if (init?.filename) this.filename = init.filename;
        if (init?.extension) this.extension = init.extension;
        if (init?.uploadDate) this.uploadDate = init.uploadDate;
        if (init?.uploadedBy) this.uploadedBy = init.uploadedBy;
    }
}