import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, concatMap, debounceTime, distinctUntilChanged, map, Observable, of, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BillingRateRequest, MatterTime } from '../../models/timekeeper.interface';
import { Matter } from '../../models/matter.interface';
import { MatterService } from '../../services/matter/matter.service';
import ODataFilterBuilder from 'odata-filter-builder';
import { ParamObj } from '../../models/param-query.interface';
import { TimerService } from '@core.services/timekeeping/timer.service';
import { MatterTimeService } from '@core.services/timekeeping/matter-time.service';
import { TimerComponent } from '../timer/timer.component';
import { TimerWithRun } from '@core.models/timer.model';
import { UserService } from '@core.services/user/user.service';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { DateTime } = require('luxon');

const DEBOUNCE_TIME_MILISECONDS = 600;
const ENTER_KEY_NUMBER = 13;
@Component({
    selector: 'app-time-entry-form',
    templateUrl: './time-entry-form.component.html',
    styleUrls: ['./time-entry-form.component.scss']
})
export class TimeEntryFormComponent implements OnInit, OnDestroy {
    @ViewChild('mattersGrid', { static: false }) mattersGrid: DxDataGridComponent;
    @ViewChild('matterTimeFormTimerComp', { static: false }) matterTimeFormTimerComp: TimerComponent;

    private destroyed$ = new Subject();
    isLoading = true;
    public mattersDataSource: Array<Matter> = [];

    public searchRequested$ = new BehaviorSubject<string>(null);

    @Input() currentMatterTimeId: number = 0;
    @Input() isEdit = false;
    @Input() currentMatterId: number = 0;
    @Input() canChangeMatter = true;
    @Output() savedMatterTime = new EventEmitter<number>();
    @Output() startSavedMatterTime = new EventEmitter<number>();

    hasActiveTimerFromTimersArray: boolean = false;

    public currentMatter: Matter = null;
    public currentBillingRate: number = 0.0;

    public entryTypes: Array<any> = [
        { Id: 0, Type: 'Time' },
        { Id: 1, Type: 'Expense' },
    ];

    public timeEntryForm: FormGroup = this.newForm();

    totalFeeAmount: number = 0.0;

    constructor(private matterService: MatterService, public timerService: TimerService, private matterTimeService: MatterTimeService, private userService: UserService) {
    }

    public ngOnInit(): void {
        this.matterTimeService.loadTimeEntryFormModuleData$.pipe(
            takeUntil(this.destroyed$),
            concatMap(_ => this.getLatestMatter().pipe(map((matter) => {
                this.currentMatter = matter;
                return matter;
            }))), switchMap(matter => this.getBillingRate(matter?.matterId, DateTime.now().toUTC()).pipe(map((billingRate) => {
                this.currentBillingRate = billingRate;
            }))), tap((_) => this.initForm())).subscribe();
    }

    private getLatestMatter(): Observable<Matter> {
        const params = { $filter: ODataFilterBuilder().eq('MatterId', this.currentMatterId).toString() } as ParamObj;
        return this.matterService.get(params).pipe(map((matters) => matters[0]));
    }

    private getBillingRate(matterId: number = null, entryDate: string = null): Observable<number> {
        const eeInfo = this.userService.userSnapshot?.eeInfo;
        const request: BillingRateRequest = {
            eeId: eeInfo.eeId,
            contactId: null, //TODO: have a special search for finding a matching contact
            matterId: matterId,
            entryDate: entryDate
        };
        return this.matterTimeService.getBillingRate(request);
    }

    public onBillingRateParamChange() {
        this.getBillingRate(this.currentMatterId, this.timeEntryForm.controls['date'].value).pipe(takeUntil(this.destroyed$), map((billingRate) => {
            this.currentBillingRate = billingRate;
            this.timeEntryForm.patchValue({
                rate: this.currentBillingRate
            });
        })).subscribe();
    }

    public ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    private newForm(): FormGroup {
        return new FormGroup(
            {
                entryType: new FormControl(this.entryTypes[0]),
                matterId: new FormControl(this.currentMatter?.matterId, [Validators.required]),
                matterRef: new FormControl(this.currentMatter?.matterRef, [Validators.required]),
                matterTitle: new FormControl(this.currentMatter?.matterTitle, [Validators.required]),
                date: new FormControl(new Date(), [Validators.required]),
                time: new FormControl(null),
                adjustments: new FormControl(null),
                rate: new FormControl(this.currentBillingRate),
                nonBillableHours: new FormControl(0),
                chargeAmount: new FormControl({ value: 0, disabled: true }),
                totalAdjustments: new FormControl({ value: 0, disabled: true }),
                description: new FormControl(null),
                isReadyToBill: new FormControl(false),
                delete: new FormControl(false)
            }
        );
    }

    private initForm() {
        this.isLoading = true;
        if (!this.timeEntryForm) {
            this.timeEntryForm = this.newForm();
        } else {
            this.timeEntryForm.patchValue({
                entryType: this.entryTypes[0],
                matterId: this.currentMatter?.matterId,
                matterRef: this.currentMatter?.matterRef,
                matterTitle: this.currentMatter?.matterTitle,
                date: new Date(),
                time: null,
                adjustments: null,
                rate: this.currentBillingRate,
                nonBillableHours: 0,
                chargeAmount: 0,
                totalAdjustments: 0,
                description: null,
                isReadyToBill: false,
                delete: false
            });
        }

        if (!this.canChangeMatter) {
            this.timeEntryForm.controls['matterTitle'].disable();
            this.timeEntryForm.patchValue({
                matterId: this.currentMatter.matterId,
                matterRef: this.currentMatter.matterRef,
                matterTitle: `${this.currentMatter.matterRef} ${this.currentMatter.matterTitle}`
            });
        } else {
            this.initSearchObservable();
        }

        if (this.isEdit) {
            if (this.currentMatterTimeId) {
                this.matterTimeService.latestMatterTime(this.currentMatterTimeId).pipe(takeUntil(this.destroyed$),
                    map((matterTime) => {
                        this.setCurrentMatterTimeObject(matterTime);
                    })
                ).subscribe();
            } else {
                this.isLoading = false;
            }
        } else {
            //Mark all as pristine, form just got initiated
            this.markFormAsPristine();
            this.isLoading = false;
        }
    }

    private setCurrentMatterTimeObject(latestMatterTime: MatterTime) {
        this.timeEntryForm.patchValue({
            entryType: latestMatterTime.entryType,
            matterId: latestMatterTime.matterId,
            matterRef: latestMatterTime.matterRef,
            matterTitle: `${latestMatterTime.matterRef} ${latestMatterTime.matterTitle}`,
            date: latestMatterTime.entryDate,
            time: latestMatterTime.hoursQty,
            adjustments: latestMatterTime.noCharge,
            rate: latestMatterTime.ratePrice,
            nonBillableHours: latestMatterTime.nonBill,
            description: latestMatterTime.entryDesc,
            isReadyToBill: latestMatterTime.billReadyToBill,
            delete: false
        });
        if (!latestMatterTime.matterTitle && latestMatterTime.matterId) {
            const params = { $filter: ODataFilterBuilder().eq('MatterID', latestMatterTime.matterId).toString() } as ParamObj;
            this.matterService.get(params).pipe(map((matters) => {
                this.timeEntryForm.patchValue({
                    matterRef: matters[0].matterRef,
                    matterTitle: `${matters[0].matterRef} ${matters[0].matterTitle}`,
                });
                this.currentMatter = matters[0];
                this.clearSearch();
            })).subscribe();
        } else {
            this.currentMatter = {
                matterId: latestMatterTime.matterId,
                matterRef: latestMatterTime.matterRef,
                matterTitle: latestMatterTime.matterTitle
            } as Matter;
            this.clearSearch();
        }

        this.matterTimeService.setMatterTimeByNewMatterTime(latestMatterTime);

        this.isLoading = false;
        //Mark all as pristine, form just got initiated
        this.markFormAsPristine();

        this.onTimeChange(null);

        if (this.timerService.openTimer.id > 0 && !this.timerService.openTimer.isRunning) {
            this.timeEntryForm.controls['time'].markAsDirty();
            this.timeEntryForm.patchValue({
                time: this.timeEntryForm.value.time + this.timerService.getAccruedHours(this.timerService.openTimer)
            });
        }
    }

    private markFormAsPristine() {
        for (const key in this.timeEntryForm.controls) {
            this.timeEntryForm.controls[key].markAsPristine();
        }
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Matter Search Functionality
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

    // For adjusting search box for the grid to be used for searching through existing matters
    public onToolbarPreparing(event: any) {
        const toolbarItems = event.toolbarOptions.items;
        toolbarItems.forEach((item: any) => {
            if (item.name === 'searchPanel') {
                item.location = 'before';
            }
        });
    }

    // creates observable for searching through matters
    public initSearchObservable() {
        this.searchRequested$.pipe(
            takeUntil(this.destroyed$),
            distinctUntilChanged(),
            debounceTime(DEBOUNCE_TIME_MILISECONDS),
            switchMap(() => of(this.matterSearch()))).subscribe();
    }

    // event that fires when the search text is changed, instant fire on enter key
    public onSearchChanged($event: any) {
        // short fuse for searching immediately on enter
        if ($event.keyCode === ENTER_KEY_NUMBER) {
            this.matterSearch();
        } else {
            this.requestSearch();
        }
    }

    // a request for a search that will occur following the rules of debounce time
    private requestSearch() {
        this.searchRequested$.next(this.timeEntryForm.value.matterTitle);
    }

    // Will find a top 5 of matters through matters search text
    private matterSearch() {
        const searchText = (this.timeEntryForm.value.matterTitle) ? this.timeEntryForm.value.matterTitle : '';
        const params = { $filter: ODataFilterBuilder('or').contains('MatterRef', searchText).contains('MatterTitle', searchText).toString(), $top: 5 } as ParamObj;
        if (searchText !== '') {
            this.matterService.get(params).pipe(map((matters) => {
                this.mattersDataSource = matters;
                this.mattersGrid?.instance.refresh(true);
            })).subscribe();
        } else {
            this.clearSearch();
        }
    }

    // To clear the devextreme datasource
    public clearSearch() {
        this.mattersDataSource = [];
    }

    // Select a new latestMatter via dx-row select
    public onRowClick(event: any) {
        this.timeEntryForm.patchValue({
            matterId: event.data.matterId,
            matterRef: event.data.matterRef,
            matterTitle: `${event.data.matterRef} ${event.data.matterTitle}`
        });
        this.currentMatter = event.data;
        this.currentMatterId = event.data.matterId;
        this.matterTimeService.setMatterTimeByNewMatterTime(this.buildLatestMatterTime());
        this.onBillingRateParamChange();
        this.clearSearch();
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // MatterTime Time Changes Functionality
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

    // When time is changed calculate totals on form and popup interpolation
    public onTimeChange(event: any) {
        const timeTotal = (this.timeEntryForm.value.rate && this.timeEntryForm.value.time) ? this.timeEntryForm.value.time * this.timeEntryForm.value.rate : 0;
        const adjustments = (this.timeEntryForm.value.rate) ? (this.timeEntryForm.value.rate * this.timeEntryForm.value.adjustments) + (this.timeEntryForm.value.rate * this.timeEntryForm.value.nonBillableHours) : 0;
        const doubleMultiplier = 2;
        this.timeEntryForm.patchValue({
            chargeAmount: timeTotal,
            totalAdjustments: (adjustments) ? adjustments - (adjustments * doubleMultiplier) : 0
        });
        this.totalFeeAmount = this.timeEntryForm.controls['chargeAmount'].value + this.timeEntryForm.controls['totalAdjustments'].value;
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // MatterTime Save/Clone Functionality
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

    // Generate a MatterTime from the object of the form and fields passed to this entry form.
    private buildLatestMatterTime() {
        const eeInfo = this.userService.userSnapshot?.eeInfo;

        return {
            timeId: this.currentMatterTimeId,
            entryType: (this.timeEntryForm.value.entryType.Type) ? this.timeEntryForm.value.entryType.Type : this.timeEntryForm.value.entryType,
            matterId: this.timeEntryForm.controls['matterId'].value,
            matterRef: this.timeEntryForm.controls['matterRef'].value,
            matterTitle: this.currentMatter.matterTitle,
            entryDate: this.timeEntryForm.value.date,
            hoursQty: (this.timeEntryForm.value.time) ? this.timeEntryForm.value.time : 0,
            noCharge: (this.timeEntryForm.value.adjustments) ? this.timeEntryForm.value.adjustments : 0,
            ratePrice: (this.timeEntryForm.value.rate) ? this.timeEntryForm.value.rate : 0,
            nonBill: (this.timeEntryForm.value.nonBillableHours) ? this.timeEntryForm.value.nonBillableHours : 0,
            entryDesc: (this.timeEntryForm.value.description) ? this.timeEntryForm.value.description : '',
            billReadyToBill: (this.timeEntryForm.value.isReadyToBill) ? 'Y' : null,
            eeId: eeInfo.eeId,
            eeRef: eeInfo.eeRef,
            fullName: eeInfo.eeFullName,
            startTime: null,
            adjEntry: 0,
            adjAmount: 0,
            timeExpAmt: this.totalFeeAmount,
            billId: null,
            billProRatedPct: null,
            billProRatedAmt: null,
            migrationId: null,
            loadDate: null,
            timeEditable: null,
            clientId: null
        } as MatterTime;
    }

    public onSaveClicked() {
        if (this.timeEntryForm.value.delete)
            this.deleteMatterTime().pipe(map((matterTimeId) => this.savedMatterTime.emit(matterTimeId))).subscribe();
        else
            this.saveMatterTime().pipe(map((timeId) => this.savedMatterTime.emit(timeId))).subscribe();
    }

    private saveMatterTime(): Observable<number> {
        const matterTime = this.buildLatestMatterTime();
        return this.matterTimeService.save(matterTime).pipe(map((matterTimeId) => {
            // Delete the timer
            if (this.timerService.openTimer.id > 0 && !this.timerService.openTimer.isRunning) {
                this.timerService.deleteTimer(this.timerService.openTimer);
            }

            matterTime.timeId = matterTimeId;
            this.matterTimeService.setMatterTimeByNewMatterTime(matterTime);
            return matterTimeId;
        }));
    }

    private deleteMatterTime(): Observable<number> {
        return this.matterTimeService.delete(this.currentMatterTimeId).pipe(map((_) => this.currentMatterTimeId));
    }

    public onCloneTimeEntryClicked() {
        const latestMatterTime = this.buildLatestMatterTime();
        this.matterTimeService.clone(latestMatterTime).pipe(map((timeId) => {
            this.savedMatterTime.emit(timeId);
        })).subscribe();
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // MatterTime Timer Functionality
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

    public callSaveOpenTimer(currentMatterId: number, currentMatterTimeId: number, currentMatterRef: string) {
        this.timerService.openTimer.matterId = currentMatterId;
        this.timerService.openTimer.matterTimeId = currentMatterTimeId;
        this.timerService.openTimer.matterRef = currentMatterRef;
        this.saveOpenTimer(true);
    }

    public saveOpenTimer(start?: boolean) {
        this.timerService.saveTimer(this.timerService.openTimer, !start);

        if (start && !this.timerService.openTimer.isRunning) {
            this.timerService.startTimer(this.timerService.openTimer);
            this.pinTimer(this.timerService.openTimer);
        }
    }

    public onStartTimerClicked() {
        for (const key in this.timeEntryForm.controls) {
            this.timeEntryForm.controls[key].markAsPristine();
        }
        this.saveMatterTime().pipe(map(matterTimeId => {
            this.hasActiveTimerFromTimersArray = true;
            this.callSaveOpenTimer(this.currentMatter.matterId, matterTimeId, this.currentMatter.matterRef);
        })).subscribe();
    }

    /* Timer pinning */
    public pinTimer(timer: TimerWithRun) {
        this.timerService.pinTimer(timer);
    }

    public resumeTimer(timer?: TimerWithRun) {
        if (this.timeEntryForm.controls['time'].untouched) {
            this.timeEntryForm.patchValue({
                time: this.timeEntryForm.value.time - this.timerService.getAccruedHours(timer)
            });
        }
        this.timeEntryForm.controls['time'].markAsUntouched();
        if (timer) {
            this.timerService.startTimer(timer);
        }
    }

    public stopTimer(timer?: TimerWithRun) {
        if (timer) {
            this.timerService.stopTimer(timer);
        }
        this.timeEntryForm.controls['time'].markAsDirty();
        this.timeEntryForm.patchValue({
            time: this.timeEntryForm.value.time + this.timerService.getAccruedHours(timer)
        });
    }

    public cancelTimer(timer?: TimerWithRun) {
        if (timer) {
            this.timerService.cancelTimer(timer);
        }
    }
}