import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { ParamObj, ParamQuery } from '@core.models/param-query.interface';
import { ActionOdataResult } from '@core.models/action-result.interface';
import { environment } from 'src/environments/environment';
import { AIPRole } from '@core.models/aip-role';

@Injectable({
    providedIn: 'root'
})
export class AIPRoleService {
    constructor(private http: HttpClient) { }

    /**
     * Returns an array of AIP ROLE based on paramQuery
     */
    public get(params?: ParamObj): Observable<Array<AIPRole>> {
        const paramQuery: ParamQuery = params ? { params: params } : null;
        return this.getRoles(paramQuery).pipe(
            map((result) => result.value as Array<AIPRole>)
        );
    }

    private getRoles(paramQuery: ParamQuery): Observable<ActionOdataResult> {
        const httpParams = paramQuery ? paramQuery : {};
        return this.http.get<ActionOdataResult>(
            `${environment.apiBaseURL}odata/AIPRoles`,
            httpParams
        );
    }
}
