import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormFieldListItem } from '../../models/form-field-list-item.interface';
import { filter, findIndex, forkJoin, map, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { IEe } from '../../models/ee';
import ODataFilterBuilder from 'odata-filter-builder';
import { ParamObj } from '../../models/param-query.interface';
import { EeService } from '../../services/ee/ee.service';
import { TimeEntry } from '../../models/timekeeper.interface';
import { TimeEntryPopupComponent } from '../../components/time-entry-popup/time-entry-popup.component';
import { FormFieldLookupService } from '@core.services/lookup/form-field-lookup.service';
import { MatterTimeService } from '@core.services/timekeeping/matter-time.service';
import { UserService } from '@core.services/user/user.service';
import { eeInfo } from '@core.models/user';
import { TimerService } from '@core.services/timekeeping/timer.service';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { DateTime } = require('luxon');

const MOMENTDAY_ONE = 0;
const MOMENTDAY_TWO = 1;
const MOMENTDAY_THREE = 2;
const MOMENTDAY_FOUR = 3;
const MOMENTDAY_FIVE = 4;
const MOMENTDAY_SIX = 5;
const MOMENTDAY_SEVEN = 6;
const LUXON_WEEK_CALC_NUMBER = 7;

const LUXON_SATURDAY_WEEK_DAY = 5;
const LUXON_SUNDAY_WEEK_DAY = 6;
const LUXON_MONDAY_WEEK_DAY = 0;
const LUXON_TUESDAY_WEEK_DAY = 1;
const LUXON_WEDNESDAY_WEEK_DAY = 2;
const LUXON_THURSDAY_WEEK_DAY = 3;
const LUXON_FRIDAY_WEEK_DAY = 4;

const LUXON_DAY_MONTH_NUMBER = 52;
const LUXON_DAY_MONTH_NUMBER_ADJ = 53;

const FIXED_DECIMAL_PLACE = 2;
@Component({
    selector: 'app-timekeeping',
    templateUrl: './timekeeping.component.html',
    styleUrls: ['./timekeeping.component.scss']
})
export class TimekeepingComponent implements OnInit {
    @ViewChild('timeEntryEditComponent', { static: false }) timeEntryEditComponent: TimeEntryPopupComponent;
    private _subscribedSubjects$ = new Subject<boolean>();
    public isLoading = true;
    public dateRangeWeek: number = 0;
    public dateRangeYear: number = 0;
    public startDate: string = DateTime.local()
        .startOf('week')
        .minus({ day: 1 })
        .setLocale('en-US')
        .toISODate();
    public endDate: string = DateTime.local()
        .endOf('week')
        .minus({ day: 1 })
        .toISODate();
    public weekObj = DateTime.fromObject({
        weekYear: DateTime.local().weekYear,
        weekNumber: DateTime.local().weekNumber
    });
    public week: number = this.dateRangeWeek
        ? this.dateRangeWeek
        : DateTime.local().weekNumber;
    public year = this.dateRangeYear
        ? this.dateRangeYear
        : DateTime.local().year;
    timeEntries: any[] = [];
    days: number;

    public timekeepers: Array<IEe> = [];
    public currentTimekeeper: IEe = null;
    public currentEeInfo: eeInfo = null;

    public onEditTimeEntryClicked = new EventEmitter<TimeEntry>();
    constructor(private matterTimeService: MatterTimeService, private eeService: EeService, private lookupService: FormFieldLookupService, private timerService: TimerService, private userService: UserService) { }

    ngOnInit() {
        this.initTimekeeper();
        this.timerService.timers.pipe(takeUntil(this._subscribedSubjects$), tap((_) => {
            this.getCalendar();
        })).subscribe();
    }

    private initTimekeeper() {
        forkJoin([this.getListOfStaff(), this.getAttorneyEes()]).pipe(map(([staff, ees]) => {
            const eeIdList: Array<number> = [];
            staff.forEach(s => eeIdList.push(s.validValue as number));
            this.timekeepers = ees.filter(ee => eeIdList.includes(ee.eeId));

            const eeDetails = this.userService.userSnapshot?.eeInfo;
            this.currentTimekeeper = this.timekeepers.find(tk => tk.eeId === eeDetails.eeId);
        }), tap(_ => {
            this.getCalendar();
        })).subscribe();
    }

    private getListOfStaff = (): Observable<Array<FormFieldListItem>> => this.lookupService.get('Staff', 'ComboName').pipe(map((items) => items));

    private getAttorneyEes(): Observable<Array<IEe>> {
        const params = {
            $filter: ODataFilterBuilder().eq('EeStatus', 'ACTIVE').toString()
        } as ParamObj;
        return this.eeService.get(params).pipe(map((ees) => ees));
    }

    public changeWeek(type: string) {
        this.isLoading = true;
        let newWeek;
        let newYear;
        let newStart;
        let newEnd;
        if (type === 'previous') {
            newWeek = this.week === 1 ? LUXON_DAY_MONTH_NUMBER_ADJ : this.week;
            newYear = this.week === 1 ? this.year - 1 : this.year;
            newStart = DateTime.fromObject({
                weekYear: newYear,
                weekNumber: newWeek - 1
            }).minus({ day: 1 });
            newEnd = DateTime.fromObject({
                weekYear: newYear,
                weekNumber: newWeek - 1
            })
                .minus({ day: 1 })
                .plus({ day: 6 });
        } else if (type === 'next') {
            newWeek = this.week === LUXON_DAY_MONTH_NUMBER ? 0 : this.week;
            newYear =
                this.week === LUXON_DAY_MONTH_NUMBER
                    ? this.year + 1
                    : this.year;
            newStart = DateTime.fromObject({
                weekYear: newYear,
                weekNumber: newWeek + 1
            }).minus({ day: 1 });
            newEnd = DateTime.fromObject({
                weekYear: newYear,
                weekNumber: newWeek + 1
            })
                .minus({ day: 1 })
                .plus({ day: 6 });
        } else {
            newStart = DateTime.fromObject({
                weekYear: DateTime.local().weekYear,
                weekNumber: DateTime.local().weekNumber
            }).minus({ day: 1 });
            newEnd = DateTime.fromObject({
                weekYear: DateTime.local().weekYear,
                weekNumber: DateTime.local().weekNumber
            })
                .minus({ day: 1 })
                .plus({ day: 6 });
        }

        const newDateObj = {
            startDate: newStart.toFormat('MM/dd/yyyy'),
            endDate: newEnd.toFormat('MM/dd/yyyy'),
            week: newEnd.weekNumber,
            year: newEnd.weekYear
        };

        if (newDateObj.startDate) this.startDate = newDateObj.startDate;
        if (newDateObj.endDate) this.endDate = newDateObj.endDate;
        if (newDateObj.week) this.week = newDateObj.week;

        this.getCalendar();
    }

    private getCalendar() {
        this.isLoading = true;
        this.weekObj = [];

        const momentDays = [
            MOMENTDAY_ONE,
            MOMENTDAY_TWO,
            MOMENTDAY_THREE,
            MOMENTDAY_FOUR,
            MOMENTDAY_FIVE,
            MOMENTDAY_SIX,
            MOMENTDAY_SEVEN
        ];
        momentDays.forEach((day) => {
            const luxonDay = (day + MOMENTDAY_SEVEN) % LUXON_WEEK_CALC_NUMBER;
            const dayTitle = this.getWeekdayTitle(luxonDay);
            const dateShort =
                luxonDay === MOMENTDAY_SEVEN
                    ? this.matterTimeService.buildLuxonDate(
                        LUXON_WEEK_CALC_NUMBER,
                        this.week - 1,
                        this.year,
                        'MM/dd'
                    )
                    : this.matterTimeService.buildLuxonDate(
                        day,
                        this.week,
                        this.year,
                        'MM/dd'
                    );
            const dateLong =
                luxonDay === MOMENTDAY_SEVEN
                    ? this.matterTimeService.buildLuxonDate(
                        LUXON_WEEK_CALC_NUMBER,
                        this.week - 1,
                        this.year,
                        'yyyy-MM-dd\'T\'00:00:00'
                    )
                    : this.matterTimeService.buildLuxonDate(
                        day,
                        this.week,
                        this.year,
                        'yyyy-MM-dd\'T\'00:00:00'
                    );
            this.weekObj.push({
                day: dayTitle,
                date: dateShort,
                longDate: dateLong,
                entries: [],
                totalTime: 0
            });
        });
        if (this.currentTimekeeper) {
            this.getTimeEntries();
        } else {
            this.isLoading = false;
        }
    }

    private getWeekdayTitle(day: number) {
        switch (day) {
            case LUXON_SUNDAY_WEEK_DAY:
                return 'Sun';
            case LUXON_MONDAY_WEEK_DAY:
                return 'Mon';
            case LUXON_TUESDAY_WEEK_DAY:
                return 'Tue';
            case LUXON_WEDNESDAY_WEEK_DAY:
                return 'Wed';
            case LUXON_THURSDAY_WEEK_DAY:
                return 'Thu';
            case LUXON_FRIDAY_WEEK_DAY:
                return 'Fri';
            case LUXON_SATURDAY_WEEK_DAY:
                return 'Sat';
            default:
                return 'UNKNOWN';
        }
    }

    public onTimekeeperChange($event: any) {
        this.isLoading = true;
        this.getCalendar();
    }

    private getTimeEntries() {
        this.isLoading = true;

        this.matterTimeService
            .getTimeByDateEE(
                this.currentTimekeeper.eeId,
                this.startDate,
                this.endDate
            )
            .pipe(
                map((entries) => {
                    entries.forEach((entry: TimeEntry) => {
                        const index = this.weekObj.findIndex((day: any) => {
                            return day.longDate === entry.entryDate;
                        });
                        if (index > -1) {
                            this.weekObj[index].entries.push(entry);
                        }
                    });

                    for (let index = 0; index < this.weekObj.length; index++) {
                        this.weekObj[index].totalTime = 0;
                        if (this.weekObj[index].entries.length) {
                            this.weekObj[index].totalTime =
                                this.matterTimeService
                                    .getTimeEntrySum(
                                        this.weekObj[index].entries
                                    )
                                    .toFixed(FIXED_DECIMAL_PLACE);
                        }
                    }
                }),
                tap((_) => {
                    this.isLoading = false;
                })
            )
            .subscribe();
    }

    public modifyTime(timeEntry: TimeEntry) {
        this.timerService.timers.pipe((take(1)), map((timers) => {
            const openTimer = timers.find(t => t.matterTimeId === timeEntry.timeId);
            if (openTimer) {
                this.timerService.openTimer = openTimer;
            } else {
                const eeId = this.userService.userSnapshot?.eeInfo.eeId;

                this.timerService.openTimer = this.timerService.spawnTimer(
                    null,
                    null,
                    null,
                    eeId,
                    false,
                    false
                );
            }
        }), tap((_) => {
            this.timeEntryEditComponent.onEditTimeEntryClicked(timeEntry.timeId, timeEntry.matterId);
        })).subscribe();
    }

    public savedTimeEntry($event: any) {
        this.getCalendar();
    }

    ngOnDestroy(): void {
        this._subscribedSubjects$.next(true);
        this._subscribedSubjects$.complete();
    }
}
