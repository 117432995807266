<p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
<div class="shadow table-border" >
  <p-table [value]="documents | async" dataKey="id" #dt [globalFilterFields]="['name']" [resizableColumns]="true" responsiveLayout="scroll" [loading]="loading" [rowHover]="true">
    <ng-template pTemplate="caption">
      <div class="d-flex justify-content-between flex-wrap">
        <div><h2>{{currentCategory?.name | titlecase}}</h2></div>
        <div class="d-flex justify-content-between align-items-center flex-wrap">
          <span class="p-input-icon-left ml-auto me-4 pb-2">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="search($event, dt)" placeholder="Search keyword">
          </span>
          <button pButton (click)="showUploadDialog()" label="Documents" icon="pi pi-plus"></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pResizableColumn [width]="60"></th>
        <th pSortableColumn="name" pResizableColumn><span style="margin-left: 4px;">Document</span><p-sortIcon field="name"></p-sortIcon></th>
        <th pSortableColumn="createDate" pResizableColumn>Upload Date <p-sortIcon field="createDate"></p-sortIcon></th>
        <th pSortableColumn="uploadBy" pResizableColumn>Upload By <p-sortIcon field="createDate"></p-sortIcon></th>
        <th pSortableColumn="status" pResizableColumn>Status <p-sortIcon field="status"></p-sortIcon></th>
        <th pResizableColumn></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-document>
      <tr pDraggable="dnd" pDroppable="dnd" (onDragStart)="dragStart($event, document.id, document.name)"  (onDragEnd)="onDragEnd()" (drop)="uploadInit($event)" (onDrop)="mergeFiles($event, document.id)" [ngClass]="{'dragged-row': draggedDocumentId == document.id, 'data-row': true, 'dragging': draggedDocumentId != null}">
        <td>
          <a [routerLink]="" (click)="downloadDocument(document.id)" target="_blank">
            <button type="button" pTooltip="Download" pButton pRipple class="p-button-text p-button-rounded p-button-plain p-mr-2"
              [icon]="'pi pi-cloud-download'">
            </button>
          </a>
        </td>
        <td>
          <i *ngIf="document.comment" tooltipPosition="top" pTooltip="{{document.comment}}" class="bi bi-chat-right-text" style="color:#1cac51; margin-left: -16px;"></i>
          <i *ngIf="!document.comment" style="margin-left: 4px; overflow: hidden; text-overflow: ellipsis; max-width: 40ch;"></i>
          {{document.name}}
        </td>
        <td>{{document.createDate | date}}</td>
        <td>{{document.createdBy.firstName + " " + document.createdBy.lastName}}</td>
        <td>
          <app-document-status [editable]="allowStatusChange" [status]="document.status" (selectionChange)="updateStatus($event, document)"></app-document-status>
        </td>
        <td style="width: 180px">
          <app-document-category [categories]="categories" [category]="currentCategory" (selectionChange)="updateCategory($event, document)"></app-document-category>
          <button pTooltip="Edit" class="btn edit" (click)="showDocumentDetails(document)">
            <i class="bi bi-pencil-square"></i>
          </button>
          <button *ngIf="userService.getRole() != UserRole.ExternalUser" pTooltip="Delete" class="btn delete" (click)="deleteDocument(document.id)">
            <i class="bi bi-trash3-fill"></i>
          </button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer" class="text-center">
      <tr>
        <td colspan="6">
          <div class="text-center my-2" pDraggable="dnd" pDroppable="dnd" (drop)="uploadInit($event)">
            <i class="pi pi-upload d-flex justify-content-center" style="font-size: 3rem; color: lightgray;"></i>
            <span class="d-flex justify-content-center">Drop documents here to upload.</span>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog header="Delete Document" [(visible)]="showConfirmModal" [modal]="true" [style]="{width: '400px'}"
  [baseZIndex]="5000" [draggable]="false" [resizable]="false">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column">
      <div class="text-center">
        <p>Are you sure you want to delete this document?<br>This document will be deleted immediately. You can't undo
          this action.</p>
      </div>
    </div>
  </ng-template>

  <ng-template let-message pTemplate="footer">
    <button pButton type="button" (click)="onCancelDelete()" label="Cancel" class="p-button-primary p-button-text"></button>
    <button pButton type="button" (click)="onConfirmDelete()" label="Delete" class="p-button-danger p-button-text"></button>
  </ng-template>
</p-dialog>
<p-confirmDialog key="createVersion" header="Create Version" icon="pi pi-exclamation-triangle"></p-confirmDialog>
