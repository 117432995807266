<div class="timer-button-container">
    <p-button *ngIf="activeTimers?.length" class="active-timers-btn" (click)="showActiveTimers($event)"
        pTooltip="Show other active timers" tooltipPosition="top">
        <i class="pi pi-stopwatch"></i>
    </p-button>

    <div class="timer-stack" *ngIf="pinnedTimer" (click)="openPopup($event, pinnedTimer)">
        <div class="ref">{{ pinnedTimer.matterRef }}</div>
        <span>
            <span class="material-icons pinned me-1">push_pin</span>
            <span class="timer">{{ pinnedTimer.run_time | time }}</span>
        </span>
    </div>

    <div *ngIf="show_buttons" class="button-stack">
        <button *ngIf="!pinnedTimer; else pinnedOpts" (click)="openPopup($event, null, true)"
            class="btn btn-primary btn-block mt-4" type="button"><i class="bi bi-stopwatch-fill"></i>
            {{label}}
        </button>
        <ng-template #pinnedOpts>
            <ng-container *ngIf="!pinnedTimer.isRunning; else runningOpts">
                <p-button class="stacked-button" (click)="resumePinnedTimer()">
                    Resume
                </p-button>
                <p-button class="stacked-button" (click)="openPopup($event, null, true)">
                    New Timer
                </p-button>
            </ng-container>
            <ng-template #runningOpts>
                <p-button *ngIf="pinnedTimer.is_cancellable; else stopOpt" (click)="cancelPinnedTimer()">
                    Cancel
                </p-button>
                <ng-template #stopOpt>
                    <p-button (click)="stopPinnedTimer()">Stop</p-button>
                </ng-template>
            </ng-template>
        </ng-template>
    </div>
</div>
<div class="active-timers">
    <p-overlayPanel #activeDialog>
        <p-table [value]="activeTimers" class="active-grid" styleClass="p-datatable-gridlines">
            <ng-template pTemplate="header">
                <tr class="active-grid-header">
                    <th class="centered">
                        <span class="material-icons pinned"> push_pin </span>
                    </th>
                    <th>Matter</th>
                    <th>Time</th>
                    <th>&nbsp;</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-timer>
                <tr class="active-grid-row">
                    <td class="centered">
                        <p-button *ngIf="timer !== pinnedTimer; else pinned" class="grid-button inverted"
                            (click)="pinTimer(timer)">
                            <span class="material-icons">push_pin</span>
                        </p-button>
                        <ng-template #pinned>
                            <span class="material-icons green rotate">
                                push_pin
                            </span>
                        </ng-template>
                    </td>
                    <td>{{ timer.matterRef }}</td>
                    <td>
                        {{
                        (timer.isRunning
                        ? timer.run_time
                        : timer.accruedTime
                        ) | time
                        }}
                    </td>
                    <td class="centered">
                        <ng-container *ngIf="timer.isRunning; else startBtn">
                            <p-button *ngIf="
                                    !timer.is_cancellable;
                                    else cancelGridBtn
                                " class="grid-button" (click)="stopTimer(timer)">
                                <span class="material-icons">stop</span>
                            </p-button>
                            <ng-template #cancelGridBtn>
                                <p-button class="grid-button" (click)="cancelTimer(timer)">
                                    <span class="material-icons"> delete </span>
                                </p-button>
                            </ng-template>
                        </ng-container>
                        <ng-template #startBtn>
                            <p-button class="grid-button" (click)="resumeTimer(timer)">
                                <span class="material-icons"> play_arrow </span>
                            </p-button>
                        </ng-template>
                        <p-button class="grid-button text" (click)="openPopup($event, timer)">view</p-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-overlayPanel>
</div>
<div>
    <app-time-entry-popup #timeEntryComponent container=".timer-container"
        [showCreateButton]="false"></app-time-entry-popup>
    <app-time-entry-popup #timeEntryEditComponent container=".timer-container" [isEdit]="true"
        [showCreateButton]="false"></app-time-entry-popup>
</div>