import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForbiddenComponent } from './modules/core/pages/error-pages/forbidden/forbidden.component';
import { NotFoundComponent } from './modules/core/pages/error-pages/not-found/not-found.component';
import { UnauthorizedComponent } from './modules/core/pages/error-pages/unauthorized/unauthorized.component';
import { SimplePageLayoutComponent } from './modules/core/layouts/simple-page-layout/simple-page-layout.component';
import { AppLayoutComponent } from './modules/core/layouts/app-layout/app-layout.component';
import { AuthGuard } from './modules/core/guards/auth.guard';
import { AdminGuard } from './modules/core/guards/admin.guard';
import { DashboardComponent } from './modules/core/pages/dashboard/dashboard.component';
import { ContactDetailsComponent } from './modules/core/pages/contact-details/contact-details.component';
import { ContactsComponent } from './modules/core/pages/contacts/contacts.component';
import { MattersComponent } from './modules/core/pages/matters/matters.component';
import { MattersDetailsComponent } from './modules/core/pages/matters-details/matters-details.component';
import { TimekeepingComponent } from './modules/core/pages/timekeeping/timekeeping.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
    },
    {
        path: 'app',
        component: AppLayoutComponent,
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'dashboard',
            },
            {
                path: 'dashboard',
                component: DashboardComponent,
            },
            {
                path: 'contacts',
                component: ContactsComponent,
            },
            {
                path: 'contact/:contactId',
                component: ContactDetailsComponent,
            },
            {
                path: 'matters',
                component: MattersComponent,
            },
            {
                path: 'matter/:matterId',
                component: MattersDetailsComponent,
            },
            {
                path: 'timekeeping',
                component: TimekeepingComponent,
            },
            {
                path: 'user',
                loadChildren: () =>
                    import('./modules/user/user.module').then((m) => m.UserModule),
            },
            {
                path: 'admin',
                canLoad: [AdminGuard],
                loadChildren: () =>
                    import('./modules/admin/admin.module').then((m) => m.AdminModule),
            },
        ],
    },
    {
        path: '',
        component: SimplePageLayoutComponent,
        children: [
            {
                path: 'not-found',
                component: NotFoundComponent,
            },
            {
                path: 'forbidden',
                component: ForbiddenComponent,
            },
            {
                path: 'unauthorized',
                component: UnauthorizedComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
