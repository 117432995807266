<div class="row">
    <div class="d-none d-sm-block col-sm">
        <button class="btn btn-primary" type="button" (click)="popupButtonClicked()"><i class="pi pi-user"></i>
            {{buttonText}}</button>
    </div>

    <div class="d-xs-block d-sm-none col-xs">
        <div *ngIf="!mobileSectionVisible">
            <button class="btn btn-primary" type="button" (click)="popupButtonClicked()"><i
                    class="pi pi-user"></i>
                {{buttonText}}</button>
        </div>
        <div *ngIf="mobileSectionVisible">
            <div class="d-flex flex-row-reverse">
                <button (click)="mobileSectionVisible = false" class="btn btn-secondary" type="button"><i
                        class="pi pi-times"></i></button>
            </div>
            <hr>

            <app-responsible-attorney-view-change-history (processCompleted)="isProcessCompleted($event)"></app-responsible-attorney-view-change-history>
        </div>
    </div>
</div>

<dx-popup [showTitle]="true" [title]="buttonText" [dragEnabled]="true" [hideOnOutsideClick]="false" [showCloseButton]="true"
    [container]="container" [(visible)]="popupVisible">
    <div *dxTemplate="let data of 'content'">
        <app-responsible-attorney-view-change-history (processCompleted)="isProcessCompleted($event)"></app-responsible-attorney-view-change-history>
    </div>
</dx-popup>