import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import {
    ApiResponseType,
    IApiDataResponse,
    IApiResponse,
} from 'src/app/modules/core/models/api-response.interface';
import { IUser, User } from 'src/app/modules/core/models/user';
import { ToastService } from 'src/app/modules/core/services/toast/toast.service';
import { UserApiService } from 'src/app/modules/core/services/user-api/user-api.service';

@Injectable()
export class UserManagementService {
    private _users: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);

    get users(): User[] {
        return this._users.value;
    }

    get users$(): Observable<User[]> {
        return this._users.asObservable();
    }

    constructor(private api: UserApiService, private router: Router, private toast: ToastService) {}

    public getUsers(): Observable<IApiDataResponse<User[]>> {
        return this.api.getUsers().pipe(
            map((response: IApiDataResponse<IUser[]>) => {
                if (response?.type === ApiResponseType.Success) {
                    return { type: response.type, message: response.message, data: response.data.map(iUser => new User(iUser)) };
                }

                return response as IApiDataResponse<User[]>;
            }),
            tap((response: IApiDataResponse<User[]>) => {
                this._users.next(response.data);
            }),
        );
    }

    public createUser() {
        this.router.navigateByUrl('app/user/admin/add');
    }

    public editUser(id: string) {
        this.router.navigateByUrl(`app/user/admin/${id}/edit`);
    }

    public deleteUser(id: string): Observable<boolean> {
        return this.api.deleteUser(id).pipe(
            map((response: IApiResponse) => {
                this.toast.success(response.message);
                return true;
            })
        );
    }

    public sendUserRegistrationEmail(id: string): Observable<boolean> {
        return this.api.sendUserRegistrationEmail(id).pipe(
            map((response: IApiResponse) => {
                this.toast.success(response.message);
                return true;
            })
        );
    }

    public forceResetPassword(id: string): Observable<boolean> {
        return this.api.forceResetPassword(id).pipe(
            map((response: IApiResponse) => {
                this.toast.success(response.message);
                return true;
            })
        );
    }
}
