import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { UserEditorComponent } from './user-editor/user-editor.component';
import { TruncatePipe } from './truncate.pipe';
import { TimePipe } from './time.pipe';

@NgModule({
    declarations: [UserEditorComponent, TruncatePipe, TimePipe],
    providers: [],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        InputMaskModule,
        FileUploadModule,
        ProgressSpinnerModule,
    ],
    exports: [UserEditorComponent, TruncatePipe, TimePipe]
})
export class SharedModule {}
