import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { IApiDataResponse, IApiResponse } from 'src/app/modules/core/models/api-response.interface';
import { CategoryGroup, Document, ICategoryGroup, IDocumentCategory } from 'src/app/modules/core/models/document';
import { DocumentApiService } from 'src/app/modules/core/services/document-api/document-api.service';
import { IProperty } from '../../models/property';
import { PropertyApiService } from '../../services/property-api/property-api.service';
import { ToastService } from '../../services/toast/toast.service';

@Injectable()
export class DocumentsByPropertyService {
    private _categoryGroups: BehaviorSubject<CategoryGroup[]> = new BehaviorSubject<CategoryGroup[]>([]);

    get categoryGroups$(): Observable<CategoryGroup[]> {
        return this._categoryGroups.asObservable();
    }
    constructor(private api: DocumentApiService, private toast: ToastService, private propertyApi: PropertyApiService) { }  

    public getPropertyCategories(propertyId: string): Observable<IDocumentCategory[]>{
        return this.api.getPropertyCategories(propertyId).pipe(
            map((response: IApiDataResponse<IDocumentCategory[]>) => {
                return response.data;
            })
        );
    }

    public moveDocument(newCategory: IDocumentCategory, document: Document): Observable<boolean> {
        document.categoryId = newCategory.id;
        return this.api.updateDocument(document).pipe(
            map(_ => {
                this.toast.success(`Moved to ${newCategory.name}`);
                return true;
            })
        );  
    }
  
    public getPropertyById(id: string): Observable<IApiDataResponse<IProperty>> {
        return this.propertyApi.getPropertyById(id);
    }
  
    public getPropertyDocuments(propertyId: string){
        return this.api.getPropertyDocuments(propertyId).pipe(
            map((response: IApiDataResponse<ICategoryGroup[]>) => {
                this._categoryGroups.next(response.data.map(r =>  new CategoryGroup(r)));
            })
        );
    }

    public updateCategoryStatus(categoryId: string, newStatus: string) {
        return this.propertyApi.updatePropertyCategoryStatus({categoryId, newStatus}).pipe(
            map((response: IApiResponse) => {
                this.toast.success(response.message);
                return true;
            })
        );
    }

    public deleteDocument(documentId: string): Observable<boolean> {
        return this.api.deleteDocument(documentId).pipe(
            map((response: IApiResponse) => {
                this.toast.success(response.message);
                return true;
            })
        );
    }
}
