
<div>
    <div>
        <input class="w-100" type="text" pInputText placeholder="Search Contacts..." [(ngModel)]="searchText" (keyup)="onSearchChanged($event)">
        <p class="mt-2">Start searching to populate grid below...</p>
    </div>
    <dx-data-grid #contactsGrid [dataSource]="contactsDataSource" [remoteOperations]="false"
        [allowColumnReordering]="true" [hoverStateEnabled]="true" [showBorders]="true" (onRowClick)="onRowClick($event)" (onToolbarPreparing)="onToolbarPreparing($event)">
        <dxo-paging [pageSize]="5"> </dxo-paging>
        <dxo-pager [visible]="true" [allowedPageSizes]="[5]" displayMode="full" [showPageSizeSelector]="false"
            [showInfo]="true" [showNavigationButtons]="true">
        </dxo-pager>
        <dxi-column *ngIf="searchType !== 'company'" dataField="firstName" caption="First Name"></dxi-column>
        <dxi-column *ngIf="searchType !== 'company'" dataField="lastName" caption="Last Name"></dxi-column>
        <dxi-column dataField="companyName" caption="Company Name"></dxi-column>
        <dxi-column dataField="email" caption="Email"></dxi-column>
    </dx-data-grid>
</div>