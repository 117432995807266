import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MatterResponsibleChangeRequest, MatterResponsibleReturnMessage } from '../../models/matter-responsible-attorney.interface';


@Injectable({
    providedIn: 'root'
})
export class MatterResponsibleService {
    // BehaviorSubject to emit matter info when the button from the popup component is clicked
    private loadModuleDataSubject = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient, private userService: UserService) { }

    /**
     * Subscribed on the responsible-attorney-view-change-history to get data on button click.
     * Observable to subscribe to events for getting emitted button click.
     */
    public loadModuleData$ = this.loadModuleDataSubject.asObservable();
    /**
     * Load Data for responsible-attorney-view-change-history.
     * Method to trigger and emit matter info and load the data on the internal component.
     */
    triggerLoadModuleData() {
        this.loadModuleDataSubject.next(true);
    }

    /**
     * Will make changes to matter responsibles tied to the current matter and Return a message
     */
    public processMatterResponsibleAttorneyChanges(request: MatterResponsibleChangeRequest): Observable<MatterResponsibleReturnMessage> {
        return this.http.post<MatterResponsibleReturnMessage>(`${environment.apiBaseURL}Matter/ProcessMatterResponsibleAttorneyChanges`, request).pipe(map((result) => result));
    }
}
