export class DataMapping
{
    id: string;
    name: string;
    headerRowIndex: number | null;
    columns: MappingColumn[];

    constructor(init?: Partial<IDataMapping>) {
        if (init?.id) this.id = init.id;
        this.name = init?.name ?? null;
        this.headerRowIndex = init?.headerRowIndex ?? null;
        if (init?.columns) {
            this.columns = JSON.parse(init.columns);
        } else {
            this.columns = [];
        }
    }
}
export interface IDataMapping
{
    id?: string;
    name: string;
    headerRowIndex: number | null;
    columns: string;
}

export class MappingColumn {
    name: string;
    mappedTo: string;
    required: boolean;
}