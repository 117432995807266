<div class="mb-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page"><i class="bi bi-briefcase-fill"></i> Matters</li>
        </ol>
    </nav>
</div>
<div class="mb-2">
    <div class="row">
        <div class="col">
            <p-dropdown [options]="matterTypeOptions" (onChange)="onMatterTypeChange($event)"
                [(ngModel)]="selectedMatterTypeOption" optionLabel="Type"></p-dropdown>
        </div>
        <div class="col"></div>
        <div class="col d-flex justify-content-end">
            <button class="btn btn-primary" (click)="newMatter()">+ Add New Matter</button>
        </div>
    </div>
</div>
<div>
    <dx-data-grid #mattersGrid [dataSource]="mattersDataSource" [allowColumnReordering]="true"
        [hoverStateEnabled]="true" [showBorders]="true" (onRowClick)="onRowClick($event)"
        (onToolbarPreparing)="onToolbarPreparing($event)" [remoteOperations]="true">
        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search Matters"></dxo-search-panel>
        <dxo-paging [pageSize]="20"> </dxo-paging>
        <dxo-pager [visible]="true" [allowedPageSizes]="[20, 50]" displayMode="full" [showPageSizeSelector]="true"
            [showInfo]="true" [showNavigationButtons]="true">
        </dxo-pager>
        <dxi-column dataField="matter" caption="Matter#"></dxi-column>
        <dxi-column dataField="clientName" caption="Client Name"></dxi-column>
        <dxi-column dataField="matterTitle" [width]="500" caption="Matter Title"></dxi-column>
        <dxi-column dataField="matterType" caption="Matter Type"></dxi-column>
        <dxi-column dataField="phase" caption="Phase"></dxi-column>
        <dxi-column dataField="responsibleAttny" caption="Responsible"></dxi-column>

        <dxi-column dataField="application" [visible]="false"></dxi-column>
        <dxi-column dataField="patentNum" [visible]="false"></dxi-column>
        <dxi-column dataField="registrationNum" [visible]="false"></dxi-column>

        <dxi-column dataField="modifiedDate" [visible]="false" sortOrder="desc"></dxi-column>
    </dx-data-grid>
</div>