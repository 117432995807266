import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { ParamObj, ParamQuery } from '../../models/param-query.interface';
import { map, Observable } from 'rxjs';
import { ActionOdataResult } from '../../models/action-result.interface';
import { environment } from 'src/environments/environment';
import { IEe } from '../../models/ee';
import { AuthService } from '@core.services/auth/auth.service';
import { ToastService } from '@core.services/toast/toast.service';


@Injectable({
    providedIn: 'root'
})
export class EeService {
    private USER_API = `${environment.apiBaseURL}VAUser`;

    constructor(private http: HttpClient, private userService: UserService, private authService: AuthService, private toastService: ToastService) { }

    /**
     * Returns an array of Ees based on paramQuery
     */
    public get(params?: ParamObj): Observable<Array<IEe>> {
        const paramQuery: ParamQuery = (params) ? { params: params } : null;
        return this.getEes(paramQuery).pipe(map((result) => result.value as Array<IEe>));
    }

    private getEes(paramQuery: ParamQuery): Observable<ActionOdataResult> {
        const httpParams = (paramQuery) ? paramQuery : {};
        return this.http.get<ActionOdataResult>(`${environment.apiBaseURL}odata/Ees`, httpParams);
    }
}
