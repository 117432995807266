import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatterResponsibleAttorney, MatterResponsibleChangeRequest } from '../../models/matter-responsible-attorney.interface';
import { MatterResponsibleService } from '../../services/matter/matter-responsible.service';

@Component({
    selector: 'app-responsible-attorney-view-change-history-popup',
    templateUrl: './responsible-attorney-view-change-history-popup.component.html',
    styleUrls: ['./responsible-attorney-view-change-history-popup.component.scss']
})
export class ResponsibleAttorneyViewChangeHistoryPopupComponent {
    popupVisible = false;
    mobileSectionVisible = false;
    @Input() searchType = 'staff'; //staff, referral, company
    @Input() buttonText = 'View / Change History';
    @Input() closeOnAttorneyClicked = true;
    closeButtonOptions: any;
    @Input() container: string = '.form-container';
    @Output() processCompleted = new EventEmitter<MatterResponsibleChangeRequest>();

    constructor(private matterResponsibleService: MatterResponsibleService) { }

    isProcessCompleted(request: MatterResponsibleChangeRequest) {
        if (this.closeOnAttorneyClicked) {
            this.popupVisible = false;
            this.mobileSectionVisible = false;
        }
        this.processCompleted.emit(request);
    }

    popupButtonClicked() {
        this.popupVisible = true;
        this.matterResponsibleService.triggerLoadModuleData();
    }
}
