import { Component, OnInit, ViewChild } from '@angular/core';
import { DXODataService } from '../../services/devextreme/dx-odata.service';
import DataSource from 'devextreme/data/data_source';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';

export class contactType {
    Id: number;
    Type: string;
}

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  @ViewChild('contactsGrid', { static: false }) contactsGrid: DxDataGridComponent;
  public contactTypeOptions: Array<contactType> = [
      { Id: 0, Type: 'Active Contacts' },
      { Id: 1, Type: 'Archived Contacts' },
      { Id: 2, Type: 'All Contacts' }
  ];
  public selectedContactTypeOption: contactType = null;
  public contactsDataSource: DataSource = null;

  constructor(private dxOdataService: DXODataService, private router: Router) {
      this.selectedContactTypeOption = this.contactTypeOptions[0];
  }

  public ngOnInit(): void {
      this.getContacts();
  }

  public onToolbarPreparing(event: any) {
      const toolbarItems = event.toolbarOptions.items;
      toolbarItems.forEach((item: any) => {                    
          if (item.name === 'searchPanel') {
              item.location = 'before';
          }
      });
  }

  private getContacts() {
      this.contactsDataSource = new DataSource({
          store: this.dxOdataService.context.contacts,
          filter: ['archiveDate', '=', null]
      });
  }

  onContactTypeChange(event: any) {
      const contactId = event.value.Id;
      this.contactsDataSource.filter(this.getContactTypeFilter(contactId));
      this.contactsDataSource.load();
      this.contactsGrid.instance.refresh();
  }

  getContactTypeFilter(contactId: number): Array<any> {
      const filters: any[] = [];
      if (contactId === 0) {
          filters.push(
              ['archiveDate', '=', null]
          );
      } else if (contactId === 1) {
          filters.push(
              ['!', ['archiveDate', '=', null]]
          );
      } else {
      // Show all contacts, no filters
      }
      return (filters.length <= 0) ? null : filters;
  }

  public newContact() {
      this.router.navigate(['/app/contact/0']);
  }

  public onRowClick(event: any) {
      this.router.navigate(['/app/contact/', event.data.contactId]);
  }

}
