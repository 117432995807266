import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatterService } from '../../services/matter/matter.service';
import { forkJoin, map, Observable, tap } from 'rxjs';
import { MatterResponsibleAttorney, MatterResponsibleChangeRequest } from '../../models/matter-responsible-attorney.interface';
import { ActivatedRoute } from '@angular/router';
import { IEe } from '../../models/ee';
import ODataFilterBuilder from 'odata-filter-builder';
import { ParamObj } from '../../models/param-query.interface';
import { EeService } from '../../services/ee/ee.service';
import { MatterResponsibleService } from '../../services/matter/matter-responsible.service';
import { ToastService } from '../../services/toast/toast.service';
import { FormFieldListItem } from '../../models/form-field-list-item.interface';
import { FormFieldLookupService } from '../../services/lookup/form-field-lookup.service';
import { Matter } from '../../models/matter.interface';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { DateTime } = require('luxon');

@Component({
    selector: 'app-responsible-attorney-view-change-history',
    templateUrl: './responsible-attorney-view-change-history.component.html',
    styleUrls: ['./responsible-attorney-view-change-history.component.scss']
})
export class ResponsiblesAttorneyViewChangeHistoryComponent implements OnInit {
    public popupLoading = true;
    public currentErrorMessage: string = null;
    public showErrorMessage = false;

    public actionTypes: Array<any> = [
        { Id: 0, Type: 'Add' },
        { Id: 1, Type: 'Update' },
        { Id: 2, Type: 'Delete' }
    ];

    public matterResponsibleAttorneys: Array<MatterResponsibleAttorney> = null;
    public ees: Array<IEe> = [];
    public currentMatterId = 0;
    public currentMatter: Matter = null;

    public currentActionType: any = this.actionTypes[1];
    public responsibleAttorney: IEe = null;
    public startDate: Date = new Date();
    public pKey: number = null;

    @Output() processCompleted = new EventEmitter<MatterResponsibleChangeRequest>();

    constructor(private matterService: MatterService, private matterResponsibleService: MatterResponsibleService, private activatedRoute: ActivatedRoute, private eeService: EeService, private toast: ToastService, private lookupService: FormFieldLookupService) {}

    public ngOnInit(): void {
        this.currentMatterId = +this.activatedRoute.snapshot.paramMap.get('matterId');
        this.matterResponsibleService.loadModuleData$.subscribe((_) => this.getPopupData());
    }

    private getPopupData() {
        forkJoin([this.matterService.getMatterResponsibles(this.currentMatterId), this.getAttorneyEes(), this.getListOfAttorneys(), this.getMatter()]).pipe(map(([matterResposibles, ees, attorneys, matter]) => {
            const filteredAttorneyList: Array<number> = [];
            attorneys.forEach((attorney) => {
                filteredAttorneyList.push(+attorney.validValue);
            });
            this.ees = ees.filter(ee => filteredAttorneyList.includes(ee.eeId));
            this.matterResponsibleAttorneys = matterResposibles;
            this.currentMatter = matter;
            if(this.matterResponsibleAttorneys.length > 0) {
                this.responsibleAttorney = this.ees.find(ee => ee.eeRef === this.matterResponsibleAttorneys[0].responsible);
            }
        }), tap((_) => {
            this.popupLoading = false;
        })).subscribe();
    }

    private getMatter(): Observable<Matter> {
        const params = { $filter: ODataFilterBuilder().eq('MatterID', this.currentMatterId).toString() } as ParamObj;
        return this.matterService.get(params).pipe(map((ees) => ees[0]));
    }

    private getListOfAttorneys = (): Observable<Array<FormFieldListItem>> => this.lookupService.get('Staff', 'AttorneyFull').pipe(map((items) => items));

    private getAttorneyEes(): Observable<Array<IEe>> {
        const params = { $filter: ODataFilterBuilder().eq('IsAttorney', 'Y').toString() } as ParamObj;
        return this.eeService.get(params).pipe(map((ees) => ees));
    }

    public processRequest() {
        const matterResponsibleChangeRequest: MatterResponsibleChangeRequest = {
            action: this.currentActionType.Type,
            matterId: this.currentMatterId,
            newResp: this.responsibleAttorney.eeId,
            begin: DateTime.fromJSDate(this.startDate).toFormat('yyyy-MM-dd').toString(),
            pKey: (this.currentActionType.Type === 'Update' || this.currentActionType.Type === 'Delete') ? this.pKey : null
        };
        if(this.currentMatterId > 0) {
            this.matterResponsibleService.processMatterResponsibleAttorneyChanges(matterResponsibleChangeRequest).pipe(map((response) => {
                if(response.returnMessage.includes('Request successful')) {
                    this.toast.success(response.returnMessage);
                    this.processCompleted.emit(matterResponsibleChangeRequest);
                } else {
                    this.currentErrorMessage = response.returnMessage;
                    this.showErrorMessage = true;
                }
                
            })).subscribe();
        } else {
            this.toast.success('extra responsible attorney will be added after initial creation');
            this.processCompleted.emit(matterResponsibleChangeRequest);
        }
    }

    public onRowClick(event: any) {
        this.currentActionType = this.actionTypes[1];
        this.responsibleAttorney = this.ees.find(ee => ee.eeRef === event.data.responsible);
        this.startDate = new Date(event.data.startDate);
        this.pKey = event.data.pKey;
    }
}
