import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
    BaseTimer,
    DBTimer,
    millisToHours,
    Timer,
    TimerWithRun,
    timestamp
} from '@core.models/timer.model';
import { typedLocalStorage } from '@shared/typed-local-storage';
import { TimerDbService } from './timer-db.service';
import {
    BehaviorSubject,
    map,
    Observable,
    of
} from 'rxjs';
import { ToastService } from '@core.services/toast/toast.service';
import { UserService } from '@core.services/user/user.service';

const _1SECOND: number = 1000;
const LS_TIMERS_KEY: string = 'current_timers';

export interface TimerServiceConfig {
    allow_multiple: boolean;
}

@Injectable()
export class TimerService implements OnDestroy {
    public openTimer: TimerWithRun = new TimerWithRun();

    // current local storage timer array
    private localTimers: TimerWithRun[] = [];
    private pinnedTimer = new BehaviorSubject<TimerWithRun>(null);
    private watchedTimers = new BehaviorSubject<TimerWithRun[]>(
        this.localTimers
    );

    // current clock interval reference
    private runningInterval: any = null;

    // # of active (running) timers; will be 0 or 1 for allow_multiple = false
    private activeCnt: number = 0;

    // flag to allow multiple active (running) timers, set once. default is false if unset
    // should be accessed internally via getter, __allow_multiple only touched by get/set
    public get allowMultiple(): boolean {
        return !!this.config.allow_multiple;
    }

    // public ref for timers list, convenience for listing timers on component
    public get timers(): Observable<TimerWithRun[]> {
        return this.watchedTimers.asObservable();
    }

    public get pinned(): Observable<TimerWithRun> {
        return this.pinnedTimer.asObservable();
    }

    constructor(
        @Inject('timer_config') private config: TimerServiceConfig,
        private timer_db: TimerDbService,
        private toast: ToastService,
        private userService: UserService
    ) { }

    ngOnDestroy() {
        this.pinnedTimer.complete();
        this.watchedTimers.complete();
    }

    public load() {
        this.getTimers();
    }

    /**** TIMER MANAGEMENT ****************************************************************/

    public pinTimer(timer: TimerWithRun) {
        if (this.pinnedTimer.value) {
            this.pinnedTimer.value.pinned = false;
        }

        if (timer) {
            timer.pinned = true;
        }

        this.pinnedTimer.next(timer);
        this.saveState();
    }

    public spawnTimer(
        matterId: number,
        matterRef: string,
        matterTimeId: number,
        eeId: number,
        add: boolean = true,
        start: boolean = true
    ): TimerWithRun {
        const timer = new TimerWithRun({
            id: 0,
            eeId: eeId,
            matterId: matterId,
            matterRef: matterRef,
            matterTimeId: matterTimeId,
            accruedTime: 0,
            startTime: null,
            endTime: null,
            entryDesc: '',
            isRunning: false,
            saved: false,
            pinned: false
        });

        if (add) {
            this.localTimers.unshift(timer);
        }

        if (start) {
            this.startTimer(timer);
        } else {
            this.saveState();
        }

        return timer;
    }

    public addTimer(timer: TimerWithRun) {
        if (timer) {
            if (this.localTimers.indexOf(timer) < 0) {
                // push most recent timers to start of array
                this.localTimers.unshift(timer);
            }
        }
    }

    // put timer in a running (active) state
    public startTimer(timer: TimerWithRun, save: boolean = true) {
        if (timer && !timer.isRunning) {
            if (!this.allowMultiple) {
                this.deactivateTimers();
            }

            timer.startTime = timestamp();
            timer.endTime = null;
            timer.isRunning = true;

            this.activeCnt++;
            this.checkClock();

            if (save) {
                this.saveTimerToDb(timer);
            }
        }
    }

    // put timer in a stopped (!active) state
    public stopTimer(timer: TimerWithRun, save: boolean = true) {
        if (timer && timer.isRunning) {
            timer.endTime = timestamp();
            timer.accruedTime += timer.endTime - timer.startTime;
            timer.isRunning = false;

            this.activeCnt--;
            this.checkClock();

            if (save) {
                this.saveTimerToDb(timer);
            }
        }
    }

    public cancelTimer(timer: TimerWithRun) {
        if (timer && TimerWithRun.is_cancellable(timer)) {
            timer.isRunning = false;

            if ((this.pinnedTimer.value as Timer) === timer) {
                this.pinTimer(null);
            }

            if (timer.id) {
                this.deleteTimerFromDb(timer);
            }

            const timers = this.localTimers.filter(
                (t) => t.id === timer.id && t.matterId === timer.matterId
            );

            timers.forEach((t) => {
                const idx = this.localTimers.indexOf(t);
                this.localTimers.splice(idx, 1);
            });

            //If open timer, recreate as empty openTimer so that proper startTimer button shows on entryformpopup.
            if (this.openTimer) {
                const eeId = this.userService.userSnapshot?.eeInfo.eeId;

                this.openTimer = this.spawnTimer(
                    null,
                    null,
                    null,
                    eeId,
                    false,
                    false
                );
            }

            this.saveState();
        }
    }

    public getAccruedHours(timer: Timer): number {
        if (timer) {
            return millisToHours(timer.accruedTime);
        }

        return 0;
    }

    public saveTimer(
        timer: TimerWithRun,
        saveToDb: boolean
    ): Observable<TimerWithRun> {
        if (timer) {
            if (this.localTimers.indexOf(timer) < 0) {
                this.addTimer(timer);
            }

            if (saveToDb) {
                return this.saveTimerToDb(timer, true);
            }
        }

        return of(timer);
    }

    public flushTimers() {
        this.localTimers.length = 0;
        this.pinTimer(null);
        this.watchedTimers.next(this.localTimers);
        this.removeState();
    }

    // place all timers in a stopped (!isRunning) state
    private deactivateTimers() {
        this.localTimers.forEach((t) => {
            if (t.isRunning) {
                this.stopTimer(t);
            }
        });
    }

    private trySetPinnedTimer() {
        if (!this.pinnedTimer?.value) {
            // most recent first
            const sorted = this.localTimers.sort(
                (a, b) => b.startTime - a.startTime
            );

            // check for a pinned status
            const pinned = sorted.filter((t) => t.pinned);
            if (pinned?.length) {
                this.pinTimer(pinned[0]);

                for (let i = 1; i < pinned.length - 1; ++i) {
                    pinned[i].pinned = false;
                }
            } else {
                // if no pinned, check for active timers
                const active = sorted?.filter((t) => t.isRunning);

                if (active?.length) {
                    this.pinTimer(active[0]);
                } else if (sorted?.length) {
                    // else set the most recent timer as pinned
                    this.pinTimer(sorted[0]);
                }
            }
        }
    }

    /**** LOCAL STORAGE FUNCTIONS *********************************************************/

    // fetch timers from local storage
    private getTimers() {
        this.activeCnt = 0;
        this.fetchTimers().subscribe((timers) => {
            timers.forEach((t) => {
                if (t.isRunning) {
                    this.activeCnt++;
                }
            });
            this.mergeTimers(timers);
        });
    }

    private mergeTimers(timers: TimerWithRun[]) {
        const stored_timers =
            typedLocalStorage.fetch_array<Timer>(LS_TIMERS_KEY);

        if (stored_timers?.length) {
            timers.forEach((db_timer) => {

                for (let i = 0; i < stored_timers.length; ++i) {

                    const local_timer = stored_timers[i];

                    if (
                        local_timer.matterId === db_timer.matterId &&
                        local_timer.matterTimeId === db_timer.matterTimeId &&
                        local_timer.accruedTime === db_timer.accruedTime
                    ) {
                        // We have a viable DB record, we can discard the local
                        stored_timers.splice(i, 1);
                        --i;
                    }
                }
            });

            if (stored_timers.length) {
                // merge the timer arrays;
                const mapped_timers = stored_timers.map((t) => {
                    if (t.isRunning) this.activeCnt++;
                    return new TimerWithRun(t);
                });
                timers.push(...mapped_timers);
            }
        }

        this.localTimers = timers;
        this.trySetPinnedTimer();
        this.checkClock();
    }

    // update local storage with current local_timers snapshot
    private saveState() {
        typedLocalStorage.store(LS_TIMERS_KEY, this.localTimers as Timer[]);
        this.watchedTimers.next(this.localTimers);
    }

    private removeState() {
        typedLocalStorage.remove(LS_TIMERS_KEY);
    }

    /**** DATABASE FUNCTIONS **************************************************************/

    private fetchTimers(): Observable<TimerWithRun[]> {
        return this.timer_db.getTimers().pipe(map((timers) => timers?.map((dbTimer) => TimerWithRun.fromDBTimer(dbTimer))));
    }

    private saveTimerToDb(
        timer: TimerWithRun,
        save_state: boolean = true
    ): Observable<TimerWithRun> {
        const db_timer = new BaseTimer(timer).toDBTimer();
        const result$ = this.timer_db.saveTimer(db_timer).pipe(
            map((response) => {
                const type = 0;
                const timerObj = 1,
                    success = 1;
                const message = 2;
                let succeeded = false;

                if (response[type] === 'insert') {
                    const dbTimer = response[timerObj] as DBTimer;
                    if (dbTimer) {
                        timer.id = dbTimer.id;
                        succeeded = true;
                    }
                } else {
                    succeeded = response[success] as boolean;
                }

                timer.saved = succeeded;
                if (!succeeded) {
                    this.toast.error(
                        `Error saving timer: ${response[message]}`
                    );
                }

                if (save_state) {
                    this.saveState();
                }

                return timer;
            })
        );

        result$.subscribe();
        return result$;
    }

    public deleteTimer(timer: Timer) {
        this.deleteTimerFromDb(timer);

        const timers = this.localTimers.filter(
            (t) => t.id === timer.id && t.matterId === timer.matterId
        );

        timers.forEach((t) => {
            const idx = this.localTimers.indexOf(t);
            this.localTimers.splice(idx, 1);
        });

        if (this.openTimer.id === this.pinnedTimer.value.id) {
            this.pinTimer((this.localTimers.length > 0) ? this.localTimers[0] : null);
        }


        this.saveState();
    }

    private deleteTimerFromDb(timer: Timer) {
        if (timer?.id) {
            this.timer_db.deleteTimer(timer.id).subscribe((success) => {
                if (!success) {
                    this.toast.error('Error deleting timer from database');
                }
            });
        }
    }
    /**** RUNNING CLOCK FUNCTIONS *********************************************************/

    // determine if the 1-second clock should be started/stopped
    private checkClock() {
        if (this.localTimers && this.localTimers.length && this.activeCnt) {
            this.startClock();
        } else {
            this.stopClock();
        }
    }

    // start the 1-second clock to display 'running-time' of active timers
    private startClock() {
        if (!this.runningInterval) {
            this.runningInterval = setInterval(this.tick.bind(this), _1SECOND);
        }
    }

    // stop the 1-second clock
    private stopClock() {
        if (this.runningInterval) {
            clearInterval(this.runningInterval);
            this.runningInterval = null;
        }
    }

    // clock method to calculate the current (total)running time of the active timers.
    private tick() {
        const now = timestamp();

        this.localTimers.forEach((t) => {
            if (t.isRunning) {
                t.run_time = t.accruedTime + (now - t.startTime);
            }
        });
    }
}
