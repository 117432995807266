import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, take, timer } from 'rxjs';
import { AuthApiService } from '../auth-api/auth-api.service';

const ACCESS_TOKEN_KEY = 'access-token';
const ADMIN_TOKEN = 'admin-token';
const IS_EXTERNAL_AUTH = 'is-external-auth';
const IS_IMPERSONATING = 'is-impersonating';
const THIRTY_SECONDS = 30;

@Injectable({
    providedIn: 'root',
})
export class TokenService implements OnDestroy {
    private _timerSubscription: Subscription;

    storeToken(token: string): void {
        localStorage.setItem(ACCESS_TOKEN_KEY, token);
        this.configureTokenRefresh();
    }

    clearAuthLocalStorage(): void {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        localStorage.removeItem(IS_EXTERNAL_AUTH);
        localStorage.removeItem(ADMIN_TOKEN);
        localStorage.removeItem(IS_IMPERSONATING);
    }

    validToken(): boolean {
        const storedToken = localStorage.getItem(ACCESS_TOKEN_KEY);

        if (!storedToken) return false;
        const expDate = new Date(0);
        expDate.setUTCSeconds(this.decodedToken.exp);
        return expDate > new Date();
    }

    isExternalAuth(): boolean {
        const isExternalAuth: boolean = localStorage.getItem(IS_EXTERNAL_AUTH) === 'true';
        return !!isExternalAuth;
    }

    setExternalAuth() {
        localStorage.setItem(IS_EXTERNAL_AUTH, 'true');
    }

    isImpersonating(): boolean {
        const isImpersonating: boolean = localStorage.getItem(IS_IMPERSONATING) === 'true';
        return !!isImpersonating;
    }

    impersonate(token: string) {
        localStorage.setItem(ADMIN_TOKEN, localStorage.getItem(ACCESS_TOKEN_KEY));
        localStorage.setItem(ACCESS_TOKEN_KEY, token);
        localStorage.setItem(IS_IMPERSONATING, 'true');
    }

    stopImpersonating(){
        localStorage.setItem(ACCESS_TOKEN_KEY, localStorage.getItem(ADMIN_TOKEN));
        localStorage.removeItem(ADMIN_TOKEN);
        localStorage.removeItem(IS_IMPERSONATING);
    }

    get token(): string {
        return localStorage.getItem(ACCESS_TOKEN_KEY) || '';
    }

    get decodedToken(): any {
        const rawToken = localStorage.getItem(ACCESS_TOKEN_KEY);
        if (!rawToken) return null;
        const decodedToken = atob(rawToken.split('.')[1]);
        return JSON.parse(decodedToken);
    }

    constructor(private authApi: AuthApiService) {
        this.configureTokenRefresh();
    }

    ngOnDestroy() {
        if (this._timerSubscription) {
            this._timerSubscription.unsubscribe();
        }
    }

    private configureTokenRefresh(): void {
    // Unsubscribe from the timer because we no longer care about
    // the old stream of data.
        if (this._timerSubscription) this._timerSubscription.unsubscribe();

        // If there is no valid token remove any existing token
        if (!this.validToken()) {
            this.clearAuthLocalStorage();
            return;
        }

        const tokenExpirationDate = new Date(0);
        tokenExpirationDate.setUTCSeconds(this.decodedToken.exp);

        // Set the token refresh date to be 30 seconds before the token expiration date;
        let tokenRefreshDate = new Date(tokenExpirationDate);
        tokenRefreshDate.setSeconds(tokenRefreshDate.getSeconds() - THIRTY_SECONDS);

        // When executing, if the current date is in the 30 windows between the token refresh date and the
        // token expiration date - set the refresh date to be the current date time for immediate execution.
        const currentDatetime = new Date();
        if (
            tokenRefreshDate < currentDatetime &&
      currentDatetime < tokenExpirationDate
        )
            tokenRefreshDate = currentDatetime;

        // Start the timer which will fire on the refresh date and trigger the call
        // to refresh the user's token and subscribe.
        this._timerSubscription = timer(tokenRefreshDate).subscribe(() => {
            this.authApi.refreshToken().pipe(take(1)).subscribe( {
                next: (response) => {
                    const token = response.data;
                    this.storeToken(token);
                },
                error: (_)  => {
                    if (this._timerSubscription) this._timerSubscription.unsubscribe();
                    // In the event of an error, remove the existing token as it will expire in the next 30 seconds.
                    this.clearAuthLocalStorage();
                }
            });
        });
    }
}
