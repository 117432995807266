import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Contact } from '../../models/contact.interface';
import { ContactSearchResult } from '../../models/contact-search-result.interface';

@Component({
    selector: 'app-contact-search-popup',
    templateUrl: './contact-search-popup.component.html',
    styleUrls: ['./contact-search-popup.component.scss']
})
export class ContactSearchPopupComponent {
    popupVisible = false;
    mobileSectionVisible = false;
  @Input() searchType = 'staff'; //staff, referral, company
  @Input() buttonText = 'Link Contact';
  @Input() closeOnContactClicked = true;
  closeButtonOptions: any;
  @Input() container: string = '.form-container';
  @Output() clickedContact = new EventEmitter<ContactSearchResult>();

  constructor() {}

  returnedContact(contact: ContactSearchResult) {
      if(this.closeOnContactClicked) {
          this.popupVisible = false;
          this.mobileSectionVisible = false;
      }
      this.clickedContact.emit(contact);
  }
}
