import { Injectable } from '@angular/core';
import ODataContext from 'devextreme/data/odata/context';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { TokenService } from '../token/token.service';

@Injectable({
    providedIn: 'root'
})

export class DXODataService {
    private oDataContext: ODataContext;
    constructor(private authService: AuthService, private tokenService: TokenService) {
        const unauthorizedStatusCode = 401;
        this.oDataContext = new ODataContext({
            url: `${environment.apiBaseURL}odata`,
            version: 4,
            errorHandler(error) {
                if (error.httpStatus === unauthorizedStatusCode) {
                    authService.logout();
                }
            },
            beforeSend(options) {
                options.headers = {
                    Authorization: `Bearer ${tokenService.token}`
                };
            },
            entities: {
                aipRoles: {
                    key: 'roleId',
                    keyType: 'Int32'
                },
                contacts: {
                    key: 'contactId',
                    keyType: 'Int32'
                },
                matters: {
                    key: 'matterId',
                    keyType: 'Int32'
                },
                vwMatterListings: {
                    key: 'matterId',
                    keyType: 'Int32'
                },
                matterResponsibles: {
                    key: 'pkey',
                    keyType: 'Int32'
                }
            }
        });
    }

    get context(): any {
        return this.oDataContext;
    }
}
