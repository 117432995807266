import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './guards/auth.guard';
import { SimplePageLayoutComponent } from './layouts/simple-page-layout/simple-page-layout.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { RouterModule } from '@angular/router';
import { NotFoundComponent } from './pages/error-pages/not-found/not-found.component';
import { ForbiddenComponent } from './pages/error-pages/forbidden/forbidden.component';
import { UnauthorizedComponent } from './pages/error-pages/unauthorized/unauthorized.component';
import { SharedModule } from '../shared/shared.module';
import { ErrorCardComponent } from './components/error-card/error-card.component';
import { AuthAbstractService } from './services/auth/auth-abstract.service';
import { AuthService } from './services/auth/auth.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { LoginGuard } from './guards/login.guard';
import { AdminGuard } from './guards/admin.guard';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CalendarModule } from 'primeng/calendar';
import { TabMenuModule } from 'primeng/tabmenu';
import { AccordionModule } from 'primeng/accordion';
import { TagModule } from 'primeng/tag';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExternalAuthService } from './services/auth/external-auth.service';
import { UserManagementService } from 'src/app/modules/admin/users/services/user-management/user-management.service';
import { TableModule } from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';
import { DragDropModule } from 'primeng/dragdrop';
import { DocumentsByPropertyComponent } from './pages/documents-by-property/documents-by-property.component';
import { DocumentsByCategoryComponent } from './pages/documents-by-category/documents-by-category.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { FileSelectorComponent } from './components/file-selector/file-selector.component';
import { DividerModule } from 'primeng/divider';
import { UploadDocumentsComponent } from './components/upload-documents/upload-documents.component';
import { DocumentDetailsComponent } from './components/document-details/document-details.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TreeSelectModule } from 'primeng/treeselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NestedSelectorComponent } from './components/nested-selector/nested-selector.component';
import { TooltipModule } from 'primeng/tooltip';
import { ChipModule } from 'primeng/chip';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ToastModule } from 'primeng/toast';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { DocumentStatusComponent } from './components/document-status-dropdown/document-status.component';
import { CategoryStatusComponent } from './components/category-status-dropdown/category-status.component';
import { DocumentCategoryComponent } from './components/document-category-dropdown/document-category.component';
import { UploadProgressComponent } from './components/upload-progress/upload-progress.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { MattersComponent } from './pages/matters/matters.component';
import { TimekeepingComponent } from './pages/timekeeping/timekeeping.component';
import { ContactDetailsComponent } from './pages/contact-details/contact-details.component';
import { MattersDetailsComponent } from './pages/matters-details/matters-details.component';
import { DxModule } from '../dx.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { DXODataService } from './services/devextreme/dx-odata.service';
import { ContactSearchPopupComponent } from './components/contact-search-popup/contact-search-popup.component';
import { ContactSearchComponent } from './components/contact-search/contact-search.component';
import { ContactService } from './services/contact/contact.service';
import { MatterService } from './services/matter/matter.service';
import { ResponsibleAttorneyViewChangeHistoryPopupComponent } from './components/responsible-attorney-view-change-history-popup/responsible-attorney-view-change-history-popup.component';
import { ResponsiblesAttorneyViewChangeHistoryComponent } from './components/responsible-attorney-view-change-history/responsible-attorney-view-change-history.component';
import { MatterResponsibleService } from './services/matter/matter-responsible.service';
import { TimeEntryFormComponent } from './components/time-entry-form/time-entry-form.component';
import { TimeEntryPopupComponent } from './components/time-entry-popup/time-entry-popup.component';
import { TimerComponent } from './components/timer/timer.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TimerService } from '@core.services/timekeeping/timer.service';
import { TimerDbService } from '@core.services/timekeeping/timer-db.service';
import { MatterTimeService } from '@core.services/timekeeping/matter-time.service';
import { AIPRoleService } from '@core.services/aip-role/aip-role.service';

@NgModule({
    declarations: [
        SimplePageLayoutComponent,
        AppLayoutComponent,
        NotFoundComponent,
        ForbiddenComponent,
        UnauthorizedComponent,
        ErrorCardComponent,
        DocumentsByPropertyComponent,
        DocumentsByCategoryComponent,
        FileSelectorComponent,
        UploadDocumentsComponent,
        DocumentDetailsComponent,
        NestedSelectorComponent,
        DocumentStatusComponent,
        DocumentCategoryComponent,
        CategoryStatusComponent,
        UploadProgressComponent,
        DashboardComponent,
        ContactsComponent,
        MattersComponent,
        TimekeepingComponent,
        ContactDetailsComponent,
        MattersDetailsComponent,
        ContactSearchPopupComponent,
        ContactSearchComponent,
        ResponsibleAttorneyViewChangeHistoryPopupComponent,
        ResponsiblesAttorneyViewChangeHistoryComponent,
        TimeEntryFormComponent,
        TimeEntryPopupComponent,
        TimerComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        SharedModule,
        ButtonModule,
        BreadcrumbModule,
        DialogModule,
        ConfirmDialogModule,
        DropdownModule,
        FormsModule,
        TableModule,
        DragDropModule,
        FileUploadModule,
        AutoCompleteModule,
        InputTextModule,
        InputTextareaModule,
        CheckboxModule,
        DividerModule,
        TreeSelectModule,
        ToastModule,
        ProgressSpinnerModule,
        InputTextareaModule,
        InputSwitchModule,
        CalendarModule,
        TabMenuModule,
        TagModule,
        AccordionModule,
        TooltipModule,
        ChipModule,
        ConfirmPopupModule,
        ProgressBarModule,
        ReactiveFormsModule,
        DxModule,
        NgbDropdownModule,
        OverlayPanelModule
    ],
    providers: [
        AdminGuard,
        AuthGuard,
        LoginGuard,
        ExternalAuthService,
        UserManagementService,
        DXODataService,
        ContactService,
        MatterService,
        MatterResponsibleService,
        {
            provide: 'timer_config',
            useValue: { allow_multiple: true }
        },
        TimerService,
        TimerDbService,
        MatterTimeService,
        AIPRoleService,
        { provide: AuthAbstractService, useClass: AuthService },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ]
})
export class CoreModule { }
