import { AbstractControl, ValidationErrors } from '@angular/forms';

export function passwordMatch(
    control: AbstractControl
): ValidationErrors | null {
    const passwordControl = control.get('password');
    const confirmedPasswordControl = control.get('confirmedPassword');

    if ( passwordControl.enabled && confirmedPasswordControl.enabled &&
        (passwordControl?.dirty || passwordControl?.touched) &&
    (confirmedPasswordControl?.dirty || confirmedPasswordControl?.touched)
    ) {
        return passwordControl.value !== confirmedPasswordControl.value
            ? { passwordMatch: true }
            : null;
    }
    return null;
}
