<div *ngIf="isFormLoading">
    <div class="loader">
        <p-progressSpinner></p-progressSpinner>
    </div>
</div>
<div *ngIf="!isFormLoading" class="form-container">
    <div class="mb-5">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/app/contacts"><i class="bi bi-house-door-fill"></i>
                        Contacts</a>
                </li>
                <li *ngIf="currentContactId > 0; else newActive" class="breadcrumb-item active" aria-current="page">Edit
                </li>
                <ng-template #newActive>
                    <li class="breadcrumb-item active" aria-current="page">New</li>
                </ng-template>
            </ol>
        </nav>
    </div>
    <div class="mb-2 sticky-top form-header">
        <div class="row">
            <div class="col d-flex justify-content-start">
                <div *ngIf="currentContactId > 0 && isArchived" class="header-btn-spacing">
                    <button class="btn btn-secondary" (click)="activateContact()">Activate Contact</button>
                </div>
                <div *ngIf="currentContactId > 0" class="header-btn-spacing">
                    <button class="btn btn-primary" (click)="cloneContact()">Clone Contact</button>
                </div>
                <div *ngIf="currentContactId > 0" class="header-btn-spacing">
                    <button *ngIf="isArchived" class="btn btn-secondary" (click)="deleteContact()">Delete
                        Contact</button>
                    <div *ngIf="!isArchived">
                        <div ngbDropdown class="d-inline-block">
                            <button type="button" class="btn btn-secondary" id="dropdownBasic1" ngbDropdownToggle>
                                Archive Contact
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                <button ngbDropdownItem (click)="archiveContact()">Archive Contact</button>
                                <button ngbDropdownItem (click)="deleteContact()">Delete Contact</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col d-flex justify-content-end">
                <div *ngIf="currentContactId === 0" class="header-btn-spacing">
                    <button class="btn btn-primary" (click)="submitForm()" [disabled]="contactForm.invalid">Save
                        Contact</button>
                </div>
                <div *ngIf="currentContactId > 0" class="header-btn-spacing">
                    <button class="btn btn-primary" (click)="submitForm()" [disabled]="contactForm.invalid">Update
                        Contact</button>
                </div>
                <div>
                    <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    <div class="form-scroll">
        <form [formGroup]="contactForm" (ngSubmit)="submitForm()" style="margin-left: 5px">
            <div class="card">
                <div class="card-body row">
                    <div class="col-sm-12 col-md pb-2">
                        <div class="p-field">
                            <label class="pb-1" for="organization-type">Organization Type</label>
                            <p-dropdown id="organization-type" [options]="organizationTypes"
                                formControlName="organizationType" placeholder="Select an Organization Type..."
                                optionLabel="Type" [required]></p-dropdown>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md pb-2">
                        <div class="p-field">
                            <label class="pb-1" for="company-role">Company Role</label>
                            <p-dropdown id="company-role" [options]="companyRoles" formControlName="companyRole"
                                optionLabel="Type" placeholder="Select a Company Role..." [required]></p-dropdown>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md">
                        <div class="p-field">
                            <label class="pb-1" for="connected-staff">Client Relationship Manager</label>
                            <p-dropdown id="connected-staff" [options]="attorneys" formControlName="connectedStaff"
                                placeholder="Select a Related Client..." optionLabel="displayValue"
                                [required]></p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div
                *ngIf="contactForm.value.organizationType && contactForm.value.companyRole && contactForm.value.connectedStaff">
                <div class="row">
                    <div *ngIf="contactForm.value.organizationType.Type === 'Organization'" class="col mb-2">
                        <p-accordion [activeIndex]="0">
                            <p-accordionTab header="Contact Information">
                                <div class="row col">
                                    <div class="d-flex flex-column gap-1">
                                        <label for="org-name">Organization Name</label>
                                        <input pInputText id="org-name" formControlName="companyContact">
                                    </div>
                                </div>
                            </p-accordionTab>
                        </p-accordion>
                    </div>
                    <div *ngIf="contactForm.value.organizationType.Type !== 'Organization'" class="col">
                        <p-accordion [activeIndex]="0">
                            <p-accordionTab header="Contact Information">
                                <div class="row col">
                                    <div class="d-flex flex-column gap-1">
                                        <label for="contact-title">Title</label>
                                        <input pInputText id="contact-title" formControlName="contactTitle">
                                    </div>
                                </div>
                                <div class="row col pt-2">
                                    <div class="d-flex flex-column gap-1">
                                        <label for="first-name">First Name</label>
                                        <input pInputText id="first-name" formControlName="firstName">
                                    </div>
                                </div>
                                <div class="row col pt-2">
                                    <div class="d-flex flex-column gap-1">
                                        <label for="middle-name">Middle Name</label>
                                        <input pInputText id="middle-name" formControlName="middleName">
                                    </div>
                                </div>
                                <div class="row col pt-2">
                                    <div class="d-flex flex-column gap-1">
                                        <label for="last-name">Last Name</label>
                                        <input pInputText id="last-name" formControlName="lastName">
                                    </div>
                                </div>
                                <div class="row pt-2">
                                    <div class="col">
                                        <div class="d-flex flex-column gap-1">
                                            <label for="prefix">Prefix</label>
                                            <input pInputText id="prefix" formControlName="namePrefix">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="d-flex flex-column gap-1">
                                            <label for="suffix">Suffix</label>
                                            <input pInputText id="suffix" formControlName="nameSuffix">
                                        </div>
                                    </div>
                                </div>
                            </p-accordionTab>
                        </p-accordion>
                    </div>
                    <div class="col mb-2">
                        <p-accordion [activeIndex]="0">
                            <p-accordionTab header="Primary Address">
                                <div class="row col pt-2">
                                    <div class="d-flex flex-column gap-1">
                                        <label for="street-1">Street 1</label>
                                        <input pInputText id="street-1" formControlName="primaryStreet1">
                                    </div>
                                </div>
                                <div class="row col pt-2">
                                    <div class="d-flex flex-column gap-1">
                                        <label for="street-2">Street 2</label>
                                        <input pInputText id="street-2" formControlName="primaryStreet2">
                                    </div>
                                </div>
                                <div class="row pt-2">
                                    <div class="col">
                                        <div class="d-flex flex-column gap-1">
                                            <label for="city">City</label>
                                            <input pInputText id="city" formControlName="primaryCity">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="d-flex flex-column gap-1">
                                            <label for="state">State</label>
                                            <input pInputText id="state" formControlName="primaryState">
                                        </div>
                                    </div>
                                </div>
                                <div class="row pt-2">
                                    <div class="col">
                                        <div class="d-flex flex-column gap-1">
                                            <label for="zip">Postal Code</label>
                                            <input pInputText id="zip" formControlName="primaryZip">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="d-flex flex-column gap-1">
                                            <label for="country">Country</label>
                                            <input pInputText id="country" formControlName="primaryCountry">
                                        </div>
                                    </div>
                                </div>
                            </p-accordionTab>
                        </p-accordion>
                        <div class="row mt-2 mb-2">
                            <div class="col-sm-12 col-md">
                                <div *ngIf="contactForm.value.organizationType && contactForm.value.companyRole">
                                    <label for="signed-engagement-letter">Signed Engagement
                                        Letter&nbsp;&nbsp;</label>
                                    <p-checkbox formControlName="signedEngagementLetter" [binary]="true"
                                        inputId="signed-engagement-letter"></p-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <p-accordion [activeIndex]="0">
                            <p-accordionTab header="Phone & Email Entry" class="col">
                                <div class="row col pt-2">
                                    <div class="d-flex flex-column gap-1">
                                        <label for="primary-phone">Primary Phone #</label>
                                        <input pInputText id="primary-phone" formControlName="primaryPhone">
                                    </div>
                                </div>
                                <div class="row col pt-2">
                                    <div class="d-flex flex-column gap-1">
                                        <label for="alternate-phone">Alternate Phone #</label>
                                        <input pInputText id="alternate-phone" formControlName="alternatePhone">
                                    </div>
                                </div>
                                <div class="row col pt-2">
                                    <div class="d-flex flex-column gap-1">
                                        <label for="cell">Cell #</label>
                                        <input pInputText id="cell" formControlName="cellPhone">
                                    </div>
                                </div>
                                <div class="row col pt-2">
                                    <div class="d-flex flex-column gap-1">
                                        <label for="primary-email">Primary Email</label>
                                        <input pInputText id="primary-email" formControlName="primaryEmail">
                                    </div>
                                </div>
                                <div class="row col pt-2">
                                    <div class="d-flex flex-column gap-1">
                                        <label for="secondary-email">Secondary Email</label>
                                        <input pInputText id="secondary-email" formControlName="secondaryEmail">
                                    </div>
                                </div>
                                <div class="row col pt-2">
                                    <div class="d-flex flex-column gap-1">
                                        <label for="website">Website</label>
                                        <input pInputText id="website" formControlName="website">
                                    </div>
                                </div>
                            </p-accordionTab>
                        </p-accordion>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div *ngIf="!(contactForm.value.companyRole.Id >= 3 && contactForm.value.companyRole.Id <= 16)"
                            class="row col">
                            <div class="d-flex align-items-center">
                                <p-inputSwitch inputId="needs-review" formControlName="needsReview"></p-inputSwitch>
                                <label for="needs-review">&nbsp;&nbsp;Flagged For Review</label>
                            </div>
                        </div>
                        <div class="row col pt-2">
                            <div class="d-flex flex-column gap-1">
                                <label for="memo">Memo</label>
                                <textarea pKeyFilter="int" rows="5" cols="30" formControlName="sharedMemo"
                                    pInputTextarea></textarea>
                            </div>
                        </div>
                        <div *ngIf="!(contactForm.value.companyRole.Id >= 3 && contactForm.value.companyRole.Id <= 16)"
                            class="row col pt-2">
                            <p-accordion [activeIndex]="0">
                                <p-accordionTab header="Client Defaults">
                                    <h6>Pay Options</h6>
                                    <div class="row col">
                                        <div class="d-flex align-items-center">
                                            <label for="is-pay-after">Advance&nbsp;&nbsp;</label>
                                            <p-inputSwitch inputId="is-pay-after"
                                                formControlName="isPayAfter"></p-inputSwitch>
                                            <label for="is-pay-after">&nbsp;&nbsp;Pay After</label>
                                        </div>
                                    </div>
                                    <div class="row col">
                                        <div class="d-flex align-items-center">
                                            <label for="is-flat-rate">Hourly&nbsp;&nbsp;</label>
                                            <p-inputSwitch inputId="is-flat-rate"
                                                formControlName="isFlatRate"></p-inputSwitch>
                                            <label for="is-flat-rate">&nbsp;&nbsp;Flat Rate</label>
                                        </div>
                                    </div>
                                    <div class="row col">
                                        <div class="d-flex align-items-center">
                                            <label for="is-scheduled">Completion&nbsp;&nbsp;</label>
                                            <p-inputSwitch inputId="is-scheduled"
                                                formControlName="isScheduled"></p-inputSwitch>
                                            <label for="is-scheduled">&nbsp;&nbsp;Scheduled</label>
                                        </div>
                                    </div>
                                    <div class="row col">
                                        <div class="d-flex align-items-center">
                                            <label for="is-not-billable">Billable&nbsp;&nbsp;</label>
                                            <p-inputSwitch inputId="is-not-billable"
                                                formControlName="isNotBillable"></p-inputSwitch>
                                            <label for="is-not-billable">&nbsp;&nbsp;Not Billable</label>
                                        </div>
                                    </div>
                                    <div class="row col">
                                        <div class="p-field">
                                            <label class="pb-1" for="file-foreign">File Foreign</label>
                                            <p-dropdown id="file-foreign" [options]="fileForeigns"
                                                formControlName="fileForeign" optionLabel="Type"
                                                [required]></p-dropdown>
                                        </div>
                                    </div>
                                    <div class="row col">
                                        <div class="p-field">
                                            <label class="pb-1" for="entity-status">Entity Status</label>
                                            <p-dropdown id="entity-status" [options]="entityStatuses"
                                                formControlName="entityStatus" optionLabel="Type"
                                                [required]></p-dropdown>
                                        </div>
                                    </div>
                                </p-accordionTab>
                            </p-accordion>
                        </div>
                        <div class="row col pt-2">
                            <p-accordion [activeIndex]="0">
                                <p-accordionTab header="Company Information">
                                    <div class="row col">
                                        <div class="d-flex flex-column gap-1">
                                            <label for="company-contact">Company Contact</label>
                                            <input pInputText id="company-contact" formControlName="companyContact">
                                        </div>
                                    </div>
                                    <div class="row col pt-2">
                                        <app-contact-search-popup container=".form-container"
                                            buttonText="Link Company Contact" searchType="company"
                                            (clickedContact)="onCompanyContactClicked($event)"></app-contact-search-popup>
                                    </div>
                                </p-accordionTab>
                            </p-accordion>
                        </div>
                    </div>
                    <div *ngIf="contactForm.value.companyRole.Id >= 3 && contactForm.value.companyRole.Id <= 16"
                        class="d-xs-none d-sm-none d-md-block col-md"></div>
                    <div *ngIf="!(contactForm.value.companyRole.Id >= 3 && contactForm.value.companyRole.Id <= 16)"
                        class="col">
                        <p-accordion [activeIndex]="0">
                            <p-accordionTab header="Billing Address">
                                <div class="row col">
                                    <div>
                                        <p-checkbox formControlName="usePrimaryAddrB"
                                            (onChange)="UsePrimaryAddrBChange($event)" [binary]="true"
                                            inputId="use-primary"></p-checkbox>
                                        <label for="use-primary">&nbsp;&nbsp;Use Primary</label>
                                    </div>
                                </div>
                                <div class="row col pt-2">
                                    <div class="d-flex flex-column gap-1">
                                        <label for="b-street-1">Street 1</label>
                                        <input pInputText id="b-street-1" formControlName="billingStreet1">
                                    </div>
                                </div>
                                <div class="row col pt-2">
                                    <div class="d-flex flex-column gap-1">
                                        <label for="b-street-2">Street 2</label>
                                        <input pInputText id="b-street-2" formControlName="billingStreet2">
                                    </div>
                                </div>
                                <div class="row pt-2">
                                    <div class="col">
                                        <div class="d-flex flex-column gap-1">
                                            <label for="b-city">City</label>
                                            <input pInputText id="b-city" formControlName="billingCity">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="d-flex flex-column gap-1">
                                            <label for="b-state">State</label>
                                            <input pInputText id="b-state" formControlName="billingState">
                                        </div>
                                    </div>
                                </div>
                                <div class="row pt-2">
                                    <div class="col">
                                        <div class="d-flex flex-column gap-1">
                                            <label for="b-zip">Postal Code</label>
                                            <input pInputText id="b-zip" formControlName="billingZip">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="d-flex flex-column gap-1">
                                            <label for="b-country">Country</label>
                                            <input pInputText id="b-country" formControlName="billingCountry">
                                        </div>
                                    </div>
                                </div>
                                <div class="row col pt-2">
                                    <div class="d-flex flex-column gap-1">
                                        <label for="email">Email</label>
                                        <input pInputText id="email" formControlName="billingEmail">
                                    </div>
                                </div>
                                <div class="row col pt-2">
                                    <div class="d-flex flex-column gap-1">
                                        <label for="email-cc">Email CC</label>
                                        <input pInputText id="email-cc" formControlName="billingEmailCC">
                                    </div>
                                </div>
                            </p-accordionTab>
                        </p-accordion>
                    </div>
                    <div class="col">
                        <div class="row col">
                            <div class="d-flex flex-column gap-1">
                                <label for="client-memo">Client Memo</label>
                                <textarea pKeyFilter="int" rows="5" cols="30" formControlName="memo"
                                    pInputTextarea></textarea>
                            </div>
                        </div>
                        <div class="row col">
                            <p-accordion [activeIndex]="0">
                                <p-accordionTab header="Tax Information">
                                    <div class="row col">
                                        <div class="d-flex flex-column gap-1">
                                            <label for="tax-id">Tax ID #</label>
                                            <input pInputText id="tax-id" formControlName="taxId">
                                        </div>
                                    </div>
                                    <div class="row col pt-2">
                                        <div class="d-flex flex-column gap-1">
                                            <label for="account-number">Account #</label>
                                            <input pInputText id="account-number" formControlName="accountNumber">
                                        </div>
                                    </div>
                                    <div class="row col pt-2">
                                        <div>
                                            <p-checkbox formControlName="send1099" [binary]="true"
                                                inputId="send-1099"></p-checkbox>
                                            <label for="send-1099">&nbsp;&nbsp;Send 1099</label>
                                        </div>
                                    </div>
                                </p-accordionTab>
                            </p-accordion>
                        </div>
                        <div *ngIf="!(contactForm.value.companyRole.Id >= 3 && contactForm.value.companyRole.Id <= 16)"
                            class="row col">
                            <p-accordion [activeIndex]="0">
                                <p-accordionTab header="Referral Source">
                                    <div>
                                        <div>{{contactForm.value.referralSource}}</div>
                                        <app-contact-search-popup container=".form-container"
                                            buttonText="Link Referral Source" searchType="referral"
                                            (clickedContact)="onReferralSourceClicked($event)"></app-contact-search-popup>
                                    </div>
                                </p-accordionTab>
                            </p-accordion>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>