import { AIPRole } from './aip-role';
import { EeRole, EeStatus } from 'src/app/modules/shared/constants';

export interface IEe {
    eeId: number;
    eeRef: string;
    firstName: string;
    lastName: string;
    middleName: string;
    fullName: string;
    email: string;
    eecontactId: number;
    isAttorney: string;
    billingRate: number;
    eeStatus: string;
    sortOrder: number;
    primaryAttorney: number;
    login: string;
    password: string;
    lastLoginDate: string;
    loginMachineName: string;
    loginUserName: string;
    eeRoleId: number;
    timekeeperCode: string;
    hoursGoal: number;
    managedGoal: number;
    loadDate: string;
    AIPRole?: AIPRole | null;
}

export class Ee implements IEe {
    eeId: number;
    eeRef: string | null;
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
    fullName: string | null;
    email: string | null;
    eecontactId: number | null;
    isAttorney: string | null;
    billingRate: number | null;
    eeStatus: string | null;
    sortOrder: number | null;
    primaryAttorney: number | null;
    login: string | null;
    password: string | null;
    lastLoginDate: string | null;
    loginMachineName: string | null;
    loginUserName: string | null;
    eeRoleId: number | null;
    timekeeperCode: string | null;
    hoursGoal: number | null;
    managedGoal: number | null;
    loadDate: string | null;

    AIPRole?: AIPRole | null;

    constructor(init?: Partial<IEe>) {
        if (init?.eeId) this.eeId = init.eeId;
        this.eeRef = init?.eeRef.toUpperCase() ?? null;
        this.firstName = init?.firstName ?? null;
        this.middleName = init?.middleName ?? null;
        this.lastName = init?.lastName ?? null;
        this.fullName = init?.fullName ?? null;
        this.email = init?.email ?? null;
        this.eeRoleId = init?.eeRoleId ?? EeRole.Employee; // Default to Employee role
        this.isAttorney = init?.eeRoleId === EeRole.Attorney ? 'Y' : null;
        this.billingRate = init?.billingRate ?? 0;
        this.sortOrder = init?.sortOrder ?? 0;
        this.eeStatus = init?.eeStatus ?? EeStatus.Active;
    }
}