export enum UserRole {
    Admin = 'admin',
    InternalUser = 'internal user',
    ExternalUser = 'external user'
}

export enum DocumentStatus {
    NotReviewed = 'Not Reviewed',
    UnderReview = 'Under Review',
    Reviewed = 'Reviewed',
    Final = 'Final'
}

export enum CategoryStatus {
    InProgress = 'In Progress',
    Complete = 'Complete',
}

export enum PropertyStatus {
    Active = 'active',
    Closed = 'closed',
}

export enum EeStatus {
    Active = 'ACTIVE',
    Former = 'FORMER',
    System = 'SYSTEM',
}

/* eslint-disable no-magic-numbers */
export enum EeRole {
    Partner = 1,
    Attorney = 2,
    LawClerk = 3,
    Paralegal = 4,
    Employee = 5,
    Financial = 6,
    FlexForce = 7
}
/* eslint-enable no-magic-numbers */

export const UPLOAD_PROGRESS_HALF = 50;
export const UPLOAD_PROGRESS_85 = 85;
export const UPLOAD_PROGRESS_COMPLETE = 100;
export const ONE_SECOND = 1000;
export const TEN_SECONDS = 10000;
export const ONE_MINUTE = 60000;
export const ONE_GIGABYTE = 1000000000;
export const HTTP_OK = 200;
export const HTTP_MFA_REQUIRED = 250;
export const HTTP_FOUND = 302;
export const HTTP_BAD_REQUEST = 400;
export const HTTP_UNAUTHORIZED = 401;
export const HTTP_FORBIDDEN = 403;
export const HTTP_NOT_FOUND = 404;
export const HTTP_INTERNAL_SERVER_ERROR = 500;
export const HTTP_SERVICE_UNAVAILABLE = 503;
export const USER_EDIT_EE_REF_MAX = 50;
export const USER_EDIT_NAME_MAX = 30;
export const USER_EDIT_BILL_RATE_MAX = 999.99;
export const USER_EDIT_SORT_ORDER_MAX = 9999;

export class Constants {
    public static documentStatuses: string[] = [
        DocumentStatus.NotReviewed,
        DocumentStatus.UnderReview,
        DocumentStatus.Reviewed,
        DocumentStatus.Final
    ];

    public static categoryStatuses: string[] = [
        CategoryStatus.InProgress,
        CategoryStatus.Complete,
    ];

    public static propertyStatuses: string[] = [
        PropertyStatus.Active,
        PropertyStatus.Closed,
    ];

    public static eeStatuses: string[] = [
        EeStatus.Active,
        EeStatus.Former,
        EeStatus.System,
    ];

    public static maxFileSize: number = ONE_GIGABYTE;
}
